<template>
  <div class="pa-4">
    <p class="text-h6">Precios de mercado</p>
    <data-table-component
      :headers="headers"
      :items="solicitud.cotizaciones"
      mobile_breakpoint="725"
      :tiene_paginacion="false"
    >
      <template v-slot:[`item.fecha_carga`]="{ item }">
        {{ moment(item.created_at).format("DD/MM/YYYY") }}
      </template>
      <template v-slot:[`item.monto`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.monto)
        }}
      </template>
      <template v-slot:[`item.persona`]>
        {{ persona }}
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip right v-if="item.documento_ruta">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              small
              fab
              @click="downloadFile(item.documento_ruta)"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template>
          <span> Descargar </span>
        </v-tooltip>
      </template>
    </data-table-component>
  </div>
</template>
    <script>
import DataTableComponent from "@/components/DataTableComponent.vue";

export default {
  name: "PreciosMercadoComponent",
  components: {
    DataTableComponent,
  },
  props: {
    solicitud: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    headers: [
      {
        text: "Documento",
        align: "center",
        value: "nombre_documento",
        sortable: false,
      },
      {
        text: "Precio de mercado",
        align: "center",
        value: "monto",
      },
      {
        text: "Fecha de carga",
        align: "center",
        value: "fecha_carga",
      },
      {
        text: "Usuario",
        align: "center",
        value: "persona",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
  }),
  methods: {
    async downloadFile(route) {
      try {
        const response = await this.services.SolicitudCompra.getUrlDocumento({
          documento: route,
        });
        if (response) {
          const blob = new Blob([response.data], {
            type: "application/pdf",
          });

          const file = new File([blob], "documento.pdf", {
            type: "application/pdf",
          });

          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download = route.toString();
          link.click();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed:{
      persona(){
        return `${this.solicitud?.persona?.primer_nombre } ${ this.solicitud?.persona?.primer_apellido }`
      }
  },
};
</script>
<template>
  <v-row>
    <v-col cols="6" md="6" class="pr-1 pl-1 pb-0 mb-0">
      <vc-date-picker
        v-model="form_enviar.fechaInicio"
        mode="datetime"
        :min-date="fecha_actual"
        locale="es"
        :popover="config_calendar"
      >
        <template v-slot="{ inputEvents }">
          <v-text-field
            class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
            label="Fecha y hora de inicio*"
            prepend-inner-icon="mdi-calendar"
            readonly
            :disabled="isShow"
            :value="getFechaInicio"
            v-on="inputEvents"
            outlined
          />
        </template>
      </vc-date-picker>
    </v-col>
    <v-col cols="6" md="6" class="pl-1 pr-2 pb-0 mb-0">
      <vc-date-picker
        v-model="form_enviar.fechaFin"
        mode="datetime"
        locale="es"
        :popover="config_calendar"
        :min-date="fechaInicio"
      >
        <template v-slot="{ inputEvents }">
          <v-text-field
            class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
            label="Fecha y hora de fin*"
            prepend-inner-icon="mdi-calendar"
            readonly
            :disabled="isShow"
            :value="getFechaFin"
            v-on="inputEvents"
            outlined
          />
        </template>
      </vc-date-picker>
    </v-col>
    <v-col cols="12" md="6" class="pb-0 mb-0 pt-0">
      <v-text-field
        v-model="form_enviar.tiempo_maximo_desierto"
        label="Tiempo para declarar desierta la subasta*"
        outlined
        min="0"
        :max="maxValueTiempoOferta"
        type="number"
        prefix="(Minutos)"
        clearable
        :readonly="isShow"
        :rules="tiempoMinutosRule"
      />
    </v-col>
    <v-col cols="12" md="6" class="pb-0 mb-0 pt-0">
      <v-text-field
        v-model="form_enviar.tiempo_maximo_oferta"
        label="Tiempo máximo de espera entre ofertas *"
        outlined
        clearable
        prefix="(Minutos)     "
        type="number"
        :rules="tiempoMinutosRule"
        :readonly="isShow"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment-timezone";

export default {
  name: "CamposComunes",
  data: () => ({
    fecha_actual: moment().format("YYYY-MM-DD HH:mm"),
    tiempoMinutosRule: [
      (value) => {
        if (!value) return "Este campo es requerido";
        if (value < 0) return "El valor debe ser mayor a 0";
        if (value > 60) return "El valor debe ser menor a 60";
        return true;
      },
    ],
  }),
  computed: {
    getFechaInicio() {
      return this.form_enviar.fechaInicio
        ? this.$moment(this.form_enviar.fechaInicio).format(
            "DD/MM/YYYY HH:mm A"
          )
        : "";
    },
    getFechaFin() {
      return this.form_enviar.fechaFin
        ? this.$moment(this.form_enviar.fechaFin).format("DD/MM/YYYY HH:mm A")
        : "";
    },
    ...mapState("etapaSubastaInversaStore", ["form", "isShow"]),
    maxValueTiempoOferta() {
      switch (this.unidadTiempo) {
        case "seconds":
          return 59;
        case "minutes":
          return 59;
        case "hours":
          return 23;
        default:
          return 0;
      }
    },
    fechaInicio: {
      get() {
        return this.form_enviar.fechaInicio;
      },
      set(value) {
        this.form_enviar.fechaInicio = value
          ? this.moment(value).format("YYYY-MM-DD HH:mm:ss")
          : "";
      },
    },
    fechaFin: {
      get() {
        return this.form_enviar.fechaFin;
      },
      set(value) {
        this.form_enviar.fechaFin = value
          ? this.moment(value).format("YYYY-MM-DD HH:mm:ss")
          : "";
      },
    },
    ...mapState("configuracionSalas", [
      "headersLoteSubasta",
      "showModalEditCreateSubasta",
      "subasta_seleccionada",
      "procesoCompra",
      "sala_seleccionada",
      "form_enviar",
      "ctlLotes",
      "montoTotalLote",
      "montoInicial",
      "loteDetails",
      "ctlSubastas",
      "config_calendar",
    ]),
  },
};
</script>

<template>
  <v-card>
    <v-row class="px-4 py-2">
      <v-col cols="12" class="text-center text-sm-start">
        <span> Resolución de recurso de {{ titleProp }} </span>
        <span
          class="error--text"
          v-if="
            !resolucionProp.resolucion &&
            resolucionProp.otros_documentos.length == 0
          "
        >
          No hay información disponible
        </span>
      </v-col>
      <v-col
        cols="12"
        class="d-flex flex-column flex-sm-row align-center"
        v-if="resolucionProp.resolucion"
      >
        <v-btn
          class="text-no-style"
          dark
          color="secondary"
          @click="
            descargarArchivoProceso({
              ruta_documento: resolucionProp.resolucion.ruta_documento,
            })
          "
        >
          <v-icon> mdi-download </v-icon>
          <span> Descargar resolución </span>
        </v-btn>
        <div class="d-flex flex-column ml-sm-10 text-center text-sm-start">
          <span v-text="resolucionProp.resolucion.Usuario.email" />
          <span v-text="resolucionProp.resolucion.Usuario.nombre_usuario" />
        </div>
      </v-col>
      <v-col cols="12" v-if="resolucionProp.otros_documentos.length > 0">
        <data-table-component
          class="mb-3"
          :headers="HEADER_CONST"
          inhabilitar_paginacion
          :tiene_paginacion="false"
          :items="resolucionProp.otros_documentos"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            {{ FormatDatePrt(item.created_at) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="
                    descargarArchivoProceso({
                      ruta_documento: item.ruta_documento,
                    })
                  "
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Descargar</span>
            </v-tooltip>
          </template>
        </data-table-component>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import { mapActions } from "vuex";

export default {
  name: "CardResolucionIMPComponent",

  components: {
    DataTableComponent,
  },

  props: {
    resolucionProp: {
      type: Object,
      required: true,
    },
    titleProp: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    // constants
    HEADER_CONST: [
      { text: "Tipo de documento", value: "TipoDocumentoProceso.nombre" },
      { text: "Usuario", value: "Usuario.nombre_usuario" },
      { text: "Fecha", value: "created_at" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
  }),

  methods: {
    ...mapActions("procesoCompraDoc", ["descargarArchivoProceso"]),
  },
};
</script>

<style>
</style>
<template>
  <v-card>
    <v-card-title>
      <v-icon size="28" color="subastaBlue"> mdi-account-circle </v-icon>
      <span class="subastaBlue--text text-h6 mx-2"> Ganador: </span>
    </v-card-title>
    <template v-if="finalizada && data">
      <v-card-text>
        <span class="blue-grey--text">
          <label class="font-weight-bold">Nombre: </label> {{ data?.nombre }}
        </span>
        <br />
        <span class="blue-grey--text">
          <label class="font-weight-bold">Nombre comercial: </label>
          {{ data?.nombre_comercial }}
        </span>
        <template v-if="data?.monto">
          <template v-if="data.tipo_operacion === 'resta_unitaria'">
            <br />
            <span class="blue-grey--text">
              <label class="font-weight-bold">Monto ganador p/u: </label>
              {{
                Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(data?.monto)
              }}
            </span>
            <br />
            <span class="blue-grey--text">
              <label class="font-weight-bold"
                >Cantidad de unidades ganadas:</label
              >
              {{ Intl.NumberFormat().format(data?.cantidad) }}
            </span>
          </template>
          <br />
          <span class="blue-grey--text">
            <label class="font-weight-bold">Monto: </label>
            {{
              Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(monto)
            }}
          </span>
          <template
            v-if="
              [
                'resta_porcentaje',
                'suma_porcentaje',
                'resta_porcentaje_publicidad',
              ].includes(data?.tipo_operacion)
            "
          >
            <template>
              <span>
                <label class="font-weight-bold">Porcentaje: </label>
                {{ data?.porcentaje }}%
              </span>
              <br />
            </template>
            <template>
              <span
                v-if="['resta_porcentaje'].includes(data?.tipo_operacion)"
                class="font-weight-bold"
                >Monto de comisión a pagar:
                {{
                  Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(data?.monto * (data?.porcentaje / 100))
                }}
              </span>
              <span
                v-else-if="['suma_porcentaje'].includes(data?.tipo_operacion)"
                class="font-weight-bold"
                >Monto de descuento:
                {{
                  Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(data?.monto * (data?.porcentaje / 100))
                }}
                <br />
              </span>
              <span class="font-weight-bold">Monto total a pagar:</span>
              {{
                Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(monto)
              }}
              <br />
            </template>
          </template>
          <br />
          <span class="blue-grey--text">
            <label class="font-weight-bold">Fecha y hora registro puja: </label>
            {{ moment(data?.fecha_hora).format("HH:mm:ss:SSSS") }}
          </span>
          <br />
        </template>
      </v-card-text>
    </template>
    <template v-else>
      <v-card-text>
        <v-alert type="info" outlined>
          {{ texto_estado }}
        </v-alert>
      </v-card-text>
    </template>
  </v-card>
</template>
<script>
import moment from "moment";

import { calcularMonto } from "@/utils/subasta-utils.js";

export default {
  name: "SubastaGanador",
  data: () => ({
    data: null,
  }),
  computed: {
    monto() {
      return calcularMonto({
        monto: this.data?.monto,
        porcentaje: this.data?.porcentaje,
        tipo: this.data?.tipo_operacion,
        cantidad: this.data?.cantidad,
      });
    },
    texto_estado() {
      return moment().isBetween(
        moment(this.data?.fecha_hora_inicio),
        moment(this.data?.fecha_hora_final)
      )
        ? "Subasta en curso"
        : moment().isBefore(moment(this.item?.fecha_hora_inicio))
        ? "Subasta no iniciada"
        : "Subasta finalizada";
    },
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    finalizada: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    async FetchLastOfertaFtn() {
      if (this.id < 0) return;

      const { data } = await this.services.Paac.getGanadorSubasta(this.id);
      this.data = data;
      this.$emit("detalleGanador", !!data);
    },
  },
  async mounted() {
    await this.FetchLastOfertaFtn();
  },
};
</script>

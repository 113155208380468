<template>
  <v-expansion-panels>
    <v-expansion-panel class="secondary--text">
      <v-expansion-panel-header>
        <div>
          <span>{{ detailProp.correlativo }}</span>
          <v-chip v-if="true" outlined color="secondary" class="ml-3">
            {{ detailProp.estado.nombre }}
          </v-chip>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="12">
            <span>{{ detailProp.usuario.nombre }}</span>
            <span class="mx-3">[{{ detailProp.usuario.email }}]</span>
          </v-col>
          <v-col cols="12">
            <span class="text-h6">
              Unidad: {{ detailProp.unidad.nombre }}
            </span>
          </v-col>
          <v-col cols="12">
            <span class="text-h6"> {{ detailProp.obs.nombre }} </span>
          </v-col>
          <v-col cols="12">
            <card-cambios-component :list-change-prop="detailProp.log" />
          </v-col>
          <v-col cols="12">
            <span class="text-h6 primary--text"> Financiamientos </span>
            <div v-for="item in detailProp.financiamiento" :key="item.id">
              <card-finamt-component :finamt-prop="item" />
            </div>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import CardCambiosComponent from "./CardCambiosSNComponent.vue";
import CardFinamtComponent from "./CardFinamtSNComponent.vue";

export default {
  name: "CardSNComponent",

  components: {
    CardCambiosComponent,
    CardFinamtComponent,
  },

  props: {
    detailProp: {
      type: Object,
      required: true,
    },
  },

  data: () => ({}),

  methods: {},
};
</script>8
<template>
  <v-dialog
    v-model="show_dialog"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card class="bgMinsal">
      <v-card-actions>
        <v-btn
          class="button-extra text-capitalize mt-4 mt-sm-0"
          color="secondary"
          outlined
          @click="show_dialog = false"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
      <v-card-title class="secondary--text d-flex justify-center">
        <div>Pronunciamiento del adjudicado</div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-center" v-if="invalid && !contain_doc">
            <v-icon color="error">mdi-alert</v-icon>
            <span class="error--text">
              No presentó pronunciamiento sobre el recurso de revisión
            </span>
          </v-col>
          <v-col cols="12" v-if="contain_doc && !invalid">
            <div class="d-flex">
              <v-spacer />
              <card-download-component
                title="Pronunciamiento del adjudicado"
                :archivo="{
                  nombre: pronunciamiento?.nombre_documento,
                  ruta: pronunciamiento?.ruta_documento,
                }"
              />
              <v-spacer />
            </div>
          </v-col>
          <v-col cols="12" v-else-if="!contain_doc && !invalid">
            <div class="d-flex flex-column flex-sm-row">
              <v-spacer />
              <v-file-input
                label="Pronunciamiento del adjudicado"
                outlined
                accept="application/pdf"
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                v-model="file_pronunciamiento"
                clearable
                @blur="$v.file_pronunciamiento.$touch()"
                :error-messages="FileErrors"
              />
              <div>
                <v-btn
                  class="text-no-style mt-3 ml-sm-8"
                  dark
                  color="secondary"
                  :disabled="$v.$invalid"
                  @click="AddPronunciamiento"
                >
                  Cargar
                </v-btn>
              </div>
              <v-spacer />
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="d-flex flex-column text-center">
            <span>
              {{ impugnacion?.Usuario?.Proveedor?.nombre_comercial }}
            </span>
            <div>
              <v-chip class="ma-2" label>
                {{ impugnacion?.EstadoImpugnacion.nombre }}
              </v-chip>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <card-download-component
              title="Recurso de revisión"
              :archivo="{
                nombre: impugnacion?.TipoImpugnacion?.nombre,
                ruta: impugnacion?.doc_impugnacion,
              }"
            />
          </v-col>
          <v-col cols="12" v-if="buffer_documento">
            <embed
              :src="buffer_documento"
              type="application/pdf"
              width="100%"
              height="600px"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CardDownloadComponent from "./CardDownloadComponent.vue";
import LegacyValidations from "@/utils/legacy-validations";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}
function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "ModalPronunciamientoComponent",

  components: {
    CardDownloadComponent,
  },

  validations: {
    file_pronunciamiento: {
      required,
      fileTypeValid,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },

  data: () => ({
    show_dialog: false,
    file_pronunciamiento: null,
    impugnacion: null,
    pronunciamiento: null,
    invalid: false,
    contain_doc: false,
    buffer_documento: null,
  }),

  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
    FileErrors() {
      const errors = [];
      if (!this.$v.file_pronunciamiento.$dirty) return errors;
      !this.$v.file_pronunciamiento.required &&
        errors.push("El archivo es requerido");
      !this.$v.file_pronunciamiento.fileTypeValid &&
        errors.push("El archivo debe ser PDF");
      !this.$v.file_pronunciamiento.fileNameValid &&
        errors.push("El nombre del archivo debe ser alfanumérico");
      !this.$v.file_pronunciamiento.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);          
      return errors;
    },
  },

  methods: {
    ...mapActions("procesoCompraDoc", ["descargarBufferDocumento"]),
    async InitDetailPron(item) {
      this.impugnacion = item;

      if (this.impugnacion?.TipoImpugnacion?.id == 1) {
        if (item.fecha_admision_ucp) {
          const fecha_final = this.moment(item.fecha_admision_ucp).add(
            2,
            "days"
          );
          const fecha_actual = this.moment();

          if (fecha_actual.isAfter(fecha_final)) {
            this.invalid = true;
          }
        }

        this.FetchReslRecRev(this.impugnacion?.doc_resolucion_ucp);
      } else if (this.impugnacion?.TipoImpugnacion?.id == 2) {
        if (item.fecha_admision_tacop) {
          const fecha_final = this.moment(item.fecha_admision_tacop).add(
            3,
            "days"
          );
          const fecha_actual = this.moment();

          if (fecha_actual.isAfter(fecha_final)) {
            this.invalid = true;
          }
        }

        this.FetchReslRecRev(this.impugnacion?.doc_resolucion_tacop);
      }

      const { data, status } =
        await this.services.RecursoRevision.getPronunciamiento(item.id);

      if (status === 200) {
        this.pronunciamiento = data;

        data ? (this.contain_doc = true) : (this.contain_doc = false);
      }
    },

    async FetchReslRecRev(ruta) {
      const file = await this.descargarBufferDocumento({
        ruta_documento: ruta,
      });

      let b64 = Buffer.from(file).toString("base64");
      this.buffer_documento = `data:application/pdf;base64,${b64}`;
    },

    async AddPronunciamiento() {
      const formData = new FormData();
      formData.append("documento", this.file_pronunciamiento);
      formData.append("nombre", "Pronunciamiento");
      formData.append("id_proceso", this.$route.params.idProceso);
      formData.append("id_proceso_impugnacion", this.impugnacion.id);
      formData.append("id_etapa_proceso", this.procesoData.id_etapa_pausa);
      formData.append("folder", "procesos");

      if (this.impugnacion?.TipoImpugnacion?.id == 1) {
        formData.append("id_tipo_documento", 15001);
      } else if (this.impugnacion?.TipoImpugnacion?.id == 2) {
        formData.append("id_tipo_documento", 15004);
      }

      const { status } =
        await this.services.PacProcesos.guardarDocumentosProceso(formData);

      if (status) {
        this.show_dialog = false;
        this.$emit("reload");
      }
    },
  },

  watch: {
    show_dialog(value) {
      if (!value) {
        this.file_pronunciamiento = null;
        this.impugnacion = null;
        this.pronunciamiento = null;
        this.invalid = false;
        this.buffer_documento = null;
        this.$v.$reset();
      }
    },
  },
};
</script>
<template>
  <section fluid>
    <v-row class="mt-2" v-if="haveRole('ROLE_PROVEEDOR')">
      <v-col
        v-if="
          isAllowedCreateOfferTec &&
          (etapa.id_tipo_etapa === 10 || etapa.id_tipo_etapa === 11)
        "
        cols="12"
        sm="6"
        md="4"
      >
        <v-btn
          block
          color="secondary"
          :to="{
            name: 'pac-procesos-ofertas',
            params: { idProceso: $route.params.idProceso, idTipoOferta: 1 },
          }"
        >
          Añadir oferta técnica
        </v-btn>
      </v-col>
      <v-col
        v-if="
          isAllowedCreateOffer &&
          (etapa.id_tipo_etapa === 4 || etapa.id_tipo_etapa === 11)
        "
        cols="12"
        sm="6"
        md="4"
      >
        <v-btn
          block
          color="secondary"
          :to="{
            name: 'pac-procesos-ofertas',
            params: { idProceso: $route.params.idProceso, idTipoOferta: 2 },
          }"
        >
          Añadir oferta económica
        </v-btn>
      </v-col>
    </v-row>
    <OfertaUCPComponent
      v-if="haveRole('ROLE_UACI')"
      :tipoEtapa="etapa.id_tipo_etapa"
    />

    <v-data-table
      :items="offersFiltered"
      :headers="headers"
      v-if="permisos.leer"
      class="mt-6"
    >
      <template #[`item.id_tipo_oferta`]="{ item }">
        {{ item.id_tipo_oferta === 1 ? "Técnica" : "Económica" }}
      </template>
      <template #[`item.created_at`]="{ item }">
        {{ formatDate(item.created_at) }}
      </template>
      <template #[`item.fecha_recepcion`]="{ item }">
        {{ formatDate(item.fecha_recepcion) }}
      </template>
      <template #[`item.actions`]="{ item }">
        <!-- item.id_usuario === userInfo.user.id -->
        <v-btn
          v-if="
            (haveRole('ROLE_PROVEEDOR') || haveRole('ROLE_UACI')) && 
            !item.ParticipacionProveedor?.Proveedor?.Usuario?.is_disabled
          "
          icon
          color="secondary"
          :to="{
            name: 'pac-procesos-ofertas',
            params: {
              idProceso: $route.params.idProceso,
              idTipoOferta: item.id_tipo_oferta,
              idOferta: item.id,
              idParticipacionProveedor: item.ParticipacionProveedor?.id,
            },
          }"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          v-if="haveRole('ROLE_UACI') && item.id_usuario === userInfo.user.id && !item.ParticipacionProveedor?.Proveedor?.Usuario?.is_disabled"
          @click="(idOferta = item.id), (deleteDialog = true)"
          icon
          color="secondary"
          ><v-icon>mdi-delete</v-icon></v-btn
        >
      </template>
    </v-data-table>
    <dialogoConfirmacion
      :show="deleteDialog"
      title="¿Desea eliminar esta oferta?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Eliminar"
      @close="deleteDialog = false"
      @confirm="deleteOffer"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import OfertaUCPComponent from "./OfertaUCPComponent.vue";
import dialogoConfirmacion from "@/components/ConfirmationDialogComponent.vue";

export default {
  name: "RecepcionDeOfertasComponent",
  components: {
    OfertaUCPComponent,
    dialogoConfirmacion,
  },
  props: {
    etapa: {
      type: Object,
      default: {},
    },
    permisos: {
      type: Object,
      default: {},
    },
    extraParams: {
      type: Object,
    },
    showForm: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    deleteDialog: false,
    idOferta: null,
  }),
  computed: {
    ...mapState(["userInfo"]),
    ...mapState("procesoCompraDoc", ["offers"]),
    offersFiltered() {
      if (!this.haveRole("ROLE_PROVEEDOR")) {
        switch (this.etapa.id_tipo_etapa) {
          case 5:
            return this.offers.filter((target) => target.id_tipo_oferta == 2);

          case 12:
            return this.offers.filter((target) => target.id_tipo_oferta == 1);

          default:
            return this.offers;
        }
      } else {
        return this.offers;
      }
    },

    headers() {
      if (this.haveRole("ROLE_PROVEEDOR")) {
        return [
          {
            text: "Tipo de oferta",
            value: "id_tipo_oferta",
          },
          {
            text: "Fecha",
            value: "created_at",
          },
          {
            text: "Acciones",
            value: "actions",
            align: "center",
            width: "15%",
          },
        ];
      } else if (this.haveRole("ROLE_UACI")) {
        return [
          {
            text: "Tipo de oferta",
            value: "id_tipo_oferta",
          },
          {
            text: "Proveedor",
            value: "ParticipacionProveedor.Proveedor.nombre_comercial",
          },
          {
            text: "Fecha de registro",
            value: "created_at",
          },
          {
            text: "Fecha de recepción",
            value: "fecha_recepcion",
          },
          {
            text: "Acciones",
            value: "actions",
            align: "center",
            width: "15%",
          },
        ];
      } else {
        return [
          {
            text: "Tipo de oferta",
            value: "id_tipo_oferta",
          },
          {
            text: "Proveedor",
            value: "ParticipacionProveedor.Proveedor.nombre_comercial",
          },
          {
            text: "Fecha",
            value: "created_at",
          },
        ];
      }
    },

    isAllowedCreateOffer() {
      return (
        this.offers.filter((target) => target.id_tipo_oferta == 2).length == 0
      );
    },
    isAllowedCreateOfferTec() {
      return (
        this.offers.filter((target) => target.id_tipo_oferta == 1).length == 0
      );
    },
  },
  methods: {
    ...mapActions("procesoCompraDoc", [
      "getProviderOffers",
      "getProcessOffers",
    ]),

    formatDate(timestamp) {
      if (!timestamp) return "-";
      return moment(timestamp).format("DD-MM-YYYY hh:mm A");
    },
    async deleteOffer() {

      const { status } = await this.services.PacProcesos.deleteOfertaProveedor(
        this.$route.params.idProceso,
        this.idOferta
      )

      if (status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Oferta eliminada con éxito",
        });

        this.deleteDialog = false;
        this.getProcessOffers({
          id_proceso_compra: this.$route.params.idProceso,
        });
      }

    },
  },
  async created() {
    if (
      (this.etapa.id_tipo_etapa === 4 ||
        this.etapa.id_tipo_etapa === 10 ||
        this.etapa.id_tipo_etapa === 11) &&
        this.haveRole('ROLE_PROVEEDOR')
    ) {
      await this.getProviderOffers({
        id_proceso_compra: this.$route.params.idProceso,
      });
    }
    
    if (
      (this.etapa.id_tipo_etapa === 4 ||
        this.etapa.id_tipo_etapa === 5 ||
        this.etapa.id_tipo_etapa === 12 ||
        this.etapa.id_tipo_etapa === 13 ||
        this.etapa.id_tipo_etapa === 20 ||
        this.etapa.id_tipo_etapa === 11 ||
        this.etapa.id_tipo_etapa === 10
      ) &&
      this.permisos.leer &&
      !this.haveRole('ROLE_PROVEEDOR')
    ) {
      await this.getProcessOffers({
        id_proceso_compra: this.$route.params.idProceso,
      });
    }
  },

};
</script>

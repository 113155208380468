<template>
  <section>
    <v-card class="mt-4">
      <v-card-text>
        <v-row class="mx-auto mt-4">
          <v-col cols="5" >
            <div class="d-flex flex-column align-center text-center">
              <span class="secondary--text">Monto requerido</span>
              <span class="secondary--text text-h4">${{
                Intl.NumberFormat("en-US", {
                  currency: "USD",
                  notation: "compact",
                }).format(procesoData?.monto_requerido || 0)
              }}</span>
              <span class="grey--text">${{
                Intl.NumberFormat("en-US", {
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(procesoData?.monto_requerido || 0)
              }}</span>
            </div>
          </v-col>
          <v-col cols="2" class="text-center">
            <v-divider vertical></v-divider>
          </v-col>
          <v-col cols="5" >
            <div class="d-flex flex-column align-center text-center">
              <span class="secondary--text">Monto certificado</span>
              <span class="secondary--text text-h4">${{
                Intl.NumberFormat("en-US", {
                  currency: "USD",
                  notation: "compact",
                }).format(procesoData?.monto_certificado || 0)
              }}</span>
              <span class="grey--text">${{
                Intl.NumberFormat("en-US", {
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(procesoData?.monto_certificado || 0)
              }}</span>
            </div>
          </v-col>
        </v-row>
        <div class="d-flex justify-center my-4">
          <a :href="`/certificacion-fondos/detalle/${$route.params.idProceso}`" target="_blank">
            <v-btn elevation="0" color="btnAditoria secondary--text">Ver certificación</v-btn>
          </a>
        </div>
      </v-card-text>
    </v-card>
  </section>
</template>
<script>
export default {
  name: "DatosCertificacionComponent",
  props: {
    procesoData: {
      type: Object,
      default: () => { },
    },
  },
  data: () => ({}),
};
</script>
<style lang="scss" scoped>
.v-divider {
  border-color: #404a7a !important;
  border-width: 1 thin 1 1 !important;
  height: 90px !important;
  display: inline-block !important;
}

.v-card {
  height: 100%;
}
</style>
<template>
  <section class="mt-6">
    <div v-for="item in list_history_var" :key="item.id">
      <card-item-component :etapa-prop="item" />
    </div>
  </section>
</template>

<script>
import CardItemComponent from "./CardItemPIPComponent.vue";

export default {
  name: "ContainerPIPComponent",

  components: {
    CardItemComponent,
  },

  data: () => ({
    list_history_var: false,
  }),

  methods: {
    async FetchHistoryFtn() {

      const { data, status } =
        await this.services.PacProcesos.getAuditCambiosEtapa(
          this.$route.params.idProceso
        );


      if (status === 200) {
        this.list_history_var = data;
      }
    },
  },

  created() {
    this.FetchHistoryFtn();
  },
};
</script>

<style>
</style>
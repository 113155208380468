<template>
  <v-row>
    <v-col cols="12" sm="12" md="12">
      <p>Detalle del lote</p>
      <v-data-table
        :headers="headersLoteSubasta"
        :items="loteDetails"
        :items-per-page="-1"
        hide-default-footer
      >
        <template #[`item.monto_sub_proceso_obs`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.monto_sub_proceso_obs)
          }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "DetalleLoteSeleccionado",
  computed: {
    ...mapState("configuracionSalas", ["loteDetails", "headersLoteSubasta"]),
  },
};
</script>

<template>
  <v-row>
    <v-col cols="12" v-if="buffer_documento">
      <embed
        :src="buffer_documento"
        type="application/pdf"
        width="100%"
        height="600px"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ResolucionRecRevComponent",

  data: () => ({
    documento: {},
    buffer_documento: null,
    lista_documentos: [],
  }),

  methods: {
    ...mapActions("procesoCompraDoc", [
      "getDocumentosProcesoPorTipo",
      "descargarBufferDocumento",
    ]),
    async FetchReslRecRev() {
      const data = await this.getDocumentosProcesoPorTipo({
        id_proceso_compra: this.$route.params.idProceso,
        id_tipo_documento: 15003,
      });

      if (data.length > 0) {
        this.lista_documentos = data;
        this.documento = data[data.length - 1];
      } else {
        this.documento = data[0];
      }

      const file = await this.descargarBufferDocumento({
        ruta_documento: this.documento.ruta_documento,
      });

      let b64 = Buffer.from(file).toString("base64");
      this.buffer_documento = `data:application/pdf;base64,${b64}`;
    },
  },

  created() {
    this.FetchReslRecRev();
  },
};
</script>

<style>
</style>
<template>
  <v-expansion-panels class="my-3">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span class="secondary--text"
          >{{ loteProp.nombre }} - {{ loteProp.descripcion }}</span
        >
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <data-table-component
          class="mb-3"
          :headers="HEADERS_CONST"
          :items="loteProp.detalles"
          inhabilitar_paginacion
          :tiene_paginacion="false"
        >
          <template v-slot:[`item.monto`]="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(item.monto)
            }}
          </template>
        </data-table-component>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
export default {
  name: "CardLoteContratoComponent",

  components: {
    DataTableComponent,
  },

  props: {
    loteProp: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    // constants
    HEADERS_CONST: [
      {
        text: "OBS",
        value: "obs.nombre",
      },
      {
        text: "Unidad de medida",
        value: "unidad_medida.nombre",
      },
      {
        text: "Cantidad",
        value: "cantidad",
      },
      {
        text: "Monto ($)",
        value: "monto",
      },
    ],
  }),
};
</script>

<style>
</style>
<template>
  <v-dialog v-model="showModal" persistent>
    <v-card>
      <v-card-title> Listado de subastas </v-card-title>
      <v-card-subtitle> Seleccione una subasta del listado </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="6" md="6">
            <v-text-field
              v-model="busqueda"
              label="Buscar"
              clearable
              outlined
            ></v-text-field>
            <v-list>
              <v-virtual-scroll
                :items="listadoFiltrado"
                height="500"
                item-height="50"
              >
                <template v-slot:default="{ item }">
                  <v-list-item @click="cargarSala(item)">
                    <v-list-item-action>
                      <v-icon large> mdi-eye </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <span>
                        {{ item.LotesProceso?.nombre }}
                      </span>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-list>
          </v-col>
          <v-col cols="6" md="6">
            <v-row>
              <!-- <v-col cols="6" md="6">
                <detallesSubasta
                  :fecha_hora_final="subastaSeleccionada.fecha_hora_final"
                  :fecha_hora_inicio="subastaSeleccionada.fecha_hora_inicio"
                />
              </v-col> -->
              <v-col cols="6" md="6">
                <subastaGanador
                  ref="subastaGanador"
                  :id="subastaSeleccionada.id"
                  :finalizada="
                    moment().isAfter(subastaSeleccionada.fecha_hora_final)
                  "
                />
              </v-col>
              <v-col cols="6" md="6">
                <detalleLote
                  ref="getLoteDetail"
                  :id_lote="subastaSeleccionada.id_lote_proceso"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="$emit('cerrar')"> Cerrar </v-btn>
        <v-btn @click="cargarSubastas">Recargar información</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";
import detallesSubasta from "./Etapas/hitoResultadosSubasta/SubastaGanador.vue";
import subastaGanador from "./Etapas/hitoResultadosSubasta/SubastaGanador.vue";
import detalleLote from "./Etapas/hitoResultadosSubasta/DetalleLote.vue";
export default {
  name: "ModalListadoSubastas",
  components: {
    detallesSubasta,
    subastaGanador,
    detalleLote,
  },
  data: () => ({
    id_proceso: null,
    list_salas: [],
    subastaSeleccionada: { id: -1, id_lote_proceso: -1},
    busqueda: "",
  }),
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    listadoFiltrado() {
      return this.busqueda
        ? this.list_salas.filter((item) =>
            item.LotesProceso?.nombre
              .toLowerCase()
              .includes(this.busqueda.toLowerCase())
          )
        : this.list_salas;
    },
  },
  methods: {
    async cargarSubastas() {
      const { data } = await this.services.PacProcesos.getListadoSubastas(
        this.id_proceso
      );
      this.list_salas = data;
    },
    cargarSala(item) {
      this.subastaSeleccionada = {...item};  
    },
  },
  async mounted() {
    this.id_proceso = this.$route.params.idProceso;
    await this.cargarSubastas();
  },
  watch: {
    subastaSeleccionada: {
      handler(value) {
        if (!value) return;

        this.$refs.subastaGanador.FetchLastOfertaFtn();
        this.$refs.getLoteDetail.getLoteDetail();
      },
      deep: true,
    }
  }
};
</script>

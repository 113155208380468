var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.permisos.escribir)?_c('section',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('p',{staticClass:"text-h6 secondary--text"},[_vm._v("Salas")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Nombre de sala *","outlined":"","error-messages":_vm.nuevaSalaError},model:{value:(_vm.nueva_sala),callback:function ($$v) {_vm.nueva_sala=$$v},expression:"nueva_sala"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-btn',{attrs:{"dark":"","color":"secondary"},on:{"click":_vm.agregarSala}},[_vm._v(" Agregar Sala ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("Listado de salas")]),_c('DataTable',{directives:[{name:"models",rawName:"v-models:select",value:(_vm.por_pagina),expression:"por_pagina",arg:"select"},{name:"models",rawName:"v-models:pagina",value:(_vm.pagina),expression:"pagina",arg:"pagina"}],attrs:{"headers":_vm.headers,"items":_vm.items,"total_registros":_vm.total_registros},on:{"paginar":_vm.paginar},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$router.push({
                name: 'subasta-inversa-agregar-sala',
                params: {
                  idProceso: _vm.$route.params.idProceso,
                  idSala: item.id,
                },
              })}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"secondary"},on:{"click":function($event){(_vm.showModalRoomDelete = true), (_vm.roomToAction = item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)],1),_c('ConfirmationDialogComponent',{attrs:{"show":_vm.showModalRoomDelete,"btnConfirmar":"Aceptar","title":"¿Desea eliminar la sala seleccionada?","message":"De confirmarse la siguiente acción, los cambios no serán reversibles."},on:{"close":function($event){_vm.showModalRoomDelete = false},"confirm":_vm.roomDelete}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
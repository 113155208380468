<template>
  <v-row no-gutters>
    <v-col
      v-if="errores.length > 0"
      cols="12"
      md="12"
    >
      <v-list>
        <v-list-item class="" v-for="(error, index) in errores" :key="index">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon color="red" large>mdi-alert-circle</v-icon>
              {{ error }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ErroresUnitaria",
  computed: {
    ...mapState("configuracionSalas", ["errores", "subasta_unitaria"]),
  },
};
</script>

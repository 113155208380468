<template>
  <v-row>
    <v-col cols="12">
      <v-btn
        @click="setShowModalEditCreateSubasta(false), resetForm()"
        class="mr-4"
        outlined
        color="secondary"
      >
        Volver
      </v-btn>
      <v-btn
        v-if="
          !form_enviar.id &&
          form_enviar.id_lote &&
          form_enviar.ctlSubasta &&
          !lote_seleccionado?.id_lote_procedencia &&
          solicitudes_lote?.length > 1
        "
        dark
        color="secondary"
        @click="enviarFormularioUnitarias"
      >
        Enviar
      </v-btn>
      <v-btn v-else dark color="secondary" @click="saveSalaConf()">
        {{ subasta_seleccionada?.id ? "Editar" : "Guardar" }}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
export default {
  name: "accionesFormulario",
  computed: {
    ...mapState("configuracionSalas", [
      "form_enviar",
      "subasta_seleccionada",
      "solicitudes_lote",
      "errores",
      "montoTotalLote",
    ]),
  },
  methods: {
    ...mapActions("configuracionSalas", [
      "guardarConfiguracion",
      "setSubastaSeleccionada",
      "setShowModalEditCreateSubasta",
      "resetForm",
      "enviarFormularioUnitarias",
      "lote_seleccionado",
      "validarFormularioIndividual",
    ]),
    async saveSalaConf() {
      this.validarFormularioIndividual();
      if (this.errores.length === 0) {
        const currentDate = moment().format("DD-MM-YYYY 00:00:00");
        this.form_enviar.tiempo_maximo_oferta = moment(
          `${currentDate} 00:00:00`,
          "DD-MM-YYYY HH:mm:ss"
        )
          .add(this.form_enviar.tiempo_maximo_oferta, "minutes")
          .format("LTS");
        this.form_enviar.tiempo_maximo_desierto = moment(
          `${currentDate} 00:00:00`,
          "DD-MM-YYYY HH:mm:ss"
        )
          .add(this.form_enviar.tiempo_maximo_desierto, "minutes")
          .format("LTS");
        try {
          await this.guardarConfiguracion();
          this.$emit("actualizar");
        } catch (e) {
          this.form_enviar.tiempo_maximo_desierto = moment
            .duration(this.form_enviar.tiempo_maximo_desierto)
            .asMinutes();
          this.form_enviar.tiempo_maximo_oferta = moment
            .duration(this.form_enviar.tiempo_maximo_oferta)
            .asMinutes();
          throw e;
        }
      }
    },
  },
};
</script>

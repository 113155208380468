<template>
  <div class="pa-4">
    <p class="text-h6">Documentos de especificación</p>
    <data-table-component
      :headers="headers"
      :items="solicitud.docs_especificacion"
      mobile_breakpoint="725"
      :tiene_paginacion="false"
    >
      <template v-slot:[`item.fecha_carga`]="{ item }">
        {{ moment(item.fecha).format("DD/MM/YYYY") }}
      </template>
      <template v-slot:[`item.persona`]>
        {{ persona }}
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip right v-if="item.archivo">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              small
              fab
              @click="
                descargarArchivoProceso({
                  ruta_documento: item.archivo,
                  disk: 'especificaciones'
                })
              "
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template>
          <span> Descargar </span>
        </v-tooltip>
      </template>
    </data-table-component>
  </div>
</template>
    <script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import { mapActions } from "vuex";

export default {
  name: "DocsEspecificacionComponent",
  components: {
    DataTableComponent,
  },
  props: {
    solicitud: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    headers: [
      {
        text: "Documento",
        align: "center",
        value: "nombre",
        sortable: false,
      },
      {
        text: "Fecha de carga",
        align: "center",
        value: "fecha_carga",
        sortable: false,
      },
      {
        text: "Usuario",
        align: "center",
        value: "persona",
        sortable: false,
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
  }),
  methods: {
    ...mapActions("procesoCompraDoc", ["descargarArchivoProceso"]),
    async downloadFile(route) {
      try {
        const response = await this.services.SolicitudCompra.getUrlDocumento({
          documento: route,
        });
        if (response) {
          const blob = new Blob([response.data], {
            type: "application/pdf",
          });

          const file = new File([blob], "documento.pdf", {
            type: "application/pdf",
          });

          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download = route.toString();
          link.click();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed:{
      persona(){
        return `${this.solicitud?.persona?.primer_nombre } ${ this.solicitud?.persona?.primer_apellido }`
      }
  },
};
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"fluid":""}},[(_vm.haveRole('ROLE_PROVEEDOR'))?_c('v-row',{staticClass:"mt-2"},[(
        _vm.isAllowedCreateOfferTec &&
        (_vm.etapa.id_tipo_etapa === 10 || _vm.etapa.id_tipo_etapa === 11)
      )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn',{attrs:{"block":"","color":"secondary","to":{
          name: 'pac-procesos-ofertas',
          params: { idProceso: _vm.$route.params.idProceso, idTipoOferta: 1 },
        }}},[_vm._v(" Añadir oferta técnica ")])],1):_vm._e(),(
        _vm.isAllowedCreateOffer &&
        (_vm.etapa.id_tipo_etapa === 4 || _vm.etapa.id_tipo_etapa === 11)
      )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn',{attrs:{"block":"","color":"secondary","to":{
          name: 'pac-procesos-ofertas',
          params: { idProceso: _vm.$route.params.idProceso, idTipoOferta: 2 },
        }}},[_vm._v(" Añadir oferta económica ")])],1):_vm._e()],1):_vm._e(),(_vm.haveRole('ROLE_UACI'))?_c('OfertaUCPComponent',{attrs:{"tipoEtapa":_vm.etapa.id_tipo_etapa}}):_vm._e(),(_vm.permisos.leer)?_c('v-data-table',{staticClass:"mt-6",attrs:{"items":_vm.offersFiltered,"headers":_vm.headers},scopedSlots:_vm._u([{key:`item.id_tipo_oferta`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.id_tipo_oferta === 1 ? "Técnica" : "Económica")+" ")]}},{key:`item.created_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:`item.fecha_recepcion`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.fecha_recepcion))+" ")]}},{key:`item.actions`,fn:function({ item }){return [(
          (_vm.haveRole('ROLE_PROVEEDOR') || _vm.haveRole('ROLE_UACI')) && 
          !item.ParticipacionProveedor?.Proveedor?.Usuario?.is_disabled
        )?_c('v-btn',{attrs:{"icon":"","color":"secondary","to":{
          name: 'pac-procesos-ofertas',
          params: {
            idProceso: _vm.$route.params.idProceso,
            idTipoOferta: item.id_tipo_oferta,
            idOferta: item.id,
            idParticipacionProveedor: item.ParticipacionProveedor?.id,
          },
        }}},[_c('v-icon',[_vm._v("mdi-eye")])],1):_vm._e(),(_vm.haveRole('ROLE_UACI') && item.id_usuario === _vm.userInfo.user.id && !item.ParticipacionProveedor?.Proveedor?.Usuario?.is_disabled)?_c('v-btn',{attrs:{"icon":"","color":"secondary"},on:{"click":function($event){(_vm.idOferta = item.id), (_vm.deleteDialog = true)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)}):_vm._e(),_c('dialogoConfirmacion',{attrs:{"show":_vm.deleteDialog,"title":"¿Desea eliminar esta oferta?","message":"De confirmarse la siguiente acción, los cambios no serán reversibles.","btnConfirmar":"Eliminar"},on:{"close":function($event){_vm.deleteDialog = false},"confirm":_vm.deleteOffer}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
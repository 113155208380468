<template>
  <Fragment>
    <v-alert v-if="
      haveRole('ROLE_PROVEEDOR') &&
      ['resta_monto', 'resta_unitaria'].includes(
        oferta?.subasta?.CtlSubastum.tipo_operacion
      )
    ">
      Realiza tus ofertas, teniendo en consideración: <br />
      <template>
        <v-chip color="#00b6f5" class="white--text"> Oferta más alta </v-chip>
        &nbsp; &nbsp; &nbsp;
        <v-chip color="#252B58" class="white--text"> Oferta más baja </v-chip>
      </template>
      <template v-if="oferta?.subasta?.monto_digitado">
        <br />
        En esta subasta también podrás digitar el monto de tu oferta
      </template>
    </v-alert>
    <v-alert v-else-if="haveRole('ROLE_PROVEEDOR')">
      Realiza tus ofertas, teniendo en consideración:
      <div>
        <v-chip color="subastaBlue--text">
          Ingresa el porcentaje de descuento que deseas ofertar sobre el monto
          inicial
        </v-chip>
      </div>
    </v-alert>
  </Fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
export default {
  components: {
    Fragment,
  },
  props: {
    oferta: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<template>
  <section>
    <v-row align="center">
      <v-col cols="12" sm="12" md="6">
        <p class="text-h5 secondary--text font-weight-bold">
          {{ etapa?.CtlEtapasProcesoImpugnacion?.nombre_etapa }}
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-row align="center">
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de Inicio:
              {{ moment(etapa?.fecha_inicio).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de Fin:
              {{ moment(etapa?.fecha_fin).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p>Recursos de revisión presentados</p>
        <DataTable :headers="headers" :items="items" :tiene_paginacion="false">
          <template v-slot:[`item.proveedor`]="{ item }">
            {{ item?.Usuario?.Proveedor?.nombre_comercial }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="secondary" @click="showDocument(item), (doc_impugnacion_selected = item)">
              mdi-eye
            </v-icon>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment("DD/MM/YYYY") }}
          </template>
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip
              :color="getColor(item.EstadoImpugnacion?.id)"
              style="display: block"
              label
            >
              {{ item?.EstadoImpugnacion?.nombre }}
            </v-chip>
          </template>
        </DataTable>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" max-width="800px">
      <v-card class="pb-2">
        <embed
          width="100%"
          height="700"
          :src="doc + '#page=1%zoom=100'"
          v-if="doc"
        />
        <v-card-actions v-if="this.estado_impugnacion_selected?.id === 1" class="d-flex flex-wrap">
          <v-btn @click.stop="showDialog = false" color="secondary" outlined>
            Cerrar
          </v-btn>
          <v-btn
            @click.stop="(showDialogadmisionRecurso = true), (newEstado = 3)"
            color="red"
            outlined
          >
            Rechazar
          </v-btn>
          <v-btn
            @click.stop="(showDialogadmisionRecurso = true), (newEstado = 2)"
            color="success"
            dark
          >
            Admitir recurso
          </v-btn>
          <v-btn
            class="ml-auto"
            @click.stop="descargarArchivo()"
            color="info"
            outlined
            dark
          >
            <v-icon>mdi-download</v-icon> Descargar
          </v-btn>
        </v-card-actions>
        <div class="px-4" v-else>
          <v-btn @click.stop="showDialog = false" color="secondary" outlined>
            Cerrar
          </v-btn>
          <v-chip label class="ml-4">
            {{ this.estado_impugnacion_selected?.nombre }}
          </v-chip>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDialogadmisionRecurso" max-width="500">
      <v-card class="pb-2">
        <v-card-text class="py-4">
          <h6 class="text-h6 secondary--text">
            {{ newEstado === 2 ? "Admitir" : "Rechazar" }} recurso de revisión
          </h6>
          <p v-if="newEstado === 2">
            Para poder realizar esta acción debe cargar antes el acta firmada
            por la autoridad aceptando la admisión del recurso.
          </p>
          <p v-if="newEstado === 3">
            Para poder realizar esta acción debe cargar antes el acta firmada
            por la autoridad rechazando la admisión del recurso.
          </p>

          <v-file-input
            :label="`Acta de ${
              newEstado === 2 ? 'admisión' : 'rechazo'
            } de recurso`"
            v-model="doc_admision_recurso"
            outlined
            accept="application/pdf"
            prepend-icon=""
            prepend-inner-icon="mdi-paperclip"
            clearable
            @blur="$v.doc_admision_recurso.$touch()"
            :error-messages="docAdmisionRecursoErrors"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click.stop="showDialogadmisionRecurso = false"
            color="secondary"
            outlined
          >
            Cerrar
          </v-btn>
          <v-btn
            @click.stop="updateEstadoRecurso()"
            :color="newEstado === 2 ? 'secondary' : 'red'"
            solid
            v-if="this.doc_admision_recurso"
          >
            {{ newEstado === 2 ? "Admitir" : "Rechazar" }} recurso
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import DataTable from "@/components/DataTableComponent.vue";
import LegacyValidations from "@/utils/legacy-validations";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}
function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "AdmisionRecursoComponent",
  components: {
    DataTable,
  },
  props: {
    etapa: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    headers: [
      {
        text: "Código",
        align: "center",
        value: "correlativo",
        width: "30%",
      },
      {
        text: "Proveedor",
        align: "center",
        value: "proveedor",
        width: "20%",
      },
      {
        text: "Fecha de creación",
        value: "created_at",
        width: "20%",
        align: "center",
      },
      {
        text: "Estado",
        value: "estado",
        width: "20%",
        align: "center",
      },
      {
        text: "Acciones",
        align: "center",
        value: "actions",
        width: "20%",
        sortable: false,
      },
    ],
    items: [],
    pagina: 1,
    por_pagina: 5,
    total_registros: 0,
    showDialog: false,
    doc: null,
    id_impugnacion: null,
    estado_impugnacion_selected: null,
    doc_admision_recurso: null,
    showDialogadmisionRecurso: false,
    newEstado: null,
    doc_impugnacion_selected: null,
  }),
  validations: {
    doc_admision_recurso: {
      required,
      fileTypeValid,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
    docAdmisionRecursoErrors() {
      const errors = [];
      if (!this.$v.doc_admision_recurso.$dirty) return errors;
      !this.$v.doc_admision_recurso.required &&
        errors.push("El archivo es requerido");
      !this.$v.doc_admision_recurso.fileTypeValid &&
        errors.push("El archivo debe ser PDF");
      !this.$v.doc_admision_recurso.fileNameValid &&
        errors.push("El nombre del archivo debe ser sin caracteres especiales");
      !this.$v.doc_admision_recurso.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);          
    },
  },
  methods: {
    ...mapActions("procesoCompraDoc", ["descargarBufferDocumento", "descargarArchivoProceso"]),
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.pagina = pagina;
      this.por_pagina = cantidad_por_pagina;
      this.getRecursosRevision();
    },
    async getRecursosRevision() {


      const { status, data } =
        await this.services.RecursoRevision.getRecursosRevisionUcp(
          this.$route.params.idProceso,
          {
            id_tipo: 1,
            page: this.pagina,
            per_page: this.por_pagina,
            id_etapa_proceso: this.procesoData.id_etapa_pausa,
          }
        )

      if (status == 200) {
        this.items = data;
      }

    },
    async showDocument(item) {
      const response = await this.descargarBufferDocumento({
        ruta_documento: item.doc_impugnacion,
      });
      this.id_impugnacion = item.id;
      this.estado_impugnacion_selected = item.EstadoImpugnacion;
      this.getDoc(response);
      this.showDialog = true;
    },
    getDoc(doc) {
      let b64 = Buffer.from(doc).toString("base64");
      this.doc = `data:application/pdf;base64,${b64}`;
    },
    getColor(id_estado) {
      switch (id_estado) {
        case 1:
          return "#8EBCE1";
        case 2:
          return "green";
        case 3:
          return "red";
        default:
          return "grey";
      }
    },
    async updateEstadoRecurso() {
      if (this.$v.doc_admision_recurso.$invalid) {
        const maxSize = LegacyValidations.getMaxSizeString();
        this.temporalAlert({
          show: true,
          message: `Debe seleccionar un archivo PDF válido, sin carácteres especiales y menor a ${maxSize}`,
          type: "error",
        });
        return;
      }

      const formData = new FormData();
      formData.append("doc_resolucion", this.doc_admision_recurso);
      formData.append("id_estado_impugnacion", this.newEstado);

      const { status } =
        await this.services.RecursoRevision.putEstadoImpugnacion(
          this.$route.params.idProceso,
          this.id_impugnacion,
          formData
        );

      if (status == 200) {
        this.temporalAlert({
          show: true,
          message:
            this.newEstado == 2
              ? "Recurso admitido con éxito"
              : "Recurso rechazado con éxito",
          type: "success",
        });
        this.showDialog = false;
        this.id_impugnacion = null;
        this.estado_impugnacion_selected = null;
        this.newEstado = null;
        this.showDialogadmisionRecurso = false;
        this.doc_admision_recurso = null;
        this.getRecursosRevision();
      }
    },
    async descargarArchivo() {
      this.descargarArchivoProceso({ ruta_documento: this.doc_impugnacion_selected.doc_impugnacion });
    }
  },
  created() {
    this.getRecursosRevision();
  },
};
</script>

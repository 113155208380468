var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"size":"28","color":"subastaBlue"}},[_vm._v(" mdi-account-circle ")]),_c('span',{staticClass:"subastaBlue--text text-h6 mx-2"},[_vm._v(" Ganador: ")])],1),(_vm.finalizada && _vm.data)?[_c('v-card-text',[_c('span',{staticClass:"blue-grey--text"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Nombre: ")]),_vm._v(" "+_vm._s(_vm.data?.nombre)+" ")]),_c('br'),_c('span',{staticClass:"blue-grey--text"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Nombre comercial: ")]),_vm._v(" "+_vm._s(_vm.data?.nombre_comercial)+" ")]),(_vm.data?.monto)?[(_vm.data.tipo_operacion === 'resta_unitaria')?[_c('br'),_c('span',{staticClass:"blue-grey--text"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Monto ganador p/u: ")]),_vm._v(" "+_vm._s(Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(_vm.data?.monto))+" ")]),_c('br'),_c('span',{staticClass:"blue-grey--text"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Cantidad de unidades ganadas:")]),_vm._v(" "+_vm._s(Intl.NumberFormat().format(_vm.data?.cantidad))+" ")])]:_vm._e(),_c('br'),_c('span',{staticClass:"blue-grey--text"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Monto: ")]),_vm._v(" "+_vm._s(Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(_vm.monto))+" ")]),(
            [
              'resta_porcentaje',
              'suma_porcentaje',
              'resta_porcentaje_publicidad',
            ].includes(_vm.data?.tipo_operacion)
          )?[[_c('span',[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Porcentaje: ")]),_vm._v(" "+_vm._s(_vm.data?.porcentaje)+"% ")]),_c('br')],[(['resta_porcentaje'].includes(_vm.data?.tipo_operacion))?_c('span',{staticClass:"font-weight-bold"},[_vm._v("Monto de comisión a pagar: "+_vm._s(Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(_vm.data?.monto * (_vm.data?.porcentaje / 100)))+" ")]):(['suma_porcentaje'].includes(_vm.data?.tipo_operacion))?_c('span',{staticClass:"font-weight-bold"},[_vm._v("Monto de descuento: "+_vm._s(Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(_vm.data?.monto * (_vm.data?.porcentaje / 100)))+" "),_c('br')]):_vm._e(),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Monto total a pagar:")]),_vm._v(" "+_vm._s(Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(_vm.monto))+" "),_c('br')]]:_vm._e(),_c('br'),_c('span',{staticClass:"blue-grey--text"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Fecha y hora registro puja: ")]),_vm._v(" "+_vm._s(_vm.moment(_vm.data?.fecha_hora).format("HH:mm:ss:SSSS"))+" ")]),_c('br')]:_vm._e()],2)]:[_c('v-card-text',[_c('v-alert',{attrs:{"type":"info","outlined":""}},[_vm._v(" "+_vm._s(_vm.texto_estado)+" ")])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
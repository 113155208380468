<template>
  <section>
    <v-list :loading="cargando" v-if="salas?.length > 0" class="FixedHeightContainer">
      <v-list-item-group class="Content">
        <v-list-item v-for="(sala, index) in salas" :key="index" outlined
          @click="setSalaSeleccionada(sala), getSubastas()">
          <v-list-item-title>{{ sala.nombre }}</v-list-item-title>
          <v-list-item-action>
            <v-icon color="secondary" @click="lanzarModal(sala)">
              mdi-delete
            </v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <ConfirmationDialogComponent :show="showModalRoomDelete" btnConfirmar="Aceptar"
      title="¿Desea eliminar la sala seleccionada?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @close="setShowModalRoomDelete(false)" @confirm="eliminarSala" />
  </section>
</template>
<script>
import { mapState, mapActions } from "vuex";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";

export default {
  name: "CatalogoSubastaComponent",
  data: () => ({}),
  components: {
    ConfirmationDialogComponent,
  },
  computed: {
    ...mapState("configuracionSalas", [
      "salas",
      "sala_seleccionada",
      "cargando",
      "showModalRoomDelete",
    ]),
  },
  methods: {
    ...mapActions("configuracionSalas", [
      "crearSala",
      "eliminarSala",
      "getSalas",
      "setSalaSeleccionada",
      "eliminarSala",
      "setShowModalRoomDelete",
      "setProcesoCompra",
      "getSubastas",
    ]),
    lanzarModal(sala) {
      this.setShowModalRoomDelete(true);
      this.setSalaSeleccionada(sala);
    },
  },
  async created() {
    this.setProcesoCompra(this.$route.params.idProceso);
    await this.getSalas();
  },
};
</script>
<style scoped>
.FixedHeightContainer {
  height: 500px;
}

.Content {
  height: 495px;
  overflow-y: auto;
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('card-download-component',{attrs:{"title":"Resolución de recurso de revisión","archivo":{
        nombre: _vm.res_rec_apelacion?.nombre_documento,
        ruta: _vm.res_rec_apelacion?.ruta_documento,
      }}})],1),_c('v-col',{attrs:{"cols":"0","sm":"6"}}),(_vm.rec_apelacion == null)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex flex-column flex-sm-row"},[_c('v-file-input',{attrs:{"label":"Resolución de apelación","outlined":"","accept":"application/pdf","prepend-icon":"","prepend-inner-icon":"mdi-paperclip","clearable":"","error-messages":_vm.docApelacionErrors},on:{"blur":function($event){return _vm.$v.doc_apelacion.$touch()}},model:{value:(_vm.doc_apelacion),callback:function ($$v) {_vm.doc_apelacion=$$v},expression:"doc_apelacion"}}),_c('div',[_c('v-btn',{staticClass:"text-no-style mt-3 ml-sm-8",attrs:{"dark":"","color":"secondary","disabled":_vm.$v.$invalid},on:{"click":_vm.AddRecApelacion}},[_vm._v(" Cargar ")])],1)],1)]):_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('card-download-component',{attrs:{"title":_vm.rec_apelacion?.TipoImpugnacion?.nombre,"archivo":{
        nombre: _vm.rec_apelacion?.nombre_documento,
        ruta: _vm.rec_apelacion?.doc_impugnacion,
      }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <card-contrato-component />
</template>

<script>
import CardContratoComponent from "./CardContratoComponent.vue";

export default {
  name: "ContainerContratosComponent",

  components: {
    CardContratoComponent,
  },
};
</script>

<style>
</style>
<template>
  <v-card>
    <v-card-title>
      <v-icon size="28" color="subastaBlue">
        mdi-information-outline
      </v-icon>
      <span class="subastaBlue--text text-h6 mx-2">Detalle de lote actual</span>
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="loteDetail"
          :items-per-page="-1"
          hide-default-footer
      >
        <template #[`item.monto_sub_proceso_obs`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.monto_sub_proceso_obs)
          }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "DetalleLote",
  data: () => ({
    tab: null,
    loteDetail: [],
    headers: [
      {
        text: "Nombre",
        value: "nombre_agrupacion",
      },
      {
        text: "Unidad de medida",
        value: "unidad",
      },
      {
        text: "Cantidad",
        value: "total_sub_proceso",
      },
    ],
  }),
  props: {
    id_lote: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async getLoteDetail() {
      if (this.id_lote < 0) return;
      const {status, data} = await this.services.PacProcesos.obtenerDatosLote(
          this.id_lote
      );
      this.loteDetail = data?.SubProcesoObs?.map((col) => ({
        ...col,
        unidad: col?.Presentacion.nombre,
      }));
    },
  },
  async mounted() {
    await this.getLoteDetail();
  }
}
</script>

<style lang="scss" scoped>

:deep(
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > thead
      > tr:last-child
      > th.sortable
  ) {
  background: transparent !important;
}

:deep(.theme--light.v-data-table > .v-data-table__wrapper > table) {
  border: 1px solid #175efb !important;
}

:deep(.v-data-table-header tr th span) {
  color: #175efb !important;
}

:deep(
    .theme--light.v-data-table
      .v-data-table-header
      th
      .v-data-table-header__icon
  ) {
  color: #175efb !important;
}

:deep(
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr:last-child
      > td
  ) {
  background: transparent !important;
  border-top: 1px solid #175efb !important;
}

</style>

<template>
  <section>
    <div v-for="item in list_contrat_var" :key="item.id">
      <v-card class="my-6">
        <v-row class="px-4 py-3">
          <v-col cols="12" class="d-flex justify-space-between secondary--text">
            <span class="text-h6">
              {{ item.numero }} - {{ item.creado_por.persona }}
            </span>
            <span class="text-h5">
              {{
                Intl.NumberFormat("en-US", {
                  currency: "USD",
                  style: "currency",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(item.monto_adjudicado)
              }}
            </span>
          </v-col>
          <v-col cols="12" class="pt-0">
            <span class="secondary--text">
              {{ item.nombre_contrato }} elaborado el
              {{ FormatDatePrt(item.fecha_hora_contratacion) }}
            </span>
          </v-col>
          <v-col cols="12">
            <container-etapas-component
              :info-general-prop="item"
              :id-contrato-prop="item.id"
            />
          </v-col>
        </v-row>
      </v-card>
    </div>
  </section>
</template>

<script>
import { mapMutations } from "vuex";
import ContainerEtapasComponent from "./ContainerEtapasComponent.vue";

export default {
  name: "CardContratoComponent",

  components: {
    ContainerEtapasComponent,
  },

  data: () => ({
    list_contrat_var: null,
  }),

  methods: {
    ...mapMutations("procesoCompraDoc", ["setListContrato"]),
    async FetchDetailContratoFtn() {

      const { data, status } =
        await this.services.PacProcesos.getAuditContratos(
          this.$route.params.idProceso
        );


      if (status === 200) {
        this.list_contrat_var = data;
        this.setListContrato(data);
      }
    },
  },

  created() {
    this.FetchDetailContratoFtn();
  },

  beforeDestroy() {
    this.setListContrato([]);
  },
};
</script>

<style>
</style>
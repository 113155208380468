<template>
  <section v-if="permisos.escribir">
    <v-row no-gutters align="center">
      <v-col cols="12" sm="12" md="6">
        <p class="text-h6 secondary--text">Salas</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          v-model="nueva_sala"
          label="Nombre de sala *"
          outlined
          :error-messages="nuevaSalaError"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-btn dark color="secondary" @click="agregarSala">
          Agregar Sala
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p>Listado de salas</p>
        <DataTable
          :headers="headers"
          :items="items"
          v-models:select="por_pagina"
          v-models:pagina="pagina"
          :total_registros="total_registros"
          @paginar="paginar"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              @click="
                $router.push({
                  name: 'subasta-inversa-agregar-sala',
                  params: {
                    idProceso: $route.params.idProceso,
                    idSala: item.id,
                  },
                })
              "
              color="secondary"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              color="secondary"
              @click="(showModalRoomDelete = true), (roomToAction = item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </DataTable>
      </v-col>
    </v-row>

    <ConfirmationDialogComponent
      :show="showModalRoomDelete"
      btnConfirmar="Aceptar"
      title="¿Desea eliminar la sala seleccionada?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @close="showModalRoomDelete = false"
      @confirm="roomDelete"
    />
  </section>
</template>
<script>
import DataTable from "@/components/DataTableComponent.vue";
import { required, helpers } from "vuelidate/lib/validators";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";

const letter = helpers.regex(
  "letter",
  /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9,.:/#$%()"" -]*$/
);

export default {
  name: "CatalogoSubastaComponent",
  props: {
    etapa: {
      type: Object,
      default: {},
    },
    permisos: {
      type: Object,
      default: {},
    },
  },
  components: {
    DataTable,
    ConfirmationDialogComponent,
  },
  data: () => ({
    headers: [
      {
        text: "Nombre",
        value: "nombre",
      },
      { text: "Acciones", align: "center", value: "actions" },
    ],
    items: [],
    pagina: 1,
    por_pagina: 5,
    total_registros: 0,
    nueva_sala: null,
    showModalRoomDelete: false,
    roomToAction: null,
  }),
  validations: {
    nueva_sala: {
      required,
      letter,
    },
  },
  computed: {
    nuevaSalaError() {
      const errors = [];
      if (!this.$v.nueva_sala.$dirty) return errors;
      !this.$v.nueva_sala.required &&
        errors.push("Nombre de sala es requerido");
      !this.$v.nueva_sala.letter &&
        errors.push("Nombre  de sala no puede contener caracteres especiales");
      return errors;
    },
  },
  methods: {
    async paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.pagina = pagina;
      this.por_pagina = cantidad_por_pagina;

      await this.getSalas();
    },
    async agregarSala() {
      this.$v.$touch();
      if (!this.$v.$invalid) {

        const { status } = await this.services.PacProcesos.creacionSala(
          this.$route.params.idProceso,
          { nombre: this.nueva_sala }
        );

        if (status == 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Sala creada con éxito",
          });
          this.nueva_sala = null;
          this.$v.$reset();
          await this.getSalas();
        }

      }
    },
    async getSalas() {

      const { status, data, headers } =
        await this.services.PacProcesos.getSalas(this.$route.params.idProceso, {
          page: this.pagina,
          per_page: this.por_pagina,
        });
      if (status == 200) {
        this.items = data;
        this.total_registros = Number(headers.total_rows);
      }

    },
    async roomDelete() {

      const { status } = await this.services.PacProcesos.removeSala(
        this.roomToAction.id
      );

      if (status == 204) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "La sala seleccionada ha sido eliminada",
        });
        this.showModalRoomDelete = false;
        this.roomToAction = null;
        await this.getSalas();
      }


    },
  },
  async created() {
    await this.getSalas();
  },
};
</script>

<template>
  <section>
    <div v-for="item in dataProp" :key="item.id">
      <card-etapa-contrato-component :detail-prop="item" class="my-3" />
    </div>
  </section>
</template>

<script>
import CardEtapaContratoComponent from "../CardEtapaContratoComponent.vue";
export default {
  name: "TabEtapaActualComponent",

  components: {
    CardEtapaContratoComponent,
  },

  props: {
    dataProp: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
</style>
<template>
  <section>
    <div v-if="list_solitd_necesd_var.length === 0">
      <div class="no-data-message">
        <p>No hay datos disponibles</p>
      </div>
    </div>
    <div v-else>
      <div v-for="item in list_solitd_necesd_var" :key="item.id">
        <card-main-component :detail-prop="item" />
      </div>
    </div>
  </section>
</template>

<script>
import CardMainComponent from "./CardSNComponent.vue";

export default {
  name: "ContainerSNComponent",

  components: {
    CardMainComponent,
  },

  data: () => ({
    list_solitd_necesd_var: [],
  }),

  methods: {
    async FetchSolicitudNecesidadFtn() {

      const { data, status } =
        await this.services.PacProcesos.getSolicitudesNecesidad(
          this.$route.params.idProceso
        );


      if (status === 200) {
        this.list_solitd_necesd_var = data;
      }
    },
  },

  created() {
    this.FetchSolicitudNecesidadFtn();
  },
};
</script>

<style>
.no-data-message {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
</style>
<template>
  <section class="ma-8">
    <AdmisionRecursoComponent
      v-if="etapaProceso?.id_etapa_proceso_impugnacion == 1"
      :etapa="etapaProceso"
    />

    <ResolucionRecursosComponent
      :etapa="etapaProceso"
      v-if="etapaProceso?.id_etapa_proceso_impugnacion == 2"
    />
    <PresentacionRecursoApelacion
      v-if="etapaProceso?.id_etapa_proceso_impugnacion == 3"
      :etapa="etapaProceso"
    />
    <AdmisionRecursoApelacionComponent
      v-if="etapaProceso?.id_etapa_proceso_impugnacion == 4"
      :etapa="etapaProceso"
    />
    <ResolucionRecursoApelacionComponent
      v-if="etapaProceso?.id_etapa_proceso_impugnacion == 5"
      :etapa="etapaProceso"
    />
    <ResolucionImpugnacionesComponent
      v-if="!etapaProceso"
      :etapa="etapaProceso"
    />

    <v-row class="my-8">
      <v-btn color="Secondary" outlined @click="$router.back()">Volver</v-btn>
    </v-row>
  </section>
</template>

<script>
import RecepcionRecursosComponent from "./componentesUCP/RecepcionRecursosComponent.vue";
import AdmisionRecursoComponent from "./componentesUCP/AdmisionRecursoComponent.vue";
import ResolucionRecursosComponent from "./componentesUCP/ResolucionRecursosComponent.vue";
import PresentacionRecursoApelacion from "./componentesUCP/PresentacionRecursoApelacionComponent.vue";
import ResolucionImpugnacionesComponent from "./componentesUCP/ResolucionImpugnacionesComponent.vue";
import AdmisionRecursoApelacionComponent from "./componentesUCP/AdmisionRecursoApelacionComponent.vue";
import ResolucionRecursoApelacionComponent from "./componentesUCP/ResolucionRecursoApelacionComponent.vue";
import { mapActions } from "vuex";
export default {
  name: "UcpEtapaActualComponent",
  components: {
    RecepcionRecursosComponent,
    AdmisionRecursoComponent,
    ResolucionRecursosComponent,
    PresentacionRecursoApelacion,
    ResolucionImpugnacionesComponent,
    AdmisionRecursoApelacionComponent,
    ResolucionRecursoApelacionComponent,
  },
  data: () => ({
    etapaProceso: null,
  }),
  methods: {
    ...mapActions("procesoCompraDoc", ["getEtapaActualProcesoImpugnacion"]),
    async getEtapaActual() {
      const response = await this.getEtapaActualProcesoImpugnacion({
        id_proceso_compra: this.$route.params.idProceso,
      });
      this.etapaProceso = response;
    },
  },
  created() {
    this.getEtapaActual();
  },
};
</script>

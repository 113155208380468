<template>
  <v-card>
    <v-card-title class="secondary white--text">
      Sala: {{ sala_seleccionada?.nombre }}</v-card-title
    >
    <v-card-actions>
      <v-btn @click="setShowModalEditCreateSubasta(true)" class="secondary">
        Agregar Subasta
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="subastas"
        v-if="subastas?.length > 0"
      >
        <template #[`item.actions`]="{ item }">
          <v-icon
            @click="
              setSubastaSeleccionada(item), setShowModalEditCreateSubasta(true)
            "
            color="secondary"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            @click="
              setSubastaSeleccionada(item), setShowModalSubastaDelete(true)
            "
            color="secondary"
          >
            mdi-delete
          </v-icon>
        </template>
        <template #[`item.fecha_hora_inicio`]="{ item }">
          {{ moment(item.fecha_hora_inicio).format("DD/MM/YYYY hh:mm a") }}
        </template>
        <template #[`item.fecha_hora_final`]="{ item }">
          {{ moment(item.fecha_hora_final).format("DD/MM/YYYY hh:mm a") }}
        </template>
        <template #[`item.monto_inicial`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.monto_inicial)
          }}
        </template>
      </v-data-table>
      <v-chip v-else> No hay subastas programadas en esta sala</v-chip>
    </v-card-text>
    <ConfirmationDialogComponent
      :show="showModalSubastaDelete"
      btnConfirmar="Aceptar"
      title="¿Desea eliminar la subasta seleccionada?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @close="setShowModalSubastaDelete(false)"
      @confirm="deleteSubasta"
    />
    <subastaForm v-if="showModalEditCreateSubasta" @actualizar="getSubastas" />
  </v-card>
</template>
<script>
import { mapState, mapActions } from "vuex";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";
import subastaForm from "@/views/ProcesoCompraDoc/components/Etapas/configuracionSalas/subastaForm.vue";

export default {
  name: "SalaCard",
  components: {
    ConfirmationDialogComponent,
    subastaForm,
  },
  computed: {
    ...mapState("configuracionSalas", [
      "sala_seleccionada",
      "subastas",
      "headers",
      "showModalSubastaDelete",
      "showModalEditCreateSubasta",
      "form_enviar",
    ]),
  },
  methods: {
    ...mapActions("configuracionSalas", [
      "getSubastas",
      "setSubastaSeleccionada",
      "setShowModalSubastaDelete",
      "deleteSubasta",
      "setShowModalEditCreateSubasta",
    ]),
  },
};
</script>

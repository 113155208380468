<template>
  <v-card class="my-6">
    <v-row class="px-4 pt-3">
      <v-col cols="12">
        <span>
          {{ finamtProp?.fondo?.cifrado_presupuestario }} ({{
            finamtProp?.fondo?.tipo_fondo === 1 ? "Proyecto" : "Votado"
          }})
        </span>
      </v-col>
      <v-col cols="12" class="text-h6">
        {{
          Intl.NumberFormat("en-US", {
            currency: "USD",
            style: "currency",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(finamtProp?.monto)
        }}
      </v-col>
      <v-col cols="12">
        <data-table-component
          class="mb-3"
          :headers="HEADERS_CONST"
          :items="finamtProp?.log"
          inhabilitar_paginacion
          :tiene_paginacion="false"
        >
          <template v-slot:[`item.updated_at`]="{ item }">
            {{ FormatDatePrt(item?.updated_at, true) }}
          </template>
          <template v-slot:[`item.monto_anterior`]="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(item?.monto_anterior)
            }}
          </template>
          <template v-slot:[`item.monto_asignado`]="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(item?.monto_asignado)
            }}
          </template>
        </data-table-component>
      </v-col>
      <v-col cols="12" v-if="!finamtProp?.registro_ajuste && finamtProp.ajuste != null">
        <v-row class="px-4 pt-3">
          <v-col cols="12" class="d-flex flex-column">
            <span class="text-h6">Ajuste</span>
            <span>
              {{ finamtProp?.ajuste?.fu?.Fondo?.cifrado_presupuestario }} ({{
                finamtProp?.ajuste?.fu?.Fondo?.tipo_fondo === 1
                  ? "Proyecto"
                  : "Votado"
              }})
            </span>
          </v-col>
          <v-col cols="12" class="text-h6">
            {{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                style: "currency",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(finamtProp?.ajuste?.monto)
            }}
          </v-col>
          <v-col cols="12">
            <data-table-component
              class="mb-3"
              :headers="HEADERS_CONST"
              :items="finamtProp?.ajuste?.lg_fnds"
              inhabilitar_paginacion
              :tiene_paginacion="false"
            >
              <template v-slot:[`item.updated_at`]="{ item }">
                {{ FormatDatePrt(item?.updated_at, true) }}
              </template>
              <template v-slot:[`item.monto_anterior`]="{ item }">
                {{
                  Intl.NumberFormat("en-US", {
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(item?.monto_anterior)
                }}
              </template>
              <template v-slot:[`item.monto_asignado`]="{ item }">
                {{
                  Intl.NumberFormat("en-US", {
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(item?.monto_asignado)
                }}
              </template>
            </data-table-component>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import DataTableComponent from "@/components/DataTableComponent.vue";

export default {
  name: "CardFinamtSNComponent",

  components: {
    DataTableComponent,
  },

  props: {
    finamtProp: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    HEADERS_CONST: [
      { text: "Fecha de modificación", value: "updated_at" },
      { text: "Usuario", value: "Usuario.email" },
      { text: "Monto anterior ($)", value: "monto_anterior" },
      { text: "Monto asignado ($)", value: "monto_asignado" },
    ],
  }),
};
</script>

<style>
</style>
<template>
  <v-row>
    <template v-if="!winner">
      <v-col cols="12" sm="8" v-if="recurso_apelacion?.id_estado_impugnacion == 1">
        <span>
          Su recurso de apelación está siendo aprobado por TACOP, se notificará su
          aprobación o rechazo en el rango de fechas establecidas para esta etapa.
        </span>
      </v-col>
      <v-col cols="12" sm="8" v-if="recurso_apelacion?.id_estado_impugnacion == 6">
        <v-sheet color="white" elevation="1" rounded class="my-1">
          <div class="d-flex flex-column px-6 py-3">
            <div class="d-flex align-center">
              <v-icon class="mr-3" color="green">mdi-check-circle</v-icon>
              <span class="green--text">Recurso de apelación admitido</span>
            </div>
            <span>
              Su recurso de apelación ha sido admitido. A partir del día
              <b>
                {{ moment(fecha_resolucion).format("dddd D [de] MMMM [de] YYYY") }}
              </b>
              TACOP cuenta con 12 días hábiles para presentar una resolución la cual se le
              notificará a través de este sistema y mediante correo electrónico.
            </span>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="8" v-if="recurso_apelacion?.id_estado_impugnacion == 7">
        <v-sheet color="white" elevation="1" rounded class="my-1">
          <div class="d-flex flex-column px-6 py-3">
            <div class="d-flex align-center">
              <v-icon class="mr-3" color="red">mdi-information</v-icon>
              <span class="red--text">Recurso de apelación rechazado</span>
            </div>
            <span>
              Su recurso de apelación ha sido rechazado. A continuación TACOP le hace
              saber la razón por la cual consideraq su recurso no ha admitido.
            </span>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="6" v-if="recurso_apelacion">
        <card-download-component
          :title="recurso_apelacion?.TipoImpugnacion?.nombre"
          :archivo="{
            nombre: recurso_apelacion?.nombre_documento,
            ruta: recurso_apelacion?.doc_impugnacion,
          }"
        />
      </v-col>
      <v-col cols="12" v-if="buffer_documento">
        <embed
          :src="buffer_documento"
          type="application/pdf"
          width="100%"
          height="600px"
        />
      </v-col>
    </template>
    <template v-else>
      <v-col cols="12" sm="8" v-if="recurso_apelacion?.id_estado_impugnacion == 6">
        <v-sheet color="secondary" elevation="1" rounded class="my-1" dark>
          <div class="d-flex flex-column px-6 py-3">
            <div class="d-flex align-center">
              <v-icon class="mr-3" color="yellow">mdi-alert</v-icon>
              <span class="yellow--text"> Se ha admitido recurso de apelación </span>
            </div>
            <span>
              Se ha admitido recurso de apelación durante el proceso. A partir de la fecha
              de admisión cuentas con <b>3 días hábiles</b> para cargar tu pronunciamiento
              sobre el recurso.
            </span>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="12">
        <data-table-component
          :headers="headers_apelacion"
          :items="lista_documentos"
          :tiene_paginacion="false"
          class="mb-3"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            {{ moment(item.created_at).format("DD/MM/YYYY") || "-" }}
          </template>
          <template v-slot:[`item.fecha_admision_tacop`]="{ item }">
            {{ moment(item.fecha_admision_tacop).format("DD/MM/YYYY") || "-" }}
          </template>
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip class="ma-2" label>
              {{ item?.EstadoImpugnacion.nombre }}
            </v-chip>
          </template>
          <!-- slot de acciones -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="OpenModal(item)">
                  <v-icon color="secondary">
                    {{
                      item?.doc_pronunciamiento_apelacion
                        ? "mdi-eye"
                        : "mdi-file-document-alert"
                    }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{ item?.doc_pronunciamiento_apelacion ? "Ver" : "Pronunciamiento" }}
              </span>
            </v-tooltip>
          </template>
        </data-table-component>
      </v-col>
    </template>
    <modal-pronunciamiento-component ref="ref_modal" @reload="VerifyGanador" />
  </v-row>
</template>

<script>
import DataTableComponent from "../../../../../components/DataTableComponent.vue";
import CardDownloadComponent from "./CardDownloadComponent.vue";
import { mapState, mapActions } from "vuex";
import ModalPronunciamientoComponent from "./ModalPronunciamientoComponent.vue";

export default {
  name: "AdmisionRecApeComponent",

  components: {
    DataTableComponent,
    CardDownloadComponent,
    ModalPronunciamientoComponent,
  },

  data: () => ({
    headers_apelacion: [
      { text: "Código", value: "correlativo" },
      { text: "Proveedor", value: "Usuario.Proveedor.nombre_comercial" },
      { text: "Fecha de presentación", value: "created_at" },
      { text: "Fecha de admisión", value: "fecha_admision_tacop" },
      { text: "Estado", value: "estado" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    recurso_apelacion: {},
    buffer_documento: null,
    winner: false,
    lista_documentos: [],
    tipoEtapa: null,
  }),

  computed: {
    ...mapState("procesoCompraDoc", ["fecha_resolucion", "procesoData"]),
  },

  methods: {
    ...mapActions("procesoCompraDoc", ["descargarBufferDocumento"]),

    OpenModal(item) {
      this.$refs.ref_modal.show_dialog = true;
      this.$refs.ref_modal.InitDetailPron(item);
    },

    async VerifyGanador() {
      const { status, data } = await this.services.PacProcesos.estadoEtapaProceso(
        this.$route.params.idProceso,
        2
      );
      if (status === 200) {
        if (data.length > 0) {
          if (this.tipoEtapa == 1) {
            this.winner = data[0].tecnica;
          } else {
            this.winner = data[0].economica;
          }
        }
      }

      if (this.winner) {
        this.FetchListDocs();
      } else {
        this.FetchRecRev();
      }
    },

    async FetchListDocs() {
      const {
        data,
        status,
      } = await this.services.RecursoRevision.getImpugnacionInstitucion(
        this.$route.params.idProceso,
        {
          id_tipo: 2,
          estados: "[6]",
          id_etapa_proceso: this.procesoData.id_etapa_pausa,
        }
      );

      if (status === 200) {
        this.lista_documentos = data;
      }
    },

    async FetchRecRev() {
      const {
        status,
        data,
      } = await this.services.RecursoRevision.getRecursosProveedorPorTipo(
        this.$route.params.idProceso,
        2,
        {
          pagination: false,
          id_etapa_proceso: this.procesoData.id_etapa_pausa,
        }
      );

      if (status === 200) {
        this.recurso_apelacion = data[0];

        if (
          this.recurso_apelacion?.id_estado_impugnacion == 6 ||
          this.recurso_apelacion?.id_estado_impugnacion == 7
        ) {
          this.FetchImpugnacion(this.recurso_apelacion.id);
        }
      }
    },

    async FetchImpugnacion(id_impugnacion) {
      const { status, data } = await this.services.PacProcesos.getDetailImpugnacion(
        id_impugnacion
      );

      if (status === 200) {
        const file = await this.descargarBufferDocumento({
          ruta_documento: data.doc_impugnacion,
        });

        let b64 = Buffer.from(file).toString("base64");
        this.buffer_documento = `data:application/pdf;base64,${b64}`;
      }
    },

    async getTipoEtapaPrevia() {
      const { status, data } = await this.services.RecursoRevision.getTipoEtapaPrevia(
        this.$route.params.idProceso
      );
      if (status == 200) {
        this.tipoEtapa = data?.id_tipo_etapa;
      }
    },
  },

  async created() {
    await this.getTipoEtapaPrevia();
    this.VerifyGanador();
  },
};
</script>

<style></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-row',{staticClass:"px-4 py-2"},[_c('v-col',{staticClass:"text-center text-sm-start",attrs:{"cols":"12"}},[_c('span',[_vm._v(" Resolución de recurso de "+_vm._s(_vm.titleProp)+" ")]),(
          !_vm.resolucionProp.resolucion &&
          _vm.resolucionProp.otros_documentos.length == 0
        )?_c('span',{staticClass:"error--text"},[_vm._v(" No hay información disponible ")]):_vm._e()]),(_vm.resolucionProp.resolucion)?_c('v-col',{staticClass:"d-flex flex-column flex-sm-row align-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"text-no-style",attrs:{"dark":"","color":"secondary"},on:{"click":function($event){return _vm.descargarArchivoProceso({
            ruta_documento: _vm.resolucionProp.resolucion.ruta_documento,
          })}}},[_c('v-icon',[_vm._v(" mdi-download ")]),_c('span',[_vm._v(" Descargar resolución ")])],1),_c('div',{staticClass:"d-flex flex-column ml-sm-10 text-center text-sm-start"},[_c('span',{domProps:{"textContent":_vm._s(_vm.resolucionProp.resolucion.Usuario.email)}}),_c('span',{domProps:{"textContent":_vm._s(_vm.resolucionProp.resolucion.Usuario.nombre_usuario)}})])],1):_vm._e(),(_vm.resolucionProp.otros_documentos.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('data-table-component',{staticClass:"mb-3",attrs:{"headers":_vm.HEADER_CONST,"inhabilitar_paginacion":"","tiene_paginacion":false,"items":_vm.resolucionProp.otros_documentos},scopedSlots:_vm._u([{key:`item.created_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.FormatDatePrt(item.created_at))+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.descargarArchivoProceso({
                    ruta_documento: item.ruta_documento,
                  })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Descargar")])])]}}],null,true)})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
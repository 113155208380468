<template>
    <v-dialog v-model="isOpen" max-width="1500" persistent>
        <div style="background-color: #FFFFFF; padding: 0 1em 2em; position: relative;">
            <div class="py-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                <v-btn
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;"
                    class="px-4 py-4" 
                    @click.stop="cerrarModal" 
                    text rounded>
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
            </div>
            <DocProveedorComponent 
                @on-close="cerrarModal"
            />
        </div>
    </v-dialog>
</template>

<script>
import { ConfirmationModalComponent } from '@/components/utils';
import DocProveedorComponent from './DocProveedorComponent.vue';

export default {
    name: 'ModalCargarDocumentos',
    components: { ConfirmationModalComponent, DocProveedorComponent },
    emits: ['on-visibility-change', 'on-observar', 'on-etapa-no-valida'],
    props: {
        isOpen: { type: Boolean },
        idOferta: { type: Number },
    },
    data: () =>({
    }),
    methods: {
        cerrarModal() {
            this.$emit('close');
        },
    },
}
</script>
4
<template>
  <v-card>
    <v-card-title>
      <v-icon size="28" color="subastaBlue">
        mdi-checkbox-marked-circle-outline
      </v-icon>
      <span class="subastaBlue--text text-h6 mx-2">
        Detalles de la subasta
      </span>
    </v-card-title>
    <v-card-text>
      <template>
        Fecha y hora de inicio:
        {{ moment(item?.fecha_hora_inicio).format("DD/MM/YYYY HH:mm:ss") }}
        <br />
      </template>
      <template>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              Fecha y hora prevista de finalización: {{
                moment(item?.fecha_hora_final).format("DD/MM/YYYY HH:mm:ss") }} <v-icon class="mb-1"
                size="20">mdi-information-outline</v-icon>
            </span>
          </template>
          <span>Si la hora prevista de cierre excede la hora finalizada la subasta finalizaará a la hora de cierre
            programado</span>
        </v-tooltip>
        <br />
      </template>
      <template>
        Fecha y hora de finalización programada:
        {{
          moment(item?.fecha_programada_finalizacion).format(
            "DD/MM/YYYY HH:mm:ss"
          )
        }}
        <br />
      </template>
      <template>
        Tiempo configurado para declarar desierto:
        {{ item.tiempo_maximo_desierto }}
        <br />
      </template>
      <template>
        Tiempo configurado entre ofertas: {{ item.tiempo_maximo_oferta }}
        <br />
      </template>
      <template v-if="![1, 2, 4].includes(item.tipo_id)">
        Salto configurado entre ofertas:
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item?.salto_oferta)
        }}
        <br />
      </template>
      <template>
        Monto inicial:
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item?.monto_inicial)
        }}
        <br />
      </template>
      <template v-if="item?.tipo === 'resta_unitaria'">
        Cantidad de items subastados: {{ item.cantidad }}
        <br />
      </template>
      <template>
        <br />
        <v-chip class="secondary white--text">
          {{ texto_estado }}
        </v-chip>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import moment from "moment";
export default {
  name: "DetalleSubasta",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    texto_estado() {
      return moment().isBetween(
        moment(this.item?.fecha_hora_inicio),
        moment(this.item?.fecha_hora_final)
      )
        ? "Subasta en curso"
        : moment().isBefore(moment(this.item?.fecha_hora_inicio))
          ? "Subasta no iniciada"
          : "Subasta finalizada";
    },
  },
};
</script>

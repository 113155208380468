<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        Cambios en solicitud de necesidad
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <data-table-component
          :headers="HEADERS_CONST"
          :items="listChangeProp"
          inhabilitar_paginacion
          :tiene_paginacion="false"
        >
          <template v-slot:[`item.updated_at`]="{ item }">
            {{ FormatDatePrt(item.updated_at, true) }}
          </template>
        </data-table-component>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import DataTableComponent from "@/components/DataTableComponent.vue";

export default {
  name: "CardCambiosSNComponent",

  components: {
    DataTableComponent,
  },

  props: {
    listChangeProp: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    HEADERS_CONST: [
      {
        text: "Fecha de modificación",
        value: "updated_at",
      },
      {
        text: "Usuario de modificación",
        value: "Usuario.nombre",
      },
      {
        text: "Estado anterior",
        value: "estado_anterior.nombre",
      },
      {
        text: "Nuevo estado",
        value: "estado_asignado.nombre",
      },
    ],
  }),

  methods: {},

  created() {},
};
</script>8
<template>
  <section>
    <v-tabs class="mt-8" v-model="tab_var" show-arrows>
      <v-tab
        v-for="item in listTabCmp"
        class="secondary--text text-no-style"
        :key="item.id"
      >
        <v-icon color="secondary" small class="mr-2">
          {{ item.icon }}
        </v-icon>
        <span>{{ item.tab }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab_var" class="bgWhite">
      <v-tab-item v-for="item in listTabCmp" :key="item.id">
        <component :is="item.content" :data-prop="item.data" :id-contrato-prop="idContratoProp" :infoGeneral="infoGeneralProp" class="py-3" />
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>

<script>
import TabEtapaActualComponent from "./etapas/TabEtapaActualComponent.vue";
import TabPagosComponent from "./etapas/TabPagosComponent.vue";
import TabSancionesComponent from "./etapas/TabSancionesComponent.vue";
import TabDocumentosComponent from "./etapas/TabDocumentosComponent.vue";
import TabGeneralComponent from "./etapas/TabGeneralComponent.vue";

export default {
  name: "ContainerEtapasComponent",

  props: {
    infoGeneralProp: {
      type: Object,
      required: true,
    },
    idContratoProp: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    tab_var: null,
  }),

  computed: {
    listTabCmp() {
      return [
        {
          id: 1,
          tab: "Etapas de contrato",
          icon: "mdi-clock-outline",
          content: TabEtapaActualComponent,
          data: this.infoGeneralProp.etapas,
        },
        {
          id: 2,
          tab: "Pagos",
          icon: "mdi-currency-usd",
          content: TabPagosComponent,
          data: this.infoGeneralProp.pagos,
        },
        {
          id: 3,
          tab: "Sanciones",
          icon: "mdi-clipboard-text-clock-outline",
          content: TabSancionesComponent,
          data: this.infoGeneralProp.sanciones,
        },
        {
          id: 4,
          tab: "Documentos",
          icon: "mdi-file-document-multiple-outline",
          content: TabDocumentosComponent,
          data: this.infoGeneralProp.documentos,
        },
        {
          id: 5,
          tab: "General",
          icon: "mdi-information-outline",
          content: TabGeneralComponent,
          data: this.infoGeneralProp.plazo_contractual,
        },
      ];
    },
  },
};
</script>

<style>
</style>
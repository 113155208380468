<template>
  <v-card class="my-6 bgMinsal">
    <v-row class="px-4 pt-3">
      <v-col cols="12">
        <v-row no no-gutters>
          <v-col cols="11" md="5" class="text-start" order="1">
            <span v-text="etapaProp.CtlEtapasProcesoImpugnacion.nombre" />
          </v-col>
          <template v-if="!noChangesEtapaCmp">
            <v-col
              cols="12"
              md="2"
              class="text-md-start"
              order="3"
              order-md="2"
            >
              <span class="font-weight-bold" v-text="'Fecha inicio: '" />
              <span v-text="FormatDatePrt(etapaProp.fecha_inicio, true)" />
            </v-col>
            <v-col cols="12" md="2" class="text-md-end" order="4" order-md="3">
              <span class="font-weight-bold" v-text="'Fecha fin: '" />
              <span v-text="FormatDatePrt(etapaProp.fecha_fin, true)" />
            </v-col>
            <v-col cols="12" md="2" class="text-md-end" order="5" order-md="4">
              <span class="font-weight-bold" v-text="'Duración: '" />
              <span v-text="CalculateDurationFtn(etapaProp)" />
            </v-col>
            <v-col cols="1" class="text-end" order="2" order-md="5">
              <v-btn icon @click="show_history_var = !show_history_var">
                <v-icon>mdi-history</v-icon>
              </v-btn>
            </v-col>
          </template>
          <v-col cols="12" md="7" v-else order="6">
            <span class="error--text"> No hay cambios en esta etapa </span>
          </v-col>
        </v-row>
      </v-col>
      <v-fade-transition>
        <template v-if="show_history_var">
          <v-col cols="12">
            <data-table-component
              class="mb-3"
              table_title="Historial de modificaciones"
              :headers="HEADERS_CONST"
              inhabilitar_paginacion
              :tiene_paginacion="false"
              :items="etapaProp.LogEtapaProcesoImpugnaciones"
            >
              <template v-slot:[`item.updated_at`]="{ item }">
                {{ FormatDatePrt(item.updated_at, true) }}
              </template>
              <template v-slot:[`item.fecha_inicio`]="{ item }">
                {{ FormatDatePrt(item.fecha_inicio, true) }}
              </template>
              <template v-slot:[`item.fecha_fin`]="{ item }">
                {{ FormatDatePrt(item.fecha_fin, true) }}
              </template>
            </data-table-component>
          </v-col>
        </template>
      </v-fade-transition>
    </v-row>
  </v-card>
</template>
  <script>
import DataTableComponent from "@/components/DataTableComponent.vue";

export default {
  name: "CardItemPIPComponent",

  components: {
    DataTableComponent,
  },

  props: {
    etapaProp: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    // 
    HEADERS_CONST: [
      { text: "Usuario", value: "Usuario.nombre" },
      { text: "Fecha de modificación", value: "updated_at" },
      { text: "Fecha de inicio", value: "fecha_inicio" },
      { text: "Fecha de fin", value: "fecha_fin" },
    ],

    //variables
    show_history_var: false,
    list_modificaciones_var: [],
  }),

  computed: {
    noChangesEtapaCmp() {
      const no_dates =
        this.etapaProp.fecha_inicio === null ||
        this.etapaProp.fecha_fin === null;

      return (
        this.etapaProp?.LogEtapaProcesoImpugnaciones?.length === 0 && no_dates
      );
    },
  },

  methods: {
    CalculateDurationFtn(etapa_param) {
      if (etapa_param.fecha_inicio === null || etapa_param.fecha_fin === null)
        return;
      const fecha_inicio = this.moment(etapa_param.fecha_inicio);
      const fecha_fin = this.moment(etapa_param.fecha_fin);
      const duration = this.moment.duration(fecha_fin.diff(fecha_inicio));
      return duration.humanize();
    },
  },
};
</script>
  
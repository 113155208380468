var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"secondary"},on:{"click":function($event){_vm.setShowModalEditCreateSubasta(false), _vm.resetForm()}}},[_vm._v(" Volver ")]),(
        !_vm.form_enviar.id &&
        _vm.form_enviar.id_lote &&
        _vm.form_enviar.ctlSubasta &&
        !_vm.lote_seleccionado?.id_lote_procedencia &&
        _vm.solicitudes_lote?.length > 1
      )?_c('v-btn',{attrs:{"dark":"","color":"secondary"},on:{"click":_vm.enviarFormularioUnitarias}},[_vm._v(" Enviar ")]):_c('v-btn',{attrs:{"dark":"","color":"secondary"},on:{"click":function($event){return _vm.saveSalaConf()}}},[_vm._v(" "+_vm._s(_vm.subasta_seleccionada?.id ? "Editar" : "Guardar")+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
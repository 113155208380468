<template>
  <section v-if="permisos.escribir">
    <v-row align="center">
      <v-col cols="12" sm="12" md="6">
        <p class="text-h6 secondary--text">Salas</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          clearable
          v-model="nueva_sala"
          label="Nombre de sala *"
          outlined
          :error-messages="nuevaSalaError"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-btn dark color="secondary" @click="agregarSala">
          Agregar Sala
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">
        <listadoSalas />
      </v-col>
      <v-col cols="12" md="9">
        <salaCard v-if="sala_seleccionada" />
        <v-chip v-else-if="salas?.length === 0"
          >No se han registrado salas</v-chip
        >
        <v-chip v-else>Seleccione una sala</v-chip>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { required, helpers } from "vuelidate/lib/validators";
import listadoSalas from "@/views/ProcesoCompraDoc/components/Etapas/configuracionSalas/listadoSalas.vue";
import salaCard from "@/views/ProcesoCompraDoc/components/Etapas/configuracionSalas/salaCard.vue";
const letter = helpers.regex(
  "letter",
  /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9,.:/#$%()"" -]*$/
);

export default {
  name: "CatalogoSubastaComponentRefactor",
  props: {
    etapa: {
      type: Object,
      default: {},
    },
    permisos: {
      type: Object,
      default: {},
    },
  },
  components: {
    salaCard,
    listadoSalas,
  },
  data: () => ({
    nueva_sala: null,
  }),
  validations: {
    nueva_sala: {
      required,
      letter,
    },
  },
  computed: {
    nuevaSalaError() {
      const errors = [];
      if (!this.$v.nueva_sala.$dirty) return errors;
      !this.$v.nueva_sala.required &&
        errors.push("Nombre de sala es requerido");
      !this.$v.nueva_sala.letter &&
        errors.push("Nombre  de sala no puede contener caracteres especiales");
      return errors;
    },
    ...mapState("configuracionSalas", ["salas", "sala_seleccionada"]),
  },
  methods: {
    ...mapActions("configuracionSalas", ["crearSala", "eliminarSala"]),
    async agregarSala() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.crearSala({ nombre: this.nueva_sala });
        this.nueva_sala = null;
      }
    },
  },
};
</script>

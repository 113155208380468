<template>
  <v-row no-gutters>
    <v-col cols="12" md="12" v-if="subasta_unitaria.rehacer_lotes">
      <v-data-table :headers="headers_lotes_rehacer" :items="solicitudes_lote" hide-default-footer disable-pagination>
        <template v-slot:[`item.precio_unitario`]="{ item }">
          {{
            Intl.NumberFormat("es-CL", {
              style: "currency",
              currency: "CLP",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.solicitud_compra_obs.precio_unitario)
          }}
        </template>
        <template v-slot:[`item.fecha_inicio`]="{ item }">
          <vc-date-picker v-model="item.fecha_inicio" mode="dateTime" locale="es" :popover="config_calendar"
            :min-date="fecha_actual">
            <template v-slot="{ inputEvents }">
              <v-text-field class="pt-5" :value="getFechaInicioParametrizable(item.fecha_inicio)" readonly
                :disabled="hideActions" v-on="inputEvents" outlined />
            </template>
          </vc-date-picker>
        </template>
        <template v-slot:[`item.fecha_fin`]="{ item }">
          <vc-date-picker v-model="item.fecha_fin" mode="dateTime" locale="es" :popover="config_calendar"
            :min-date="item.fecha_inicio">
            <template v-slot="{ inputEvents }">
              <v-text-field class="pt-5" :value="getFechaInicioParametrizable(item.fecha_fin)" readonly
                v-on="inputEvents" outlined />
            </template>
          </vc-date-picker>
        </template>
        <template v-slot:[`item.tiempo_maximo_oferta`]="{ item }">
          <v-text-field class="pt-5" v-model="item.tiempo_maximo_oferta" outlined prefix="(minutos)" type="number"
            :rules="tiempoMinutosRule()" />
        </template>
        <template v-slot:[`item.tiempo_maximo_desierto`]="{ item }">
          <v-text-field class="pt-5" v-model="item.tiempo_maximo_desierto" prefix="(minutos)" outlined type="number"
            :rules="tiempoMinutosRule()" />
        </template>
        <template v-slot:[`item.monto_inicial`]="{ item }">
          <v-text-field class="pt-5" v-model="item.monto_inicial" label="Monto inicial*" prefix="$"
            @paste="(e) => e.preventDefault()" outlined :rules="calcularRulesMontoInicial(item)" type="number" />
        </template>
        <template v-slot:[`item.saltos_oferta`]="{ item }">
          <v-text-field class="pt-5" v-model="item.saltos_oferta" label="Salto entre ofertas*" prefix="$"
            @paste="(e) => e.preventDefault()" outlined :rules="calcularRulesSalto(item)" type="number" />
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

import { mapState } from "vuex";
import { helpers } from "vuelidate/lib/validators";

const priceDecimals = helpers.regex(
  "priceDecimals",
  /^[0-9]+([.][0-9]{1,2})?$/
);

export default {
  name: "UnitariaAlerta",
  computed: {
    ...mapState("configuracionSalas", [
      "solicitudes_lote",
      "subasta_unitaria",
      "headers_lotes_rehacer",
      "config_calendar",
    ]),
  },
  data() {
    return {
      fecha_actual: new Date().toISOString(),
      hideActions: false,
    };
  },
  methods: {
    getFechaInicioParametrizable(fecha) {
      return fecha ? moment(fecha).format("DD/MM/YYYY hh:mm A") : "Pendiente";
    },
    calcularRulesMontoInicial({ precio_unitario }) {
      return [
        (v) => !!v || "Este campo es requerido",
        (v) => (v && v > 0) || "El valor debe ser mayor a 0",
        (v) =>
          (v && v <= precio_unitario) ||
          `El valor debe ser menor o igual a ${precio_unitario}`,
      ];
    },
    calcularRulesSalto({ monto_inicial, ...other }) {
      return [
        (v) => !!v || "Este campo es requerido",
        (v) => !!monto_inicial || "El monto inicial es requerido",
        (v) => (v && v > 0) || "El valor debe ser mayor a 0",
        (v) =>
          (v && v < Number(monto_inicial)) ||
          "El valor debe ser menor al monto inicial",
        (v) =>
          priceDecimals(v) || "El valor debe tener máximo dos decimales",
      ];
    },
    tiempoMinutosRule() {
      return [
        (v) => !!v || "Este campo es requerido",
        (v) => (v && v > 0) || "El valor debe ser mayor a 0",
        (v) => (v && v <= 60) || "El valor debe ser menor a 60",
      ];
    },
  },
};
</script>

<template>
  <section>
    <div v-if="solicitudesCompra.length === 0">
      <div class="no-data-message">
        <p>No hay datos disponibles</p>
      </div>
    </div>
    <div v-else>
      <v-expansion-panels v-model="panel" multiple accordion flat>
        <v-expansion-panel v-for="solicitud in solicitudesCompra" :key="solicitud.id_solicitud_compra">
          <v-expansion-panel-header>
            <div class="d-flex flex-wrap align-center">
              <span class="text-h6 secondary--text">{{ solicitud.codigo }}</span>
              <v-chip class="mx-4" color="secondary" outlined>
                {{ solicitud.estado.nombre }}</v-chip>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-4">
            <v-row>
              <p class="secondary--text text-h6">
                Usuario:
                {{ solicitud.persona?.primer_nombre }}
                {{ solicitud.persona?.primer_apellido }}
              </p>
            </v-row>
            <v-row>
              <p class="secondary--text text-h6">
                Correo: {{ solicitud.usuario?.email }}
              </p>
            </v-row>
            <v-row>
              <p class="secondary--text text-h6">
                Unidad : {{ solicitud.unidad }}
              </p>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-tabs id="tab-compras" class="my-4" centered v-model="solicitud.id" show-arrows>
                  <v-tab style="text-transform: none !important" v-for="item in contenido" class="secondary--text"
                    :key="item.id">
                    <span>{{ item.tab }}</span>
                  </v-tab>

                  <v-tab-item v-for="item in contenido" :key="item.id">
                    <component :solicitud="solicitud" :is="item.content" class="mt-8"></component>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </section>
</template>
<script>
import InsumosComponent from "./components/InsumosComponent.vue";
import AdminContratoComponent from "./components/AdminContratoComponent.vue";
import PreciosMercadoComponent from "./components/PreciosMercadoComponent.vue";
import DocsEspecificacionComponent from "./components/DocsEspecificacionComponent.vue";
import ModificacionContratoComponent from "./components/ModificacionContratoComponent.vue";
export default {
  name: "SolicitudCompraComponent",
  data: () => ({
    panel: [],
    id: null,
    solicitudesCompra: [],
  }),
  computed: {
    contenido() {
      return [
        {
          id: 1,
          tab: "Insumos",
          content: InsumosComponent,
        },
        {
          id: 2,
          tab: "Administradores de contrato",
          content: AdminContratoComponent,
        },
        {
          id: 3,
          tab: "Precios de mercado",
          content: PreciosMercadoComponent,
        },
        {
          id: 4,
          tab: "Documentos de especificación",
          content: DocsEspecificacionComponent,
        },
        {
          id: 5,
          tab: "Modificaciones al contrato",
          content: ModificacionContratoComponent,
        },
      ];
    },
  },
  methods: {
    async getSolicitudesCompra() {
      const { status, data } =
        await this.services.PacProcesos.getSolicitudesCompraAuditoria(
          this.$route.params.idProceso
        );

      if (status == 200) {
        this.solicitudesCompra = data;
      }
    },
  },
  created() {
    this.getSolicitudesCompra();
  },
};
</script>

<style>
.no-data-message {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
</style>
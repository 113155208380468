<template>
  <v-container v-if="catalogo.data.length > 0">
    <v-expansion-panels multiple accordion>
      <v-expansion-panel
        class="px-4 py-4"
        v-for="solicitud in catalogoInformacion"
        :key="solicitud.id"
      >
        <!-- Solicitudes de compra  -->
        <v-expansion-panel-header>
          <div>
            <v-icon class="mr-1" style="font-size: 30px"
              >mdi-cart-outline</v-icon
            >
            <b class="text-h6 font-weight-bold" style="line-height: 1.5rem">{{
              `Solicitud de compra - (${solicitud.id})`
            }}</b>
          </div>
        </v-expansion-panel-header>

        <!-- Contenido de la solicitud de compra  -->
        <v-expansion-panel-content>
          <DataTableComponent
            :headers="headers"
            class="mt-4"
            :items="[solicitud]"
            :tiene_paginacion="false"
          >
            <template v-slot:item.tipo_solicitud="{ item }">
              {{ item.id_tipo_solicitud === 2 ? "Servicio" : "Producto" }}
            </template>
            <template v-slot:item.autorizada="{ item }">
              {{ item.autorizada ? "Solicitud autorizada" : "Sin autorizar" }}
            </template>
            <template v-slot:item.descartada="{ item }">
              {{ item.descartada ? "Descartada" : "Sin descartar" }}
            </template>
            <template v-slot:item.con_observaciones="{ item }">
              {{
                item.con_observaciones
                  ? "Tiene observaciones"
                  : "Sin observaciones"
              }}
            </template>
            <template v-slot:item.fecha_autorizacion="{ item }">
              {{
                item.fecha_autorizacion
                  ? moment(item.fecha_autorizacion).format("DD/MM/YYYY")
                  : "Sin fecha de autorización"
              }}
            </template>
            <template v-slot:item.fecha_creacion="{ item }">
              {{
                item.created_at
                  ? moment(item.created_at).format("DD/MM/YYYY")
                  : "Sin fecha de creación"
              }}
            </template>
            <template v-slot:item.total="{ item }">
              ${{ item.total }}
            </template>
            <template v-slot:item.elaborada_por="{ item }">
              {{ item.elaborada_por }}
            </template>
          </DataTableComponent>

          <!-- Ordenes de compra  -->
          <v-expansion-panels multiple accordion>
            <v-expansion-panel class="px-4 py-4 mt-4">
              <v-expansion-panel-header class="text-h6 font-weight-bold">
                <div>
                  <v-icon class="mr-1" style="font-size: 30px"
                    >mdi-receipt-text-edit-outline</v-icon
                  >
                  <span>Órdenes de compra</span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels>
                  <template v-if="solicitud.orden.length > 0">
                    <v-expansion-panel
                      v-for="orden in solicitud.orden"
                      :key="orden.id"
                    >
                      <v-expansion-panel-header class="px-4 py-4">
                        <h4 class="font-weight-bold text-uppercase">
                          {{ orden.codigo }}
                        </h4>
                      </v-expansion-panel-header>
                      <v-divider></v-divider>
                      <v-expansion-panel-content>
                        <DataTableComponent
                          :headers="headersOrden"
                          class="mt-4"
                          :items="[orden]"
                          :tiene_paginacion="false"
                        >
                          <template v-slot:item.fecha_creacion="{ item }">
                            {{
                              item.created_at
                                ? moment(item.created_at).format("DD/MM/YYYY")
                                : "Sin fecha de creación"
                            }}
                          </template>
                          <template v-slot:item.proveedor="{ item }">
                            {{ item.proveedor.nombre_comercial }}
                          </template>
                          <template v-slot:item.estado_orden="{ item }">
                            <v-chip
                              v-if="item.estado"
                              label
                              dark
                              class="mt-1"
                              :color="item.estado.color"
                            >
                              {{ item.estado.nombre || "" }}
                            </v-chip>
                          </template>
                          <template v-slot:item.total="{ item }">
                            ${{ item.total }}
                          </template>
                          <template v-slot:item.total_aceptado="{ item }">
                            ${{ item.total_aceptado }}
                          </template>
                        </DataTableComponent>

                        <v-expansion-panels multiple accordion class="mt-4">
                          <v-expansion-panel>
                            <v-expansion-panel-header
                              class="text-h7 font-weight-bold"
                            >
                              <div>
                                <v-icon class="mr-2" style="font-size: 25px"
                                  >mdi-shopping</v-icon
                                >
                                <span>Productos de la orden de compra</span>
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-divider></v-divider>
                              <DataTableComponent
                                :headers="headersProductos"
                                class="mt-4"
                                :items="orden.productos"
                                :tiene_paginacion="false"
                              >
                                <template v-slot:item.producto="{ item }">
                                  {{ item.cmopc.CMProducto.nombre }}
                                </template>
                                <template v-slot:item.sku="{ item }">
                                  {{ item.cmopc.CMProducto.cmsku.sku }}
                                </template>
                                <template v-slot:item.estado="{ item }">
                                  {{ item.cmbps[0].sec_bit.es_sec.nombre }}
                                </template>
                                <template v-slot:item.total="{ item }">
                                  ${{ item.subtotal }}
                                </template>
                                <template v-slot:item.precio_oferta="{ item }">
                                  ${{ item.precio_de_oferta }}
                                </template>
                                <template v-slot:item.acciones="{ item }">
                                  <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        large
                                        color="primary"
                                        @click="verDetalle(item)"
                                      >
                                        mdi-eye
                                      </v-icon>
                                    </template>
                                    <span> Ver detalle </span>
                                  </v-tooltip>
                                </template>
                              </DataTableComponent>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </template>

                  <EmptyComponent v-else empty colored style="min-width: 100%">
                    <template v-slot:empty-content>
                      <v-icon x-large>mdi-note-alert-outline</v-icon>
                      <p class="mb-0 mt-2 font-weight-bold text-body-1">
                        No se encuentran órdenes de compra.
                      </p>
                    </template>
                  </EmptyComponent>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <PaginationComponent
      class="mt-4"
      :total="catalogo.pagination.total_rows"
      :pagination="pagination"
    />
  </v-container>
  <EmptyComponent v-else empty colored style="min-width: 100%">
    <template v-slot:empty-content>
      <v-icon x-large>mdi-receipt-text-remove-outline</v-icon>
      <p class="mb-0 mt-2 font-weight-bold text-body-1">
        No se han enviado órdenes de compra a ningún proveedor.
      </p>
    </template>
  </EmptyComponent>
</template>

<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import EmptyComponent from "@/components/utils/EmptyComponent.vue";
import PaginationComponent from "@/components/utils/PaginationComponent.vue";
import { PaginationHandler } from "@/utils/pagination-handler.js";

import {
  createPageable,
  setPageableResponse,
  togglePageable,
} from "@/utils/loadable";

export default {
  components: { DataTableComponent, EmptyComponent, PaginationComponent },
  data: () => ({
    catalogo: createPageable([], 5),
    pagination: new PaginationHandler(5, 1),
    headers: [
      { align: "center", sortable: false, text: "Tipo solicitud", value: "tipo_solicitud" },
      { align: "center", sortable: false, text: "Elaborada por", value: "elaborada_por" },
      { align: "center", sortable: false, text: "Autorizacion", value: "autorizada" },
      { align: "center", sortable: false, text: "Fecha autorizacion", value: "fecha_autorizacion" },
      { align: "center", sortable: false, text: "Fecha creación", value: "fecha_creacion" },
      { align: "center", sortable: false, text: "Total",  value: "total" },
    ],
    headersOrden:[
      { align: "center", sortable: false, text: "Fecha creación",value: "fecha_creacion" },
      { align: "center", sortable: false, text: "Proveedor",  value: "proveedor" },
      { align: "center", sortable: false, text: "Estado", value: "estado_orden" },
      { align: "center", sortable: false, text: "Total Inicial", value: "total" },
      { align: "center", sortable: false, text: "Total aceptado",  value: "total_aceptado" },
    ],
    headersProductos:[
      { align: "center", sortable: false, text: "Producto",value: "producto" },
      { align: "center", sortable: false, text: "SKU",value: "sku" },
      { align: "center", sortable: false, text: "Estado",  value: "estado" },
      { align: "center", sortable: false, text: "Precio", value: "precio_oferta" },
      { align: "center", sortable: false, text: "Cantidad", value: "cantidad" },
      { align: "center", sortable: false, text: "Total", value: "total" },
      { align: "center", sortable: false, text: "Acciones", value: "acciones" },
    ],
  }),

  computed: {
    catalogoInformacion() {
      return this.catalogo.data.map((catalogo) => catalogo);
    },
  },
  methods: {
    async obtenerInformacionCatalogo() {
      togglePageable(this.catalogo);
      const filters = {
        pagination: true,
        ...this.pagination.toApiFormat(),
      };
      const { data, headers } = await this.services.Product.auditoriaCatalogo(
        this.$route.params.idProceso,
        filters
      );

      setPageableResponse(this.catalogo, data, headers);
    },
    verDetalle(item) {
      this.$router.push(`/cm-solicitudes-proveedor/${item.id}`);
    },
  },
  watch: {
    pagination: {
      handler() {
        this.obtenerInformacionCatalogo();
      },
      deep: true,
    },
  },
  created() {
    this.obtenerInformacionCatalogo();
  },
};
</script>

<template>
  <section>
    <!-- Form para cargar el acta -->
    <h5 class="text-h5 secondary--text">Informe de evaluación</h5>
    <v-form 
      ref="form"
      lazy-validation
      class="mt-8"
      >
      <!-- ACTA DE EVALUACIÓN DE OFERTA TÉCNICA -->
      <v-row v-if="(!actaActual)">
        <v-col cols="8" md="4" sm="8">
          <v-file-input
            v-model="documentoActa"
            accept="application/pdf"
            prepend-icon=""
            prepend-inner-icon="mdi-paperclip"
            label="Acta de panel de evaluación*"
            outlined
            :error-messages="errorArchivo"
            @blur="$v.documentoActa.$touch"
            >
          </v-file-input>
        </v-col>
        <v-col cols="4" md="8" sm="4" class="d-flex mt-2">
          <v-btn
            color="secondary"
            v-if="documentoActa"
            @click="guardarActa"
            >
            <v-icon color="white">mdi-content-save</v-icon> Guardar
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="actaActual" class="mb-8">
        <v-col class="d-flex">
          <v-btn
            color="secondary"
            @click="descargarActa"
            >
            Descargar acta actual 
          </v-btn>
          
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="secondary"
                @click="abrirModalEdit"
                class="ml-4"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar Acta</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-form>


    <v-dialog v-model="editActa" persistent max-width="700" min-height="700">
    <v-card class="pa-4" max-width="700">
      <v-card-title justify="center">
        <v-row justify="center">
          <v-icon> mdi-file-edit-outline </v-icon>
          <div><v-col>Editar acta de recomendación PEO </v-col></div>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col class="mt-10" cols="12">
            <v-file-input
              v-model="documentoActa"
              accept="application/pdf"
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              label="Subir un nuevo documento*"
              outlined
              :error-messages="errorArchivo"
              @blur="$v.documentoActa.$touch"
              >
            </v-file-input>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-btn
          @click="closeModal"
          class="primary mx-4"
          >
          Cancelar
        </v-btn>
        <v-btn
          @click="guardarActa"
          color="secondary mx-4"
          >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-divider class="my-4 mb-8" />
  </section>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import LegacyValidations from "@/utils/legacy-validations";

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}
function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "ActaEvaluacionComponent",

  props: {
    proceso: {
      type: Object,
      default: () => {
      },
    },
  },

  data() {
    return {
      // datos de muestra para la tabla
      documentoActa: null,
      etapaActual: {},
      actaActual: null,
      editActa: false,
    };
  },
  validations: {
    documentoActa: {
      required,
      fileTypeValid,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },
  methods: {
    async guardarActa() {
      this.$v.$touch();
      if (!this.$v.$invalid) {

        const formData = new FormData();
        formData.append("documento", this.documentoActa);
        formData.append("id_tipo_documento", 284); // Nuevo tipo de documento para manejar estos casos de cierre de contratos

        const response = await this.services.PeoServices.cargarActaPanel(
          this.proceso.id,
          formData
        );

        if (response.status === 201 || response.status === 200) {
          this.consultarActaActual();
          this.temporalAlert({
            show: true,
            type: "success",
            message: response.data.message,
          });
          this.editActa = false;
        }
      }
    },
    async consultarActaActual() {
      const response = await this.services.PeoServices.descargarActaPanel(
        this.proceso.id,
        { id_tipo_documento: 284 },
      );

      if (response.status == 200) this.actaActual = response.data;
    },
    async descargarActa() {

      const response = await this.services.PacProcesos.descargarDocumentoProceso({ ruta: this.actaActual.ruta_documento });

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], this.actaActual.nombre_documento, {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },

    closeModal() {
      this.consultarActaActual();
      this.editActa = false;
      this.documentoActa = null;
    },
    abrirModalEdit() {
      this.documentoActa = null;
      this.editActa = true;
    },
  },
  computed: {
    errorArchivo() {
      const errors = [];
      if (!this.$v.documentoActa.$dirty) return errors;
      !this.$v.documentoActa.required && errors.push("El archivo es requerido");
      !this.$v.documentoActa.fileTypeValid &&
        errors.push("El archivo debe ser PDF");
      !this.$v.documentoActa.fileNameValid &&
        errors.push("El archivo debe tener un nombre valido");
      !this.$v.documentoActa.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);
      return errors;
    },
  },
  async mounted() {
    this.consultarActaActual();
  },
};
</script>

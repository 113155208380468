<template>
  <v-expansion-panels flat>
    <v-expansion-panel class="bgMinsal">
      <v-expansion-panel-header>
        <span class="secondary--text text-h6"> {{ detailProp.etapa }} </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="12" md="9">
            <v-row no-gutters class="my-1">
              <v-col cols="12" sm="5" md="3">Usuario que registra:</v-col>
              <v-col cols="12" sm="7" md="9" class="text-wrap">
                {{ detailProp.creado_por.persona }} [{{
                  detailProp.creado_por.correo
                }}]
              </v-col>
            </v-row>
            <v-row no-gutters class="my-1">
              <v-col cols="12" sm="5" md="3">Ponderación</v-col>
              <v-col cols="12" sm="7" md="9" class="text-wrap">
                <v-rating
                  length="5"
                  readonly
                  size="20"
                  half-increments
                  :value="Number(detailProp.calificacion?.ponderacion)"
                />
              </v-col>
            </v-row>
            <v-row no-gutters class="my-1">
              <v-col cols="12" sm="5" md="3">
                Fecha probable de recepción:
              </v-col>
              <v-col cols="12" sm="7" md="9" class="text-wrap">
                {{ FormatDatePrt(detailProp.fecha_inicio) }}
              </v-col>
            </v-row>
            <v-row no-gutters class="my-1">
              <v-col cols="12" sm="5" md="3">Cantidad a pagar:</v-col>
              <v-col cols="12" sm="7" md="9" class="text-wrap">
                {{
                  Intl.NumberFormat("en-US", {
                    currency: "USD",
                    style: "currency",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(detailProp.cantidad_pagar)
                }}
              </v-col>
            </v-row>
            <v-row no-gutters class="my-1">
              <v-col cols="12" sm="5" md="3">Fecha de recepción:</v-col>
              <v-col cols="12" sm="7" md="9" class="text-wrap">
                {{ FormatDatePrt(detailProp.fecha_fin) }}
              </v-col>
            </v-row>
            <v-row no-gutters class="my-1">
              <v-col cols="12" sm="5" md="3">Forma de pago:</v-col>
              <v-col cols="12" sm="7" md="9" class="text-wrap">
                {{ CleanInfoFtn(detailProp.movimientos) }}
              </v-col>
            </v-row>
            <v-row no-gutters class="my-1">
              <v-col cols="12" sm="5" md="3">Descripción de la entrega:</v-col>
              <v-col cols="12" sm="7" md="9" class="text-wrap">
                {{ detailProp.descripcion_entrega }}
              </v-col>
            </v-row>
            <v-row no-gutters class="my-1">
              <v-col cols="12" sm="5" md="3">Observaciones:</v-col>
              <v-col cols="12" sm="7" md="9" class="text-wrap">
                {{ detailProp.descripcion?.includes("null") ? "Sin observaciones" : detailProp.descripcion }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="3">
            <div
              v-for="item in filterDocsCmp"
              :key="item.id"
              class="d-flex flex-column align-self-center"
            >
              <v-btn
                class="text-no-style mb-2"
                outlined
                color="secondary"
                @click="
                  descargarArchivoProceso({
                    ruta_documento: item.DocumentoOrden.ruta_archivo,
                  })
                "
              >
                <v-icon> mdi-download </v-icon>
                <span>
                  {{ item.DocumentoOrden.TipoDocumentoOrden.nombre }}
                </span>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" class="pa-0">
            <div v-for="item in detailProp.detalles" :key="item.id">
              <card-lote-contrato-component :lote-prop="item" />
            </div>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapActions } from "vuex";
import CardLoteContratoComponent from "./CardLoteContratoComponent.vue";

export default {
  name: "CardEtapaContratoComponent",

  components: {
    CardLoteContratoComponent,
  },

  props: {
    detailProp: {
      type: Object,
      required: true,
    },
  },

  computed: {
    filterDocsCmp() {
      if (this.detailProp.documentos.length > 0 && this.detailProp.documentos) {
        return this.detailProp.documentos.filter(
          (item) =>
            item.DocumentoOrden.TipoDocumentoOrden.id === 3 ||
            item.DocumentoOrden.TipoDocumentoOrden.id === 4 ||
            item.DocumentoOrden.TipoDocumentoOrden.id === 9
        );
      }
    },
  },

  methods: {
    ...mapActions("procesoCompraDoc", ["descargarArchivoProceso"]),

    CleanInfoFtn(data_param) {

      function formatCurrency(value){
        return Intl.NumberFormat("en-US", {
          currency: "USD",
          style: "currency",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value)
      }


      const infoOrdenada = Object.entries(data_param)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map((entry) => `${entry[0]}: ${formatCurrency(entry[1])}`)
        .join(" | ");
      return infoOrdenada;
    },
  },
};
</script>

<style>
</style>
<template>
  <section>
    <v-row v-if="haveRole('ROLE_PROVEEDOR')">
      <v-col cols="6">
        <v-textarea
          v-model="nueva_consulta"
          label="Escriba su consulta *"
          outlined
          no-resize
          rows="3"
          :error-messages="nuevaConsultaError"
          @blur="$v.nueva_consulta.$touch()"
        ></v-textarea>
      </v-col>
      <v-col>
        <v-file-input
          class="mt-5"
          append-icon="mdi-paperclip"
          outlined
          label="Adjunto (opcional)"
          placeholder="Agregue un archivo adjunto"
          prepend-icon=""
          v-model="adjunto"
          :error-messages="adjuntoError"
          accept="application/pdf"
          enctype="multipart/form-data"
          @blur="$v.adjunto.$touch()"
        />
      </v-col>
      <v-col cols="2">
        <v-btn
          :disabled="this.$v.$invalid"
          class="mt-8"
          @click="agregarConsulta"
          :loading="isLoadingAgregar"
          color="secondary"
        >
          Agregar
        </v-btn>
      </v-col>
    </v-row>
    <!-- Consultas publicadas en expansion panels -->
    <v-row>
      <v-col cols="12">
        <v-expansion-panels class="expansion-panels-consultas" multiple>
          <consulta-component
            :is-uaci="
              haveRoles([
                'ROLE_UACI',
                'ROLE_UACI_TECNICO',
                'ROLE_DIRECCION_COMPRAS',
                'ROLE_TECNICO_DIRECCION_COMPRAS',
              ])
            "
            v-for="consulta in consultasOrdenadas"
            :key="consulta.id"
            :consulta="consulta"
            :permisos="permisos"
            @reload="getConsultas()"
          />
        </v-expansion-panels>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import ConsultaComponent from "./ConsultaUcpComponent.vue";
import LegacyValidations from "@/utils/legacy-validations";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "RecepcionConsultasComponent",
  components: {
    ConsultaComponent,
  },
  props: {
    etapa: {
      type: Object,
      default: {},
    },
    permisos: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      nueva_consulta: "",
      adjunto: null,
      consultas: [],
      isLoadingAgregar: false,
    };
  },
  validations: {
    nueva_consulta: {
      required,
    },
    adjunto: {
      fileTypeValid,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },
  computed: {
    nuevaConsultaError() {
      const errors = [];
      if (!this.$v.nueva_consulta.$dirty) return errors;
      !this.$v.nueva_consulta.required &&
        errors.push("La consulta no puede estar vacía");
      return errors;
    },
    adjuntoError() {
      const errors = [];
      if (!this.$v.adjunto.$dirty) return errors;
      !this.$v.adjunto.fileTypeValid &&
        errors.push("El archivo debe ser de tipo PDF");
      !this.$v.adjunto.fileNameValid &&
        errors.push("El nombre del archivo no debe contener caracteres especiales");
      !this.$v.adjunto.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);          
      return errors;
    },
    consultasOrdenadas() {
      //  Ordenar consultas mostrando las mas recientes prime
      return this.consultas.sort((a, b) => {
        return new Date(b.fecha) - new Date(a.fecha);
      });
    },
  },
  methods: {
    ...mapActions("etapaConsultasStore", ["responderConsulta"]),
    async agregarConsulta(id_estado = null) {

      this.isLoadingAgregar = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const formData = new FormData();
      formData.append("consulta", this.nueva_consulta);
      formData.append("documento", this.adjunto);

      const response = await this.services.Paac.postConsultas(
        formData,
        this.$route.params.idProceso
      ).catch(() => {

        this.isLoadingAgregar = false;
      });
      if (response.status == 201) {
        // Reiniciar el campo de consulta
        this.nueva_consulta = "";
        // Reiniciar el campo de adjunto
        this.adjunto = null;
        // Reiniciar validaciones
        this.$v.$reset();
      }

      this.isLoadingAgregar = false;
      this.getConsultas();
    },
    async getConsultas() {
      const { status, data } =
        await this.services.PacProcesos.getConsultasProceso(
          this.$route.params.idProceso
        );

      if (status == 200) {
        this.consultas = data;
      }
    },
  },
  created() {
    this.getConsultas();
  },
};
</script>
<style lang="scss">
.expansion-panels-consultas {
  .v-expansion-panel-header {
    // Colocar borde en la parte inferior
    // border-bottom: 1px solid #e0e0e0;
    border: 1px solid red;
  }
  .v-expansion-panel {
    .v-expansion-panel-content {
      background-color: var(--v-bgMinsal-base);
    }

    .v-expansion-panel-header {
      border-bottom: 1px solid #e0e0e0 !important;
      padding-left: 10px !important;
    }
  }
  .v-expansion-panel:before {
    box-shadow: none !important;
  }
  .v-expansion-panel::before {
    box-shadow: none !important;
  }
}
</style>

<template>
  <div class="pa-4">
    <p class="text-h6">Insumos</p>
    <data-table-component
      :headers="headers"
      :items="solicitud.solicitud_compra_obs"
      mobile_breakpoint="725"
      :tiene_paginacion="false"
    >
      <template v-slot:[`item.no`]="{ item }">
        {{
          solicitud.solicitud_compra_obs.map((col) => col.id_obs).indexOf(item.id_obs) + 1
        }}
      </template>
      <template v-slot:[`item.precio_unitario`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.precio_unitario)
        }}
      </template>
      <template v-slot:[`item.estado`]="{ item }">
        <v-chip
          label
          :dark="item.estado.includes('Descartado')"
          :color="item.color"
        >
          {{ item.estado || "" }}
        </v-chip>
      </template>
    </data-table-component>
  </div>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";

export default {
  name: "InsumosComponent",
  components: {
    DataTableComponent,
  },
  props: {
    solicitud: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    headers: [
      {
        text: "No.",
        align: "center",
        value: "no",
        sortable: false,
      },
      {
        text: "OBS",
        align: "start",
        value: "obs",
      },
      {
        text: "Especificación",
        align: "start",
        value: "observacion",
      },
      {
        text: "Unidad de medida",
        value: "unidad_medida",
        sortable: false,
      },
      {
        text: "Cantidad solicitada",
        value: "solicitud_compra_detalle_obs[0].cantidad",
        sortable: false,
        align: "center",
      },
      {
        text: "Total ($)",
        value: "proceso_compra_pac.total_mercancia",
        sortable: false,
        align: "center",
      },
      {
        text: "Precio unitario ($)",
        value: "precio_unitario",
        sortable: false,
        align: "center",
      },
      {
        text: "Estado",
        value: "estado",
        sortable: false,
        align: "center",
      },
    ],
  }),
};
</script>
import Vue from 'vue';

export const calcularMonto = ({monto, porcentaje, tipo, cantidad}) => {
    const porcentajeCalculado = (monto * porcentaje ?? 0) / 100;
    let totalSubasta = 0;
    switch (tipo) {
        case 'resta_porcentaje':
            totalSubasta = +monto + +porcentajeCalculado;
            break;
        case 'suma_porcentaje':
            totalSubasta = +monto - +porcentajeCalculado;
            break;
        case 'resta_porcentaje_publicidad':
            totalSubasta = +monto;
            break;
        case 'resta_unitaria':
            totalSubasta = +monto * +cantidad;
            break;
        default:
            totalSubasta = +monto;
            break;
    }
    return totalSubasta;
}
<template>
  <Fragment>
    <v-card v-if="list_last_ofertas.length > 0">
      <v-card-title class="d-flex align-center mb-6">
        <v-icon size="28" color="subastaBlue"> mdi-list-box-outline </v-icon>
        <span class="subastaBlue--text text-h6 mx-2"> Historial </span>
      </v-card-title>
      <v-card-text class="scrollable marcado">
        <v-list class="listado" dense>
          <template v-for="(item, index) in list_last_ofertas">
            <v-list-item dense :class="userInfo?.user?.id === item.id_usuario_proveedor ? 'self' : 'other'">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.proveedor }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ moment(item.fecha_hora).format("HH:mm:ss:SSSS A") }}
                </v-list-item-subtitle>
                <template v-if="!porcentual">{{
                  Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(item.monto)
                }}
                </template>
                <template v-else> {{ item.porcentaje }}% </template>
              </v-list-item-content>
            </v-list-item>
            <br />
          </template>
        </v-list>
      </v-card-text>
      <v-card-actions
        v-if="haveRoles(['ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS', 'ROLE_UACI', 'ROLE_UACI_TECNICO', 'ROLE_AUDITORIA_PROCESO'])">
        <v-btn color="subastaBlue" text @click="exportarExcelUltimasOfertas">Descargar Excel</v-btn>
      </v-card-actions>
    </v-card>
    <v-alert type="info" outlined v-else> No hay pujas para mostrar </v-alert>
  </Fragment>
</template>
<script>
import { Listener, Observable } from "@/utils/observable";
import { Fragment } from "vue-fragment";
import { mapState } from "vuex";

export default {
  name: "HistorialPujasComponent",
  components: { Fragment },
  props: {
    id: {
      type: Number,
      required: true,
    },
    porcentual: {
      type: Boolean,
      default: false,
    },
    recarga: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    list_last_ofertas: [],
    timeoutCleaner: null,
    recargaPujasUnsubscriber: null,
    estadoCountdownUnsubscriber: null,
  }),
  computed: {
    ...mapState(["userInfo"]),
    ...mapState("etapaSubastaInversaStore", ["hayCountdownSubasta"]),
  },
  methods: {
    async obtenerUltimasOfertas(params) {
      const { data } = await this.services.Paac.getLastOferta(this.id, params);
      this.list_last_ofertas = data;
    },
    async exportarExcelUltimasOfertas() {
      const { data } = await this.services.Paac.getLastOfertaExcel(this.id);

      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const file = new File([blob], "asd", {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = `subasta_historial_${this.id}.xlsx`;
      link.click();
    },
    autoRecargarUltimasOfertas() {
      if (this.timeoutCleaner) {
        clearTimeout(this.timeoutCleaner);
        this.timeoutCleaner = null;
      }

      if (!this.id || !this.recarga) return;

      this.timeoutCleaner = setTimeout(() => {
        this.obtenerUltimasOfertas({ 'es-recarga-automatica': true });
        Observable.emit('REFRESCAR-HORA-SERVIDOR');
        this.autoRecargarUltimasOfertas();
      }, 1000 * 10);
    },
    manejarCambioVisibilidadCountdown(visible) {
      if (visible) {
        this.autoRecargarUltimasOfertas();
        return;
      }

      if (this.timeoutCleaner) {
        clearTimeout(this.timeoutCleaner);
        this.timeoutCleaner = null;
      };
    },
  },
  watch: {
    id: {
      handler: function (val) {
        if (val) {
          this.obtenerUltimasOfertas();
          this.autoRecargarUltimasOfertas();
        }
      },
      immediate: true,
    },
  },
  created() {
    // Eventos para recargar pujas
    const recargaPujasListener = new Listener('HistorialPujasListener').setHandler(() => this.obtenerUltimasOfertas());
    const recargaPujasUnsubscriber = Observable.getInstance().subscribe('RECARGAR_HISTORIAL_PUJAS', recargaPujasListener);
    this.recargaPujasUnsubscriber = recargaPujasUnsubscriber;

    // Eventos para estado de countdown
    const estadoCountdownListener = new Listener('EstadoCountdown').setHandler((evt, visible) => this.manejarCambioVisibilidadCountdown(visible));
    const estadoCountdownUnsubscriber = Observable.getInstance().subscribe('CAMBIO-VISIBILIDAD-COUNTDOWN-SUBASTA', estadoCountdownListener);
    this.estadoCountdownUnsubscriber = estadoCountdownUnsubscriber;
  },
  beforeDestroy() {
    this.recargaPujasUnsubscriber?.();
    this.estadoCountdownUnsubscriber?.();

    if (this.timeoutCleaner) clearTimeout(this.timeoutCleaner);
  },
};
</script>
<style>
.marcado {
  max-height: 48vh;
  overflow-y: auto;
}

.self {
  background-color: #4f96cd;
}
</style>

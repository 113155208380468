<template>
  <!-- <section v-if="!estadoEtapa"> -->
    <v-row v-if="!recursoRevisionActual || editRecursoRevision">
      <v-col cols="8" md="4" sm="8">
        <v-file-input
          v-model="recursoRevision"
          accept="application/pdf"
          prepend-icon=""
          prepend-inner-icon="mdi-paperclip"
          label="Recurso de revisión *"
          outlined
          :error-messages="errorArchivo"
          @blur="$v.recursoRevision.$touch"
        ></v-file-input>
      </v-col>
      <v-col cols="4" md="8" sm="4" class="d-flex mt-2">
        <v-btn color="secondary" @click="guardarRecursoRevision">
          <v-icon color="white">mdi-content-save</v-icon> Guardar
        </v-btn>
        <v-btn
          solid
          color="secondary"
          @click="editRecursoRevision = false"
          class="ml-4"
          v-if="editRecursoRevision"
        >
          <v-icon color="white">mdi-close</v-icon> Cancelar edición
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mb-8" v-else-if="recursoRevisionActual">
      <v-col class="d-flex">
        <v-card @click="descargarRecursoRevision">
          <v-card-text>
            <p class="text-h6 secondary--text">Recurso de revisión</p>
            <v-btn text color="secondary" class="pl-0">
              <v-icon color="secondary" size="40">mdi-file-pdf-box</v-icon>
              Descargar recurso de revisión
            </v-btn>
            <v-btn
              icon
              color="secondary"
              @click="editRecursoRevision = true"
              class="ml-4"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  <!-- </section> -->
</template>

<script>
  import LegacyValidations from "@/utils/legacy-validations";
  import { required } from "vuelidate/lib/validators";
  import { mapState } from "vuex";

  function fileTypeValid(value) {
    if (!value) {
      return true;
    }
    let extension = /(\.pdf)$/i;
    let file = value;
    return extension.test(file.name);
  }
  function fileNameValid(value) {
    if (!value) {
      return true;
    }
    let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
    let file = value;
    return regAlfaNumPdf.test(file.name);
  }

  export default {
    name: "RecursoRevisionComponent",
    data: () => ({
      estadoEtapa: null,
      esNotificacionEconomica: false,

      recursoRevisionActual: null,
      editRecursoRevision: false,
      recursoRevision: null,
    }),
    props: {
      etapa: {
        type: Object,
        default: {},
      },
      etapas: {
        type: Array,
        default: () => [],
      },
      permisos: {
        type: Object,
        default: {},
      },
    },
    validations: {
      recursoRevision: {
        required,
        fileTypeValid,
        fileNameValid,
        fileSizeValid: LegacyValidations.maxFileSizeRule(),
      },
    },
    computed: {
      ...mapState("procesoCompraDoc", ["procesoData", "participando"]),
      errorArchivo() {
        const errors = [];
        if (!this.$v.recursoRevision.$dirty) return errors;
        !this.$v.recursoRevision.required &&
          errors.push("El archivo es requerido");
        !this.$v.recursoRevision.fileTypeValid &&
          errors.push("El archivo debe ser PDF");
        !this.$v.recursoRevision.fileNameValid &&
          errors.push("El archivo debe tener un nombre valido");
        !this.$v.recursoRevision.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);          
        return errors;
      },
    },
    methods: {
      async getEstadoNotificacion() {
        // let response = null;
        // response = await this.services.PacProcesos.estadoEtapaProceso(
        //   this.procesoData?.id,
        //   2
        // );

        // if (response.data[0]?.economica !== undefined) {
        //   this.estadoEtapa = response.data[0].economica;
        //   if (!this.estadoEtapa) this.obtenerRecursoRevision();
        // }
        this.obtenerRecursoRevision();
      },
      async guardarRecursoRevision() {
        this.$v.$touch();
        if (!this.$v.$invalid) {

          let formData = new FormData();
          formData.append("doc_impugnacion", this.recursoRevision);
          formData.append("id_tipo_impugnacion", 1);
          formData.append("id_etapa_proceso", this.etapa.id);

          const response =
            await this.services.RecursoRevision.storeRecursoProveedor(
              this.procesoData.id,
              formData
            );

          if (response.status === 201) {
            this.temporalAlert({
              show: true,
              type: "success",
              message: "Documento cargado correctamente",
            });
            this.recursoRevision = null;
            this.obtenerRecursoRevision();
            this.editRecursoRevision = false;
            this.$v.$reset();
          }

        }
      },
      async obtenerRecursoRevision() {

        const response =
          await this.services.RecursoRevision.getRecursosProveedorPorTipo(
            this.procesoData.id,
            1,
            { pagination: false, id_etapa_proceso: this.etapa.id }
          );
        if (response.status === 200) {
          this.recursoRevisionActual = response.data[response.data.length - 1];
        }

      },
      async descargarRecursoRevision() {

        const response =
          await this.services.PacProcesos.descargarDocumentoProceso({
            ruta: this.recursoRevisionActual.doc_impugnacion,
          });

        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const file = new File([blob], this.recursoRevisionActual.nombre_documento, {
          type: response.headers["content-type"],
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = "";
        link.click();

      },
    },
    async created() {
      await this.getEstadoNotificacion();
    },
  };
</script>

<template>
  <section>
    <v-row align="center">
      <v-col cols="12" sm="12" md="6">
        <p class="text-h5 secondary--text font-weight-bold">
          {{ etapa?.CtlEtapasProcesoImpugnacion?.nombre_etapa }}
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-row align="center">
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de Inicio:
              {{ moment(etapa?.fecha_inicio).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de Fin:
              {{ moment(etapa?.fecha_fin).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <embed
          width="100%"
          height="700"
          :src="doc + '#page=1%zoom=100'"
          v-if="doc"
        />
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "ResolucionRecursoApelacionComponent",
  data: () => ({}),
  props: {
    etapa: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    recursoApelacion: null,
    doc: null,
  }),
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
  },
  methods: {
    ...mapActions("procesoCompraDoc", [
      "getDocumentosProcesoPorTipo",
      "descargarBufferDocumento",
    ]),
    async getRecursoApelacion() {
      const response = await this.getDocumentosProcesoPorTipo({
        id_proceso_compra: this.$route.params.idProceso,
        id_tipo_documento: 15006,
        id_etapa_proceso: this.procesoData.id_etapa_pausa,
      });

      this.recursoApelacion = response[0];
      if (this.recursoApelacion) this.showDocument();
    },

    async showDocument() {
      const response = await this.descargarBufferDocumento({
        ruta_documento: this.recursoApelacion.ruta_documento,
      });
      this.getDoc(response);
    },
    getDoc(doc) {
      let b64 = Buffer.from(doc).toString("base64");
      this.doc = `data:application/pdf;base64,${b64}`;
    },
  },
  created() {
    this.getRecursoApelacion();
  },
};
</script>

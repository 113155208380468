<template>
  <section>
    <v-row>
      <v-col cols="12">
        <span class="secondary--text text-h6">
          Sub proceso de impugnaciones -
          {{ FormatDatePrt(subprocesoProp.fecha_hora_inicio, true) }}
        </span>
      </v-col>
      <v-col cols="12">
        <v-expansion-panels class="my-3">
          <v-expansion-panel>
            <v-expansion-panel-header> Etapas </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-for="item in subprocesoProp.etapas" :key="item.id">
                <card-etapa-component :etapa-prop="item" />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-0 pr-md-3 mb-6 mb-md-0">
            <card-resolucion-component
              :resolucion-prop="subprocesoProp.resolucion_recurso_revision"
              title-prop="revisión"
            />
          </v-col>
          <v-col cols="12" md="6" class="pl-0 pl-md-3 mt-6 mt-md-0">
            <card-resolucion-component
              :resolucion-prop="subprocesoProp.resolucion_recurso_apelacion"
              title-prop="apelación"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <div v-for="item in subprocesoProp.participantes" :key="item.id">
          <card-proveedor-component :proveedor-prop="item" v-if="item.recursos.length > 0"/>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import CardResolucionComponent from "./CardResolucionIMPComponent.vue";
import CardProveedorComponent from "./CardProveedorIMPComponent.vue";
import CardEtapaComponent from "./CardEtapaIMPComponent.vue";

export default {
  name: "ContainerSubProcesoIMPComponent",

  components: {
    CardResolucionComponent,
    CardProveedorComponent,
    CardEtapaComponent,
  },

  props: {
    subprocesoProp: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>
<template>
  <section>
    <data-table-component
      class="mb-3"
      :headers="HEADERS_CONST"
      :items="dataProp"
      inhabilitar_paginacion
      :tiene_paginacion="false"
    >
      <template v-slot:[`item.created_at`]="{ item }">
        {{ FormatDatePrt(item.created_at) }}
      </template>
      <template v-slot:[`item.estado`]="{ item }">
        <v-chip>
          {{ item.estado }}
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                descargarArchivoProceso({
                  ruta_documento: item.documento,
                })
              "
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Descargar</span>
        </v-tooltip>
      </template>
    </data-table-component>
  </section>
</template>

<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import { mapActions } from "vuex";

export default {
  name: "TabSancionesComponent",

  components: {
    DataTableComponent,
  },

  props: {
    dataProp: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    // constants
    HEADERS_CONST: [
      {
        text: "Código",
        value: "codigo",
      },
      {
        text: "Usuario que reporta",
        value: "empleado",
      },
      {
        text: "Fecha de creación",
        value: "created_at",
      },
      {
        text: "Estado de reporte",
        value: "estado",
      },
      {
        text: "Motivo de reporte",
        value: "motivo",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
      },
    ],
  }),

  methods: {
    ...mapActions("procesoCompraDoc", ["descargarArchivoProceso"]),
  },
};
</script>

<style>
</style>
<template>
  <section>
    <div v-for="item in list_subproceso_var" :key="item.id">
      <container-sub-proceso-component :subproceso-prop="item" />
    </div>
  </section>
</template>

<script>
import ContainerSubProcesoComponent from "./ContainerSubProcesoIMPComponent.vue";
export default {
  name: "ContainerImpugnacionComponent",

  components: {
    ContainerSubProcesoComponent,
  },

  data: () => ({
    list_subproceso_var: false,
  }),

  methods: {
    async FetchSubprocesoFtn() {

      const { data, status } =
        await this.services.PacProcesos.getAuditImpugnacion(
          this.$route.params.idProceso
        );


      if (status === 200) {
        this.list_subproceso_var = data;
      }
    },
  },

  created() {
    this.FetchSubprocesoFtn();
  },
};
</script>

<style>
</style>
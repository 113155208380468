<template>
  <section>
    <v-row align="center">
      <v-col cols="12" sm="12" md="6">
        <p class="text-h5 secondary--text font-weight-bold">
          Recepción de recursos de revisión
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-row align="center">
          <v-col cols="6">
            <p class="secondary--text">Fecha de Inicio:</p>
          </v-col>
          <v-col cols="6">
            <p class="secondary--text">Fecha de Fin:</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p>Recursos de revisión presentados</p>
        <DataTable
          :headers="headers"
          :items="items"
          v-models:select="por_pagina"
          v-models:pagina="pagina"
          :total_registros="total_registros"
          @paginar="paginar"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="secondary" @click="showDocument(item)">
              mdi-eye
            </v-icon>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment("DD/MM/YYYY") }}
          </template>
        </DataTable>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" max-width="800px">
      <v-card class="pb-2">
        <embed
          width="100%"
          height="700"
          :src="doc + '#page=1%zoom=100'"
          v-if="doc"
        />
        <v-card-actions>
          <v-btn @click.stop="showDialog = false" color="secondary" outlined>
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import DataTable from "@/components/DataTableComponent.vue";
import moment from "moment";
import { mapActions } from "vuex";
export default {
  name: "RecepcionRecursosComponent",
  components: {
    DataTable,
  },
  props: {},
  data: () => ({
    headers: [
      {
        text: "Código",
        align: "center",
        sortable: false,
        value: "correlativo",
        width: "30%",
      },
      {
        text: "Proveedor",
        align: "center",
        value: "proveedor",
        width: "20%",
      },
      {
        text: "Fecha de presentación",
        value: "created_at",
        width: "20%",
        align: "center",
      },
      { text: "Acciones", align: "center", value: "actions", width: "20%" },
    ],
    items: [],
    pagina: 1,
    por_pagina: 5,
    total_registros: 0,
    showDialog: false,
    doc: null,
  }),
  methods: {
    ...mapActions("procesoCompraDoc", ["descargarBufferDocumento"]),
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.pagina = pagina;
      this.por_pagina = cantidad_por_pagina;
    },
    async getRecursosRevision() {


      const { status, data } =
        await this.services.RecursoRevision.getRecursosRevisionUcp(
          this.$route.params.idProceso,
          {
            id_tipo: 1,
            page: this.pagina,
            per_page: this.por_pagina,
          }
        )

      if (status == 200) {
        this.items = data;
      }

    },
    async showDocument(item) {
      const response = await this.descargarBufferDocumento({
        ruta_documento: item.doc_impugnacion,
      });
      this.getDoc(response);
      this.showDialog = true;
    },
    getDoc(doc) {
      let b64 = Buffer.from(doc).toString("base64");
      this.doc = `data:application/pdf;base64,${b64}`;
    },
  },
  created() {
    this.getRecursosRevision();
  },
};
</script>

<template>
  <section>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <h2 class="secondary--text mb-2">
          {{ etapa?.CtlEtapasProcesoImpugnacion?.nombre_etapa }}
        </h2>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-row align="center">
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de inicio:
              {{ moment(etapa?.fecha_inicio).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de fin:
              {{ moment(etapa?.fecha_fin).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <h5 class="mt-2 mb-3">Recursos de apelación presentados</h5>
    <data-table-component
      :headers="headers"
      :items="apelaciones"
      v-models:select="perPage"
      v-models:pagina="page"
      :total_registros="totalRows"
      @paginar="paginar"
    >
      <template #[`item.estado`]="{ item }">
        <v-chip
          label
          :color="
            item.EstadoImpugnacion.id === 6
              ? 'success'
              : item.EstadoImpugnacion.id === 7
              ? 'error'
              : 'grey lighten-1'
          "
          dark
          >{{
            item.estado 
          }}</v-chip
        >
      </template>
      <template #[`item.created_at`]="{ item }">
        {{ moment(item.created_at).format("DD/MM/YYYY") }}
      </template>
      <template #[`item.acciones`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" @click="abrirModal(item), (doc_revision_selected = item)"
              >mdi-eye</v-icon
            >
          </template>
          <span>Verificar recurso</span>
        </v-tooltip>
      </template>
    </data-table-component>
    <v-dialog v-model="showDialog" max-width="800px">
      <v-card class="pb-2">
        <p class="text-h6 ma-2">{{ proveedor.nombre_comercial }}</p>
        <embed
          width="100%"
          height="700"
          :src="doc + '#page=1%zoom=100'"
          v-if="doc"
        />
        <v-card-actions>
          <v-btn @click.stop="showDialog = false" color="secondary" outlined>
            Cerrar
          </v-btn>
          <v-btn
            v-if="![6,7].includes(id_estado)"
            @click.stop="showDialog = false"
            color="red"
            outlined
            @click="modalRechazarR()"
          >
            Rechazar
          </v-btn>
          <v-btn
            v-if="![6,7].includes(id_estado)"
            @click.stop="showDialog = false"
            color="success"
            dark
            @click="modalAprob()"
          >
            Admitir recurso
          </v-btn>

          <v-btn
            class="ml-auto"
            @click.stop="descargarArchivo()"
            color="info"
            outlined
            dark
          >
            <v-icon>mdi-download</v-icon> Descargar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="aprobarRe" max-width="500px">
      <v-card>
        <p class="text-h6 secondary--text ma-2">Admitir recurso de apelación</p>
        <v-card-text>
          <p>
            Para poder realizar esta acción debe cargar antes el acta firmada
            por la autoridad aceptando la admisión del recurso
          </p>
          <v-file-input
            outlined
            label="Acta de admisión"
            v-model="documentoAprop"
            prepend-icon=""
            accept="application/pdf"
            prepend-inner-icon="mdi-paperclip"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cerrar" outlined color="secondary">Volver</v-btn>
          <v-btn color="success" dark @click="admitir">Admitir recurso</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="rechazarRe" max-width="500px">
      <v-card>
        <p class="text-h6 secondary--text ma-2">
          Rechazar recurso de apelación
        </p>
        <v-card-text>
          <p>
            Para poder realizar esta acción debe cargar antes el acta firmada
            por la autoridad rechazando la admisión del recurso
          </p>
          <v-file-input
            outlined
            label="Acta de rechazo"
            v-model="documentoRech"
            prepend-icon=""
            prepend-inner-icon="mdi-paperclip"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cerrar" outlined color="secondary">Volver</v-btn>
          <v-btn color="error" dark @click="rechazar">Rechazar recurso</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import moment from "moment";
import DataTableComponent from "../../../../../components/DataTableComponent.vue";
import { mapActions, mapState } from "vuex";
export default {
  components: { DataTableComponent },
  name: "AdmisionRecursoApelacionTacop",
  props: {
    etapa: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    headers: [
      {
        text: "Código",
        value: "correlativo",
      },
      {
        text: "Proveedor",
        value: "proveedor",
      },
      {
        text: "Fecha creación",
        value: "created_at",
      },
      {
        text: "Estado",
        value: "estado",
        align: "center",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
      },
    ],
    page: 1,
    perPage: 10,
    totalRows: 0,
    apelaciones: [],
    id_tipo: 2,
    showDialog: false,
    doc: null,
    proveedor: {},
    rechazarRe: false,
    aprobarRe: false,
    documentoRech: null,
    documentoAprop: null,
    id_impugnacion: null,
    id_estado:null,
    doc_revision_selected: null,
  }),
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
  },
  methods: {
    ...mapActions("procesoCompraDoc", ["descargarBufferDocumento", "descargarArchivoProceso"]),
    async getRecursoTacop() {

      const { status, data, headers } =
        await this.services.RecursoRevision.getRecursosRevisionUcp(
          Number(this.$route.params.idProceso),
          {
            id_tipo: this.id_tipo,
            page: this.page,
            per_page: this.perPage,
            id_etapa_proceso: this.procesoData.id_etapa_pausa,
          }
        )
      if (status === 200) {
        this.apelaciones = data.map((item) => {
          return {
            ...item,
            correlativo: item.correlativo,
            proveedor: item.Usuario.Proveedor.nombre_comercial,
            created_at: item.created_at,
            estado: item.EstadoImpugnacion.nombre,
          };
        });
        this.page = Number(headers.page);
        this.perPage = Number(headers.per_page);
        this.totalRows = Number(headers.total_rows);
      }

    },
    paginar(filtros) {
      const { pagina, cantidad_por_pagina } = filtros;
      this.page = pagina;
      this.perPage = cantidad_por_pagina;
      this.getRecursoTacop();
    },
    async abrirModal(item) {
      const response = await this.descargarBufferDocumento({
        ruta_documento: item.doc_impugnacion,
      });
      this.getDoc(response);
      this.showDialog = true;
      this.proveedor = item.Usuario.Proveedor;
      this.id_impugnacion = item.id;
      this.id_estado = item.id_estado_impugnacion
    },
    getDoc(doc) {
      let b64 = Buffer.from(doc).toString("base64");
      this.doc = `data:application/pdf;base64,${b64}`;
    },
    modalRechazarR() {
      this.rechazarRe = true;
    },
    modalAprob() {
      this.aprobarRe = true;
    },
    cerrar() {
      this.showDialog = false;
      this.documentoAprop = null;
      this.aprobarRe = false;
      this.rechazarRe = false;
    },
    async admitir() {

      const formData = new FormData();
      formData.append("id_estado_impugnacion", 6);
      if (this.documentoAprop) {
        formData.append("doc_resolucion", this.documentoAprop);
      }
      const response = await this.services.RecursoRevision.putEstadoImpugnacion(
        Number(this.$route.params.idProceso),
        this.id_impugnacion,
        formData
      ).catch(() => {

      });
      if (response.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Recurso de apelación admitido exitosamente",
        });
        this.getRecursoTacop();
        this.cerrar();

      }
    },
    async rechazar() {

      const formData = new FormData();
      formData.append("id_estado_impugnacion", 7);
      if (this.documentoRech) {
        formData.append("doc_resolucion", this.documentoRech);
      }
      const response = await this.services.RecursoRevision.putEstadoImpugnacion(
        Number(this.$route.params.idProceso),
        this.id_impugnacion,
        formData
      ).catch(() => {

      });
      if (response.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Recurso de apelación rechazado exitosamente",
        });
        this.getRecursoTacop();
        this.cerrar();
      }

    },

    async descargarArchivo() {
      this.descargarArchivoProceso({ ruta_documento: this.doc_revision_selected.doc_impugnacion });
    },
  },
  created() {
    this.getRecursoTacop();
  },
};
</script>

<style></style>

<template>
  <section>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <h2 class="secondary--text mb-2">
          {{ etapa?.CtlEtapasProcesoImpugnacion?.nombre_etapa }}
        </h2>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-row align="center">
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de inicio:
              {{ moment(etapa?.fecha_inicio).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de fin:
              {{ moment(etapa?.fecha_fin).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="6">
        <p>Recursos de apelación admitidos</p>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="apelacionesAdmitidos"
        >
          <template #[`item.proveedor`]="{ item }">
            {{ item.Usuario?.Proveedor?.nombre_comercial }}
          </template>
          <template #[`item.created_at`]="{ item }">
            {{ moment(item.created_at).format("DD/MM/YYYY") }}
          </template>
          <template #[`item.fecha_admision_tacop`]="{ item }">
            {{ moment(item.fecha_admision_tacop).format("DD/MM/YYYY") }}
          </template>
          <template #[`item.acciones`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" @click="showDocument(item)"
                  >mdi-eye</v-icon
                >
              </template>
              <span>Ver recurso</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <p>Pronunciamiento de partes</p>
        <v-data-table
          :headers="headersPronun"
          :items="pronunciamientos"
          hide-default-footer
        >
          <template #[`item.fecha_carga`]="{ item }">
            {{ moment(item.fecha_carga).format("DD/MM/YYYY") }}
          </template>
          <template #[`item.proveedor`]="{ item }">
            {{ item.Usuario.Proveedor.nombre_comercial }}
          </template>
          <template #[`item.acciones`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  @click="descargar(item.ruta_documento)"
                  >mdi-download</v-icon
                >
              </template>
              <span>Descargar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="6">
        <h4 class="secondary--text my-2">
          Proveedores adjudicados después de resolución
        </h4>
        <h5 class="mb-2">Resolución final</h5>
        <v-row>
          <v-col cols="12" sm="12" md="4">
            <v-text-field
             outlined
             label="Nombre del documento"
             v-model="nombreResolucion"
             @blur="$v.nombreResolucion.$touch()"
             :error-messages="nombreResolucionError"
             @input="$v.nombreResolucion.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="5">
            <v-file-input
              label="Resolución final"
              outlined
              v-model="resolucion"
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              @blur="$v.resolucion.$touch()"
              :error-messages="documentoResolucionError"
              accept="application/pdf"
            ></v-file-input>
          </v-col>
          <v-col cols="8" sm="8" md="3">
            <v-btn color="secondary" dark @click="cargarResolucion">Cargar</v-btn>
          </v-col>
        </v-row>
        <v-btn color="secondary" dark v-if="resolucionFinal != null" @click="verResolucion">Ver resolución final</v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <h5 class="mb-3">Actos de trámite</h5>
        <v-row>
          <v-col cols="12" sm="12" md="9">
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  outlined
                  label="Nombre del archivo"
                  v-model="nombre"
                  @blur="$v.nombre.$touch()"
                  @input="$v.nombre.$touch()"
                  :error-messages="nombreTramiteError"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-file-input
                  label="Archivo adjunto"
                  outlined
                  v-model="tramite"
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  accept="application/pdf"
                  @blur="$v.tramite.$touch()"
                  :error-messages="documentoTramiteError"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <v-btn color="secondary" dark @click="cargar" class="mb-5">Agregar</v-btn>
          </v-col>
        </v-row>
        <v-data-table
          hide-default-footer
          :headers="headersTramites"
          :items="tramites"
        >
          <template #[`item.fecha_carga`]="{ item }">
            {{ moment(item.fecha_carga).format("DD/MM/YYYY") }}
          </template>
          <template #[`item.acciones`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  @click="descargar(item.ruta_documento)"
                  >mdi-download</v-icon
                >
              </template>
              <span>Descargar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row class="ma-2">
      <v-btn outlined color="secondary">Volver</v-btn>
    </v-row>
    <v-dialog v-model="modalApel" max-width="900px">
      <v-card>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <p class="text-h6 mt-3 mx-3 mb-0">
              {{ proveedor.nombre_comercial }}
            </p>
            <v-chip color="success" label class="mb-5 mx-3">{{
              apelacion.estado
            }}</v-chip>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-btn text color="secondary" class="mt-3"
              >Recurso de apelación<v-icon color="secondary" @click="descargarApelacion(apelacion.doc_resolucion_tacop)"
                >mdi-download</v-icon
              ></v-btn
            >
          </v-col>
        </v-row>
        <embed
          width="100%"
          height="700"
          :src="doc + '#page=1%zoom=100'"
          v-if="doc"
        />
        <v-btn
          @click.stop="modalApel = false"
          color="secondary"
          outlined
          class="ma-2"
        >
          Cerrar
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalResolucion" max-width="900px">
      <v-card>
        <embed
          width="100%"
          height="700"
          :src="doc2 + '#page=1%zoom=100'"
          v-if="doc2"
        />
        <v-btn
          @click.stop="modalResolucion = false"
          color="secondary"
          outlined
          class="ma-2"
        >
          Cerrar
        </v-btn>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { required, helpers } from "vuelidate/lib/validators";
import LegacyValidations from "@/utils/legacy-validations";

const letter = helpers.regex("letter", /^[A-Za-zñÑáéíóúÁÉÍÓÚ -]*$/);
const letterTramite = helpers.regex("letterTramite", /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 -]*$/);
function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}
function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}
export default {
  props: {
    etapa: {
      type: Object,
      default: () => {},
    },
  },
  validations:{
    tramite:{
      fileTypeValid,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
    resolucion:{
      fileTypeValid,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
    nombreResolucion:{
      letter
    },
    nombre:{
      letterTramite
    },
  },
  data: () => ({
    headers: [
      {
        text: "Código",
        value: "correlativo",
      },
      {
        text: "Proveedor",
        value: "proveedor",
      },
      {
        text: "Fecha de presentación",
        value: "created_at",
      },
      {
        text: "Fecha de admisión",
        value: "fecha_admision_tacop",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
      },
    ],
    headersPronun: [
      {
        text: "Proveedor",
        value: "proveedor",
      },
      {
        text: "Fecha de pronunciamiento",
        value: "fecha_carga",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
      },
    ],
    headersTramites: [
      {
        text: "Documento",
        value: "nombre_documento",
      },
      {
        text: "Fecha de carga",
        value: "fecha_carga",
      },
      {
        text: "Acciones",
        value: "acciones",
      },
    ],
    resolucion: null,
    tramite: null,
    apelacionesAdmitidos: [],
    id_tipo: 2,
    resolucionFinal: null,
    pronunciamientos: [],
    tramites:[],
    doc: null,
    id_tipo_documento: null,
    modalApel: false,
    proveedor: {},
    apelacion: {},
    nombre:null,
    nombreResolucion:null,
    modalResolucion:false,
    doc2:null,
  }),
  computed:{
    ...mapState("procesoCompraDoc", ["procesoData"]),
    documentoTramiteError(){
      const errors = []
      if(!this.$v.tramite.$dirty) return errors
     
      !this.$v.tramite.fileNameValid && errors.push("No ingrese caracteres especiales ni números")
      !this.$v.tramite.fileTypeValid && errors.push("Solo se permiten archivos PDF")
      !this.$v.tramite.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);          
      return errors
    },
    documentoResolucionError(){
      const errors = []
      if(!this.$v.resolucion.$dirty) return errors
     
      !this.$v.resolucion.fileNameValid && errors.push("No ingrese caracteres especiales ni números")
      !this.$v.resolucion.fileTypeValid && errors.push("Solo se permiten archivos PDF")
      !this.$v.resolucion.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);          
      return errors
    },
    nombreTramiteError(){
      const errors = []
      if(!this.$v.nombre.$dirty) return errors
      
      !this.$v.nombre.letterTramite && errors.push("No ingrese caracteres especiales")
      return errors
    },
    nombreResolucionError(){
      const errors = []
      if(!this.$v.nombreResolucion.$dirty) return errors
      
      !this.$v.nombreResolucion.letter && errors.push("No ingrese caracteres especiales ni números")
      return errors
    },
  },
  methods: {
    ...mapActions("procesoCompraDoc", [
      "getDocumentosProcesoPorTipo",
      "descargarBufferDocumento",
      'descargarArchivoProceso',
      'guardarArchivoProceso'
    ]),
    async getRecursos() {

      let estados = [6];
      let filters = {
        id_tipo: 2,
        estados: [...estados],
        pagination: false,
        id_etapa_proceso: this.procesoData.id_etapa_pausa,
      };
      const { data, headers, status } =
        await this.services.RecursoRevision.getRecursosRevisionUcp(
          Number(this.$route.params.idProceso),
          filters
        )
      if (status === 200) {
        this.apelacionesAdmitidos = data;
      }

    },
    async getDocumentoPronun() {
      this.id_tipo_documento = 15004;
      const response = await this.getDocumentosProcesoPorTipo({
        id_proceso_compra: this.$route.params.idProceso,
        id_tipo_documento: this.id_tipo_documento,
        id_etapa_proceso: this.procesoData.id_etapa_pausa,
      });
      this.pronunciamientos = response;
    },
    async showDocument(item) {
      const response = await this.descargarBufferDocumento({
        ruta_documento: item.doc_resolucion_tacop,
      });
      this.getDoc(response);
      this.modalApel = true;
      this.proveedor = item.Usuario.Proveedor;
      this.apelacion.estado = item.EstadoImpugnacion.nombre;
      this.apelacion.doc_resolucion_tacop = item.doc_resolucion_tacop;
    },
    getDoc(doc) {
      let b64 = Buffer.from(doc).toString("base64");
      this.doc = `data:application/pdf;base64,${b64}`;
    },
    async descargar(ruta) {

      const response =
        await this.descargarArchivoProceso({
          ruta_documento: ruta,
        })
      if (response.status != 200) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });

        return;
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], "Documento", {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.setAttribute("download", "Documento");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },
    async descargarApelacion(doc_resolucion_tacop){

      const response =
        await this.descargarArchivoProceso({
          ruta_documento: doc_resolucion_tacop,
        })

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], "Documento", {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.setAttribute("download", "Documento");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },
    async cargar() {

      this.$v.$touch()
      if(!this.$v.$invalid){
        const response = await this.guardarArchivoProceso({
          documento: this.tramite,
          nombre: this.nombre,
          id_proceso_compra: this.$route.params.idProceso,
          id_tipo_documento: 15005,
          id_etapa_proceso: this.procesoData.id_etapa_pausa,
        })
        if(response.status === 200){
          this.temporalAlert({
            show:true,
            type:"success",
            message:"Documento cargado exitosamente"
          })
          this.getActosTramites()
          this.nombre = null
          this.tramite = null
          this.$v.$reset()
        }
      }
    },
    async getActosTramites(){
      this.id_tipo_documento = 15005;
      const response = await this.getDocumentosProcesoPorTipo({
        id_proceso_compra: this.$route.params.idProceso,
        id_tipo_documento: this.id_tipo_documento,
        id_etapa_proceso: this.procesoData.id_etapa_pausa,
      });
      this.tramites = response;
    },
    async cargarResolucion(){

      this.$v.$touch()
      if(!this.$v.$invalid) {
        const response = await this.guardarArchivoProceso({
          documento: this.resolucion,
          nombre: this.nombreResolucion,
          id_proceso_compra: this.$route.params.idProceso,
          id_tipo_documento: 15006,
          id_etapa_proceso: this.procesoData.id_etapa_pausa,
        })
        if(response.status === 200){
          this.temporalAlert({
            show:true,
            type:"success",
            message:"Resolución final cargada exitosamente"
          })
          this.getResolucion()
          this.nombreResolucion = null
          this.resolucion = null
          this.$v.$reset()
        }
      }
    },
    async getResolucion(){
      this.id_tipo_documento = 15006;
      const response = await this.getDocumentosProcesoPorTipo({
        id_proceso_compra: this.$route.params.idProceso,
        id_tipo_documento: this.id_tipo_documento,
        id_etapa_proceso: this.procesoData.id_etapa_pausa,
      });

      if (response.length > 0) this.resolucionFinal = response[0].ruta_documento
    },
    async verResolucion(){
      const response = await this.descargarBufferDocumento({
        ruta_documento: this.resolucionFinal,
      });
      this.getDoc2(response);
      this.modalResolucion = true
    },
    getDoc2(doc) {
      let b64 = Buffer.from(doc).toString("base64");
      this.doc2 = `data:application/pdf;base64,${b64}`;
    },
  },
  created() {
    this.getRecursos();
    this.getDocumentoPronun();
    this.getActosTramites();
    this.getResolucion();
  },
};
</script>

<style></style>

<template>
  <Fragment v-if="permisos.leer">
    <v-row>
      <v-col>
        <div class="mb-8">
          <v-btn
            text
            color="secondary"
            class="text-no-style"
            @click="descargarActa"
          >
            <v-icon color="secondary" size="40">mdi-file-pdf-box</v-icon>
            Descargar acta de recomendación de PEO
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form
          class="mt-5"
          v-if="
            haveRoles([
              'ROLE_UACI',
              'ROLE_UACI_TECNICO',
              'ROLE_AUDITORIA_PROCESO',
            ])
          "
        >
          <v-row
            v-if="
              (!actaAdjudicacionActual || editActaAdjudicacion) &&
              !haveRoles(['ROLE_AUDITORIA_PROCESO'])
            "
          >
            <v-col cols="8" md="4" sm="8">
              <v-file-input
                v-model="actaAdjudicacion"
                accept="application/pdf"
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                label="Acta de adjudicación *"
                outlined
                :error-messages="errorArchivo"
                @blur="$v.actaAdjudicacion.$touch"
              ></v-file-input>
            </v-col>
            <v-col cols="4" md="8" sm="4" class="d-flex mt-2">
              <v-btn color="secondary" v-if="permisos.leer" @click="guardarActaAdjudicacion">
                <v-icon color="white">mdi-content-save</v-icon>
                Guardar
              </v-btn>
              <v-btn
                solid
                color="secondary"
                @click="editActaAdjudicacion = false"
                class="ml-4"
                v-if="editActaAdjudicacion"
              >
                <v-icon color="white">mdi-close</v-icon>
                Cancelar edición
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-else-if="actaAdjudicacionActual">
            <v-col class="d-flex">
              <v-btn color="secondary" @click="descargarActaAdjudicacion">
                Descargar acta de adjudicación
              </v-btn>
              <v-btn
                icon
                color="secondary"
                @click="editActaAdjudicacion = true"
                class="ml-4"
                v-if="!haveRoles(['ROLE_AUDITORIA_PROCESO'])"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row v-if="adjudicacionVisible">
      <v-col cols="12" md="12" v-if="permiteAdjudicar">
        <detallesGanadores :items="ganadores" />
      </v-col>
      <v-col cols="12" md="12" v-else>
        <v-alert type="info">
          Aún no se pueden realizar adjudicaciones de ganadores.
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO'])">
      <v-col cols="6" md="6">
        <v-btn color="primary" @click="recargar">Recargar resultados</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" cols="12">
        <app-tab-component :items="salas" v-if="salas.length > 0">
          <template v-slot:[`contenido`]="{ item }">
            <app-tab-component
              :items="item.subastas"
              v-if="item.subastas.length > 0"
            >
              <template v-slot:[`contenido`]="{ item }">
                <subastaTabContent
                  :item="item"
                  :id_proceso="id_proceso"
                  @update_subastas="recargar"
                />
              </template>
            </app-tab-component>
            <v-alert type="info" outlined v-else>
              No hay subastas configuradas para esta sala
            </v-alert>
          </template>
        </app-tab-component>
        <v-alert type="info" outlined v-else>
          No hay subastas para mostrar
        </v-alert>
      </v-col>
    </v-row>
  </Fragment>
</template>
<script>
import historialPujasComponent from "./hitoResultadosSubasta/HistorialPujasComponent.vue";
import detalleLote from "./hitoResultadosSubasta/DetalleLote.vue";
import subastaGanador from "./hitoResultadosSubasta/SubastaGanador.vue";
import detalleSubasta from "./hitoResultadosSubasta/DetalleSubasta.vue";
import detallesGanadores from "./hitoResultadosSubasta/DetallesGanadores.vue";
import subastaTabContent from "./hitoResultadosSubasta/subastaTabContent.vue";
import LegacyValidations from "@/utils/legacy-validations";
import { required } from "vuelidate/lib/validators";
function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "PublicacionResultadoSubasta",
  validations: {
    actaAdjudicacion: {
      required,
      fileTypeValid,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },
  data: () => ({
    salas: [],
    tab: null,
    actaAdjudicacion: null,
    actaAdjudicacionActual: null,
    editActaAdjudicacion: false,
    permiteAdjudicar: false,
    ganadores: [],
  }),
  components: {
    historialPujasComponent,
    detalleLote,
    subastaGanador,
    detalleSubasta,
    detallesGanadores,
    subastaTabContent,
  },
  props: {
    id_proceso: {
      type: Number,
      required: true,
    },
    permisos: {
      type: Object,
      default: {},
    },
  },
  computed: {
    errorArchivo() {
      const errors = [];
      if (!this.$v.actaAdjudicacion.$dirty) return errors;
      !this.$v.actaAdjudicacion.required &&
        errors.push("El archivo es requerido");
      !this.$v.actaAdjudicacion.fileTypeValid &&
        errors.push("El archivo debe ser PDF");
      !this.$v.actaAdjudicacion.fileNameValid &&
        errors.push("El archivo debe tener un nombre valido");
      !this.$v.actaAdjudicacion.fileSizeValid &&
        LegacyValidations.pushToErrorsArray(errors);

      return errors;
    },
    adjudicacionVisible() {
      return this.haveRoles([
        "ROLE_UACI",
        "ROLE_UACI_TECNICO",
        "ROLE_AUDITORIA_PROCESO",
      ]);
    },
  },
  methods: {
    async descargarActaAdjudicacion() {
      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: this.actaAdjudicacionActual.ruta_documento,
        });

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File(
        [blob],
        this.actaAdjudicacionActual.nombre_documento,
        {
          type: response.headers["content-type"],
        }
      );

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();
    },

    async obtenerActasAdjudicacion() {
      const response = await this.services.PacProcesos.obtenerDocumentosProceso(
        this.$route.params.idProceso,
        10000
      );

      this.actaAdjudicacionActual = response.data[0];
    },
    async descargarActa() {
      try {
        const response =
          await this.services.PacProcesos.descargarDocumentoProceso({
            ruta: this.actaActual?.ruta_documento,
          });

        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const file = new File([blob], this.actaActual.nombre_documento, {
          type: response.headers["content-type"],
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = "";
        link.click();
      } catch (e) {
        console.log(e);
      }
    },
    async guardarActaAdjudicacion() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let formData = new FormData();
        formData.append("documento", this.actaAdjudicacion);
        formData.append("nombre", "Acta de adjudicación");
        formData.append("id_proceso", this.$route.params.idProceso);
        formData.append("id_tipo_documento", 10000);
        formData.append("folder", "procesos/adjudicacion");
        formData.append("disk", "local");

        const response =
          await this.services.PacProcesos.guardarDocumentosProceso(formData);

        if (response.status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Documento cargado correctamente",
          });
          this.actaAdjudicacion = null;
          await this.obtenerActasAdjudicacion();
          this.editActaAdjudicacion = false;
          this.$v.$reset();
        }
      }
    },
    async obtenerGanadores() {
      const { data } = await this.services.Paac.obtenerGanadores(
        this.id_proceso
      );
      this.ganadores = data;
    },
    async obtenerSubastas() {
      const {
        data: { salas, permiteAdjudicar },
      } = await this.services.Paac.getSubastas(this.id_proceso);
      await this.obtenerGanadores();
      this.salas = salas;
      this.permiteAdjudicar = permiteAdjudicar;
    },
    async consultarActaActual() {
      const response = await this.services.PeoServices.descargarActaPanel(
        this.$route.params.idProceso,
        { id_tipo_documento: 4 }
      );

      this.actaActual = response.data;
    },
    async recargar() {
      this.salas = [];
      await this.obtenerSubastas();
      await this.obtenerGanadores();
      await this.obtenerGanadores();
      this.pushAppMessage({
        show: true,
        type: "success",
        color: "info",
        message: "Información de proceso recargada.",
      });
    },
  },
  async created() {
    await this.obtenerSubastas();
    await this.consultarActaActual();
    await this.obtenerActasAdjudicacion();
  },
};
</script>

<template>
  <section>
    <v-row>
      <v-col v-if="mainActaEconomica">
        <v-card class="ma-4">
          <div class="pa-4">
            <div class="d-flex align-center">
              <v-btn
                @click="descargarDocumento(mainActaEconomica?.ruta_documento)"
                text
                color="secondary"
              >
                <v-icon>mdi-download</v-icon>
                <span>Acta de evaluación económica</span>
              </v-btn>
            </div>
            <div class="d-flex justify-space-between align-center mx-4">
              <span class="grey--text"
                >{{ mainActaEconomica?.Usuario?.nombre }} ({{
                  mainActaEconomica?.Usuario?.email
                }})</span
              >
              <v-btn @click="showHistorialActas('economicas')" icon
                ><v-icon size="30">mdi-history</v-icon></v-btn
              >
            </div>
            <div class="d-flex align-center mx-4">
              <span class="grey--text">{{
                moment(mainActaEconomica?.created_at).format(
                  "DD/MM/YYYY HH:mm a"
                )
              }}</span>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col v-if="mainActaTecnica">
        <v-card class="ma-4">
          <div class="pa-4">
            <div class="d-flex align-center">
              <v-btn
                @click="descargarDocumento(mainActaTecnica?.ruta_documento)"
                text
                color="secondary"
              >
                <v-icon>mdi-download</v-icon>
                <span>Acta de evaluación técnica</span>
              </v-btn>
            </div>
            <div class="d-flex justify-space-between align-center mx-4">
              <span class="grey--text"
                >{{ mainActaTecnica?.Usuario?.nombre }} ({{
                  mainActaTecnica?.Usuario?.email
                }})</span
              >
              <v-btn @click="showHistorialActas('tecnicas')" icon
                ><v-icon size="30">mdi-history</v-icon></v-btn
              >
            </div>
            <div class="d-flex align-center mx-4">
              <span class="grey--text">
                {{
                  moment(mainActaTecnica?.created_at).format(
                    "DD/MM/YYYY HH:mm a"
                  )
                }}
              </span>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col v-if="mainActaAdjudicacion">
        <v-card class="ma-4">
          <div class="pa-4">
            <div class="d-flex align-center">
              <v-btn
                @click="
                  descargarDocumento(mainActaAdjudicacion?.ruta_documento)
                "
                text
                color="secondary"
              >
                <v-icon>mdi-download</v-icon>
                <span>Acta de adjudicación</span>
              </v-btn>
            </div>
            <div class="d-flex justify-space-between align-center mx-4">
              <span class="grey--text"
                >{{ mainActaAdjudicacion?.Usuario?.nombre }} ({{
                  mainActaAdjudicacion?.Usuario?.email
                }})</span
              >
              <v-btn @click="showHistorialActas('adj')" icon
                ><v-icon size="30">mdi-history</v-icon></v-btn
              >
            </div>
            <div class="d-flex align-center mx-4">
              <span class="grey--text">{{
                moment(mainActaAdjudicacion?.created_at).format(
                  "DD/MM/YYYY HH:mm a"
                )
              }}</span>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-expansion-panels v-model="panel" multiple accordion flat class="mt-8">
        <v-expansion-panel
          v-for="(proveedor, i) in adjudicacionesProveedor"
          :key="i"
        >
          <v-expansion-panel-header>
            <span class="mt-2 ma-2 font-weight-bold text-h6">{{
              proveedor?.proveedor
            }}</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-4">
            <div
              v-for="(lote, index) in proveedor.lotes"
              :key="index"
              class="d-flex flex-column"
            >
            <div class="pa-4">
              <p class="secondary--text text-h6">
                {{ lote?.nombre }} - {{ lote?.descripcion }}
              </p>

              <div
                v-for="(agrupadores, k) in lote?.agrupadores"
                :key="k"
                class="d-flex flex-column btnAditoria pa-4"
              >
                <span class="font-weight-bold secondary--text">{{
                  agrupadores?.nombre
                }}</span>
                <v-row align="center">
                  <v-col cols="12" sm="12" md="8" lg="8">
                    <div class="d-flex">
                      <p class="font-weight-bold">Adjudicado por:</p>
                      <p class="ml-2">
                        {{ agrupadores?.usuario?.primer_nombre }}
                        {{ agrupadores?.usuario?.primer_apellido }}
                      </p>
                    </div>
                    <span>{{ agrupadores?.descripcion }}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="2" lg="2">
                    <p class="text-center font-weight-bold secondary--text">
                      Cantidad
                    </p>
                    <p class="text-center text-h6">
                      {{ getTotalCantidad(agrupadores?.detalles) }} de
                      {{ agrupadores?.cantidad_adjudicada }}
                    </p>
                  </v-col>
                  <v-col cols="12" sm="12" md="2" lg="2">
                    <p class="text-center font-weight-bold secondary--text">
                      Monto adjudicado
                    </p>
                    <p class="text-center text-h6">
                      ${{
                        Intl.NumberFormat("en-US", {
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(agrupadores?.monto_adjudicado || 0)
                      }}
                    </p>
                  </v-col>
                </v-row>
                <data-table-component
                  :headers="headersObs"
                  :items="agrupadores?.detalles"
                  mobile_breakpoint="725"
                  :tiene_paginacion="false"
                >
                </data-table-component>
              </div>
            </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <v-dialog max-width="800" v-model="showHistory">
      <v-card class="pa-4">
        <v-card-title>
          <p class="secondary--text">Historial de modificaciones</p>
        </v-card-title>
        <v-card-text>
          <data-table-component
            :headers="headersActas"
            :items="historialActas"
            mobile_breakpoint="725"
            :tiene_paginacion="false"
          >
            <template v-slot:[`item.fecha_carga`]="{ item }">
              {{ moment(item.fecha_carga).format("DD/MM/YYYY") }}
            </template>
            <template v-slot:[`item.acciones`]="{ item }">
              <v-btn @click="descargarDocumento(item.ruta_documento)" icon>
                <v-icon color="secondary"> mdi-download </v-icon>
              </v-btn>
            </template>
          </data-table-component>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="showHistory = false" color="secondary" outlined
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";

export default {
  name: "AdjudicacionComponent",
  components: {
    DataTableComponent,
  },
  data: () => ({
    panel: [],
    headersObs: [
      {
        text: "OBS",
        align: "start",
        value: "obs",
        sortable: false,
      },
      {
        text: "Unidad de medida",
        value: "unidad_medida",
        sortable: false,
      },
      {
        text: "Unidad solicitante",
        value: "unidad_solicitante",
        sortable: false,
      },
      {
        text: "Cantidad",
        value: "cantidad",
        sortable: false,
        align: "center",
      },
      {
        text: "Monto ($)",
        value: "monto",
        sortable: false,
        align: "center",
      },
    ],
    headersActas: [
      {
        text: "Usuario",
        align: "start",
        value: "Usuario.nombre",
        sortable: false,
      },
      {
        text: "Fecha de subida",
        value: "fecha_carga",
        sortable: false,
      },
      {
        text: "Acciones",
        value: "acciones",
        sortable: false,
      },
    ],
    actasAdjudicacion: [],
    actasEconomica: [],
    actasTecnicas: [],
    adjudicacionesProveedor: [],
    showHistory: false,
    historialActas: [],
  }),
  computed: {
    mainActaAdjudicacion() {
      if (this.actasAdjudicacion.length > 0) {
        return this.actasAdjudicacion[0];
      }
    },
    mainActaEconomica() {
      if (this.actasEconomica.length > 0) {
        return this.actasEconomica[0];
      }
    },
    mainActaTecnica() {
      if (this.actasTecnicas.length > 0) {
        return this.actasTecnicas[0];
      }
    },
  },
  methods: {
    getTotalCantidad(detalles) {
      return detalles.reduce((a, b) => Number(a) + Number(b.cantidad), 0);
    },
    async getAdjudicacion() {
      const { status, data } =
        await this.services.PacProcesos.datosAdjudicacionAuditoria(
          this.$route.params.idProceso
        );

      if (status == 200) {
        this.actasAdjudicacion = data?.actas?.adjudicacion;
        this.actasEconomica = data?.actas?.evaluacion_economica;
        this.actasTecnicas = data?.actas?.evaluacion_tecnica;
        this.adjudicacionesProveedor = data?.adjudicaciones;
      }
    },
    async descargarDocumento(ruta) {
      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: ruta,
        })
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });

        return;
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], "oferta", {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },
    showHistorialActas(type) {
      this.historialActas = [];

      switch (type) {
        case "economicas":
          this.historialActas = this.actasEconomica.filter(
            (a) => a.id !== this.mainActaEconomica.id
          );
          break;
        case "tecnicas":
          this.historialActas = this.actasTecnicas.filter(
            (a) => a.id !== this.mainActaTecnica.id
          );
          break;
        case "adj":
          this.historialActas = this.actasAdjudicacion.filter(
            (a) => a.id !== this.mainActaAdjudicacion.id
          );
          break;
      }

      this.showHistory = true;
    },
  },
  created() {
    this.getAdjudicacion();
  },
};
</script>
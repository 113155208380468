<template>
  <v-row>
    <v-col cols="12" sm="6">
      <card-download-component
        title="Resolución de recurso de revisión"
        :archivo="{
          nombre: res_rec_apelacion?.nombre_documento,
          ruta: res_rec_apelacion?.ruta_documento,
        }"
      />
    </v-col>
    <v-col cols="0" sm="6" />
    <v-col cols="12" sm="6" v-if="rec_apelacion == null">
      <div class="d-flex flex-column flex-sm-row">
        <v-file-input
          label="Resolución de apelación"
          outlined
          accept="application/pdf"
          prepend-icon=""
          prepend-inner-icon="mdi-paperclip"
          v-model="doc_apelacion"
          clearable
          @blur="$v.doc_apelacion.$touch()"
          :error-messages="docApelacionErrors"
        />
        <div>
          <v-btn
            class="text-no-style mt-3 ml-sm-8"
            dark
            color="secondary"
            :disabled="$v.$invalid"
            @click="AddRecApelacion"
          >
            Cargar
          </v-btn>
        </div>
      </div>
    </v-col>
    <v-col cols="12" sm="6" v-else>
      <card-download-component
        :title="rec_apelacion?.TipoImpugnacion?.nombre"
        :archivo="{
          nombre: rec_apelacion?.nombre_documento,
          ruta: rec_apelacion?.doc_impugnacion,
        }"
      />
    </v-col>
  </v-row>
</template>

<script>
import CardDownloadComponent from "./CardDownloadComponent.vue";
import LegacyValidations from "@/utils/legacy-validations";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}
function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "PresentacionRecApeComponent",

  components: {
    CardDownloadComponent,
  },

  validations: {
    doc_apelacion: { 
      required, 
      fileTypeValid, 
      fileNameValid, 
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },

  data: () => ({
    doc_apelacion: null,
    rec_apelacion: null,
    res_rec_apelacion: null,
  }),

  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
    docApelacionErrors() {
      const errors = [];
      if (!this.$v.doc_apelacion.$dirty) return errors;
      !this.$v.doc_apelacion.required && errors.push("El archivo es requerido");
      !this.$v.doc_apelacion.fileTypeValid &&
        errors.push("El archivo debe ser PDF");
      !this.$v.doc_apelacion.fileNameValid &&
        errors.push("El nombre del archivo debe ser sin caracteres especiales");
      !this.$v.doc_apelacion.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);          
    },
  },

  methods: {
    ...mapActions("procesoCompraDoc", ["getDocumentosProcesoPorTipo"]),
    async FetchRecApe() {
      const { status, data } =
        await this.services.RecursoRevision.getRecursosProveedorPorTipo(
          this.$route.params.idProceso,
          2,
          {
            pagination: false,
            id_etapa_proceso: this.procesoData.id_etapa_pausa,
          }
        );

      if (status === 200) {
        this.rec_apelacion = data[0];
      }
    },

    async FetchReslRecRev() {
      const data = await this.getDocumentosProcesoPorTipo({
        id_proceso_compra: this.$route.params.idProceso,
        id_tipo_documento: 15003,
      });

      if (data.length > 0) {
        this.res_rec_apelacion = data[data.length - 1];
      } else {
        this.res_rec_apelacion = data[0];
      }
    },

    async AddRecApelacion() {
      this.$v.$touch();
      if (!this.$v.$invalid) {

        let formData = new FormData();
        formData.append("doc_impugnacion", this.doc_apelacion);
        formData.append("id_tipo_impugnacion", 2);
        formData.append("id_etapa_proceso", this.procesoData.id_etapa_pausa);

        const response =
          await this.services.RecursoRevision.storeRecursoProveedor(
            this.$route.params.idProceso,
            formData
          );

        if (response.status === 201) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Documento cargado correctamente",
          });
          this.doc_apelacion = null;
          this.FetchRecApe();
          this.$v.$reset();
        }

      }
    },
  },

  created() {
    this.FetchRecApe();
    this.FetchReslRecRev();
  },
};
</script>

<style>
</style>
<template>
  <section>
    <v-row>
      <v-col cols="12" class="d-flex justify-sm-center flex-column flex-sm-row">
        <div class="d-flex flex-column text-center mr-3">
          <span class="secondary--text text-h6">
            {{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                style: "currency",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(infoGeneral.monto_adjudicado)
            }}
          </span>
          <span> Monto adjudicado </span>
        </div>
        <div class="d-flex flex-column text-center mx-3">
          <span class="secondary--text text-h6">
            {{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                style: "currency",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(infoGeneral.monto_cancelado)
            }}
          </span>
          <span> Total cancelado </span>
        </div>
        <div class="d-flex flex-column text-center ml-3">
          <span class="secondary--text text-h6">
            {{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                style: "currency",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(infoGeneral.monto_restante)
            }}
          </span>
          <span> Monto restante </span>
        </div>
      </v-col>
      <v-col cols="12">
        <data-table-component
          class="mb-3"
          :headers="HEADERS_CONST"
          :items="dataProp"
          inhabilitar_paginacion
          :tiene_paginacion="false"
        >
          <template v-slot:[`item.fecha_hora_movimiento`]="{ item }">
            {{ FormatDatePrt(item.fecha_hora_movimiento, true) }}
          </template>
          <template v-slot:[`item.user`]="{ item }">
            {{ item.usuario.primer_nombre }}
            {{ item.usuario.primer_apellido }}
            ({{ item.usuario.email }})
          </template>
          <template v-slot:[`item.monto`]="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(item.monto)
            }}
          </template>
        </data-table-component>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import { mapState } from "vuex";

export default {
  name: "TabPagosComponent",

  components: {
    DataTableComponent,
  },

  props: {
    dataProp: {
      type: Array,
      required: true,
    },
    idContratoProp: {
      type: Number,
      required: true,
    },
    infoGeneral:{
      type: Object,
      required: true,
    }
  },

  data: () => ({
    // constants
    HEADERS_CONST: [
      {
        text: "Fecha",
        value: "fecha_hora_movimiento",
      },
      {
        text: "Usuario que realiza",
        value: "user",
      },
      {
        text: "Justificación",
        value: "justificacion",
      },
      {
        text: "Tipo",
        value: "tipo_movimiento",
      },
      {
        text: "Monto ($)",
        value: "monto",
      },
    ],

    // variables
    dataCntr: {},
  }),

  computed: {
    ...mapState("procesoCompraDoc", ["list_contrato_st"]),
  },

  mounted() {
    // busqueda del contrato en listado de contrato en vuex, fue sobreescrito por la prop de infoGeneral
    this.dataCntr = this.list_contrato_st.find(
      (item) => item.id === this.idContratoProp
    );
  },
};
</script>

<style>
</style>
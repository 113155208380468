<template>
  <v-row>
    <template v-if="!winner">
      <v-col
        cols="12"
        sm="8"
        v-if="recurso_revision?.id_estado_impugnacion == 1"
      >
        <span>
          Su recurso de revisión está siendo aprobado por la institución, se
          notificará su aprobación o rechazo en el rango de fechas establecidas
          para esta etapa.
        </span>
      </v-col>
      <v-col
        cols="12"
        sm="8"
        v-if="recurso_revision?.id_estado_impugnacion == 2"
      >
        <v-sheet color="white" elevation="1" rounded class="my-1">
          <div class="d-flex flex-column px-6 py-3">
            <div class="d-flex align-center">
              <v-icon class="mr-3" color="green">mdi-check-circle</v-icon>
              <span class="green--text">Recurso de revisión admitido</span>
            </div>
            <span>
              Su recurso de revisión ha sido admitido. A partir del día
              <b>
                {{
                  moment(fecha_resolucion).format("dddd D [de] MMMM [de] YYYY")
                }}
              </b>
              la institución cuenta con <b>10 días hábiles</b> para presentar
              una resolución la cual se le notificará a través de este sistema y
              mediante correo electrónico
            </span>
          </div>
        </v-sheet>
      </v-col>
      <v-col
        cols="12"
        sm="8"
        v-if="recurso_revision?.id_estado_impugnacion == 3"
      >
        <v-sheet color="white" elevation="1" rounded class="my-1">
          <div class="d-flex flex-column px-6 py-3">
            <div class="d-flex align-center">
              <v-icon class="mr-3" color="red">mdi-information</v-icon>
              <span class="red--text">Recurso de revisión rechazado</span>
            </div>
            <span>
              Su recurso de revisión ha sido rechazado. A continuación la
              institución le hace saber la razón por la cual considera que su
              recurso no es admisible.
            </span>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="6" v-if="recurso_revision?.id">
        <card-download-component
          :title="recurso_revision?.TipoImpugnacion?.nombre"
          :archivo="{
            nombre: recurso_revision?.nombre_documento,
            ruta: recurso_revision?.doc_impugnacion,
          }"
        />
      </v-col>
      <v-col cols="12" v-if="buffer_documento">
        <embed
          :src="buffer_documento"
          type="application/pdf"
          width="100%"
          height="600px"
        />
      </v-col>
    </template>
    <template v-else>
      <v-col
        cols="12"
        sm="8"
        v-if="recurso_revision?.id_estado_impugnacion == 2"
      >
        <v-sheet color="secondary" elevation="1" rounded class="my-1" dark>
          <div class="d-flex flex-column px-6 py-3">
            <div class="d-flex align-center">
              <v-icon class="mr-3" color="yellow">mdi-alert</v-icon>
              <span class="yellow--text">
                Se ha admitido recurso de revisión
              </span>
            </div>
            <span>
              Se ha admitido el recurso de revisión durante el proceso. A partir
              de la fecha de admisión cuentas con <b>2 días hábiles</b> para
              cargar tu pronunciamiento sobre el recurso.
            </span>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="12">
        <data-table-component
          :headers="headers_revision"
          :items="lista_documentos"
          :tiene_paginacion="false"
          class="mb-3"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            {{ moment(item.created_at).format("DD/MM/YYYY") || "-" }}
          </template>
          <template v-slot:[`item.fecha_admision_ucp`]="{ item }">
            {{
              item.fecha_admision_ucp
                ? moment(item.fecha_admision_ucp).format("DD/MM/YYYY")
                : "-"
            }}
          </template>
          <!-- slot de acciones -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="OpenModal(item)">
                  <v-icon color="secondary">
                    {{
                      item?.doc_pronunciamiento_revision
                        ? "mdi-eye"
                        : "mdi-file-document-alert"
                    }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{
                  item?.doc_pronunciamiento_revision ? "Ver" : "Pronunciamiento"
                }}
              </span>
            </v-tooltip>
          </template>
        </data-table-component>
      </v-col>
    </template>
    <modal-pronunciamiento-component ref="ref_modal" @reload="VerifyGanador" />
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DataTableComponent from "../../../../../components/DataTableComponent.vue";
import CardDownloadComponent from "./CardDownloadComponent.vue";
import ModalPronunciamientoComponent from "./ModalPronunciamientoComponent.vue";

export default {
  name: "AdmisionRecRevComponent",
  components: {
    DataTableComponent,
    CardDownloadComponent,
    ModalPronunciamientoComponent,
  },

  data: () => ({
    headers_revision: [
      { text: "Código", value: "correlativo" },
      { text: "Proveedor", value: "Usuario.Proveedor.nombre_comercial" },
      { text: "Fecha de presentación", value: "created_at" },
      { text: "Fecha de admisión", value: "fecha_admision_ucp" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    recurso_revision: {},
    buffer_documento: null,
    winner: false,
    lista_documentos: [],
    tipoEtapa: null,
  }),

  computed: {
    ...mapState("procesoCompraDoc", ["fecha_resolucion", "procesoData"]),
  },

  methods: {
    ...mapActions("procesoCompraDoc", ["descargarBufferDocumento"]),

    OpenModal(item) {
      this.$refs.ref_modal.show_dialog = true;
      this.$refs.ref_modal.InitDetailPron(item);
    },

    async FetchRecRev() {
      const { status, data } =
        await this.services.RecursoRevision.getRecursosProveedorPorTipo(
          this.$route.params.idProceso,
          1,
          {
            pagination: false,
            id_etapa_proceso: this.procesoData.id_etapa_pausa,
          }
        );

      if (status === 200) {
        this.recurso_revision = data[0];

        if (
          this.recurso_revision?.id_estado_impugnacion == 2 ||
          this.recurso_revision?.id_estado_impugnacion == 3
        ) {
          this.FetchImpugnacion(this.recurso_revision.id);
        }
      }
    },

    async VerifyGanador() {
      let idTipo = null;

      if (this.tipoEtapa == 15 || this.tipoEtapa == 6) {
        idTipo = 2;
      } else if (this.tipoEtapa == 14) {
        idTipo = 1;
      } else if (this.tipoEtapa == 16) {
        idTipo = 3;
      }
      const { status, data } =
        await this.services.PacProcesos.estadoEtapaProceso(
          this.$route.params.idProceso,
          idTipo
        );
      if (status === 200) {
        if (data.length > 0) {
          if (this.tipoEtapa == 1) {
            this.winner = data[0].tecnica;
          } else {
            this.winner = data[0].economica;
          }
        }
      }

      if (this.winner) {
        this.FetchListDocs();
      } else {
        this.FetchRecRev();
      }
    },

    async FetchListDocs() {
      const { data, status } =
        await this.services.RecursoRevision.getImpugnacionInstitucion(
          this.$route.params.idProceso,
          {
            id_tipo: 1,
            estados: "[2]",
            id_etapa_proceso: this.procesoData.id_etapa_pausa,
          }
        );

      if (status === 200) {
        this.lista_documentos = data;
      }
    },

    async FetchImpugnacion(id_impugnacion) {
      const { status, data } =
        await this.services.PacProcesos.getDetailImpugnacion(id_impugnacion);

      if (status === 200) {
        const file = await this.descargarBufferDocumento({
          ruta_documento: data.doc_impugnacion,
        });

        let b64 = Buffer.from(file).toString("base64");
        this.buffer_documento = `data:application/pdf;base64,${b64}`;
      }
    },

    async getTipoEtapaPrevia() {
      const { status, data } =
        await this.services.RecursoRevision.getTipoEtapaPrevia(
          this.$route.params.idProceso
        );
      if (status == 200) {
        this.tipoEtapa = data?.id_tipo_etapa;
      }
    },
  },

  async created() {
    await this.getTipoEtapaPrevia();
    this.VerifyGanador();
  },
};
</script>

<style></style>

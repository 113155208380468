<template>
  <v-dialog v-model="isOpen" max-width="1500" persistent>
    <div
      style="background-color: #ffffff; padding: 0 1em 2em; position: relative"
    >
      <div
        class="py-4"
        style="
          width: 100%;
          background-color: inherit;
          position: sticky;
          top: 0;
          z-index: 10;
        "
      >
        <v-btn
          style="
            min-width: unset !important;
            min-height: unset !important;
            width: 32px !important;
            height: 32px !important;
          "
          class="px-4 py-4"
          @click.stop="cerrarModal"
          text
          rounded
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </div>
      <v-card>
        <v-card-title>Adjuntar garantía</v-card-title>
        <v-card-subtitle>Esta se vinculará al contrato</v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="contrato"
                clearable
                :items="filtrarGanadores"
                label="Contrato"
                :item-text="item =>`${item.nombre_contrato} - ${item.nombre}`"
                item-value="id_contrato"
                outlined
                :error-messages="contratoErrors"
                @change="$v.contrato.$touch()"
                @blur="$v.contrato.$touch()"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.nombre_documento"
                outlined
                label="Nombre del documento"
                :error-messages="nombreDocumentoErrors"
                @change="$v.form.nombre_documento.$touch()"
                @blur="$v.form.nombre_documento.$touch()"
              />
            </v-col>
            <v-col cols="6">
              <v-file-input
                v-model="form.documento"
                label="PDF"
                placeholder="Seleccione un PDF"
                outlined
                light
                accept="application/pdf"
                :error-messages="documentoErrors"
                @change="$v.form.documento.$touch()"
                @blur="$v.form.documento.$touch()"
              />
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="menuDesde"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
                    label="Fecha inicio *"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    :value="calcularFecha(form.vigencia_desde)"
                    outlined
                  />
                </template>
                <v-date-picker
                  v-model="form.vigencia_desde"
                  locale="es"
                  scrollable
                  @input="menuDesde = false"
                  :error-messages="vigenciaDesdeErrors"
                  @change="$v.form.vigencia_desde.$touch()"
                  @blur="$v.form.vigencia_desde.$touch()"
                />
              </v-menu>
            </v-col>

            <v-col cols="6">
              <v-menu
                v-model="menuHasta"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
                    label="Fecha fin *"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    :value="calcularFecha(form.vigencia_hasta)"
                    outlined
                    :disabled="!form.vigencia_desde"
                  />
                </template>
                <v-date-picker
                  v-model="form.vigencia_hasta"
                  locale="es"
                  :min-date="form.vigencia_desde"
                  scrollable
                  @input="menuHasta = false"
                  :error-messages="vigenciaHastaErrors"
                  @change="$v.form.vigencia_hasta.$touch()"
                  @blur="$v.form.vigencia_hasta.$touch()"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <div class="d-flex justify-end mt-4">
        <v-btn
          class="flex-grow-1 flex-shrink-1"
          @click.stop="cerrarModal"
          color="secondary"
          >Cerrar</v-btn
        >
        <v-btn
          class="flex-grow-1 flex-shrink-1 ml-4"
          @click="abrirConfirmacion"
          color="primary"
          >Guardar</v-btn
        >
      </div>
    </div>
    <ConfirmationModalComponent
      :isOpen="modalConfirmacionAbierta"
      description="¿Desea continuar con la acción?"
      @confirm="guardarGarantia"
      @cancel="modalConfirmacionAbierta = false"
    />
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { Validator } from "@/utils/form-validation";
import moment from "moment";
import { ConfirmationModalComponent } from "@/components/utils";
import { convertToFormData } from "@/utils/data";
import { mapState } from "vuex";
import { Observable } from "@/utils/observable";

export default {
  name: "ModalGarantias",
  components: { ConfirmationModalComponent },
  emits: ["on-save"],
  props: {
    isOpen: { type: Boolean },
    ganadores: { type: Array },
  },
  validations: () => ({
    contrato: { required },
    form: {
      documento: { required },
      nombre_documento: { required },
      vigencia_desde: { required },
      vigencia_hasta: { required },
    },
  }),
  data: () => ({
    menuDesde: false,
    menuHasta: false,
    contrato: null,
    form: {
      documento: null,
      nombre_documento: null,
      vigencia_desde: null,
      vigencia_hasta: null,
    },
    config_calendar: {
      visibility: "click",
    },
    modalConfirmacionAbierta: false,
  }),
  computed: {
    // ...mapState("procesoCompraDoc", ["ganadores"]),
    contratoErrors() {
      return new Validator(this.$v.contrato).detect().getResult();
    },
    documentoErrors() {
      return new Validator(this.$v.form.documento).detect().getResult();
    },
    nombreDocumentoErrors() {
      return new Validator(this.$v.form.nombre_documento).detect().getResult();
    },
    vigenciaDesdeErrors() {
      return new Validator(this.$v.form.vigencia_desde).detect().getResult();
    },
    vigenciaHastaErrors() {
      return new Validator(this.$v.form.vigencia_hasta).detect().getResult();
    },
    filtrarGanadores(){
      return this.ganadores.filter(ganador => ganador);
    }
  },
  methods: {
    calcularFecha(fecha) {
      if (!fecha) return "Aún no seleccionada";
      return moment(fecha).format("DD/MM/YYYY");
    },
    async guardarGarantia() {
      await this.services.ContratoService.agregarGarantia(
        this.contrato,
        convertToFormData({ ...this.form })
      );
      Observable.emit("refresh-documentos")
      this.limpiarFormulario();
      this.cerrarModal();
    },
    abrirConfirmacion(){
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.modalConfirmacionAbierta = true;
    },
    limpiarFormulario() {
      this.contrato = null;
      this.form = {};
    },
    cerrarModal() {
      this.$emit("close");
    },
  },
};
</script>

<template>
  <section>
    <v-tabs v-model="idTab" background-color="bgMinsal" show-arrows>
      <v-tab
        v-for="item in contenido"
        :key="item.id"
        class="secondary--text"
        style="text-transform: none !important"
        v-if="!item.hide"
      >
        <v-icon>{{ item.icon }}</v-icon>
        <span class="pl-1">{{ item.tab }}</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="idTab">
      <v-tab-item v-for="item in contenido" :key="item.id" v-if="!item.hide">
        <component
          :is="item?.content"
          :proceso="procesoData"
          class="mt-8"
        ></component>
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>

<script>
  import { mapState } from "vuex";
  import ProveedorEtapaActualComponent from "./components/ProveedorEtapaActualComponent.vue";
  import UcpEtapaActualComponent from "./components/UcpEtapaActualComponent.vue";
  import TacopEtapaActualComponent from "./components/TacopEtapaActualComponent.vue";
  import EtapasProcesoImpugnacionesComponent from "./components/EtapasProcesoImpugnacionesComponent.vue";

  export default {
    name: "ProcesoImpugnacionesComponent",
    data: () => ({
      idTab: 0,
    }),
    computed: {
      ...mapState("procesoCompraDoc", ["procesoData", "participando"]),
      contenido() {
        if (this.haveRole('ROLE_PROVEEDOR')) {
          return [
            {
              id: 1,
              tab: "Etapa actual",
              icon: "mdi-clock-outline",
              content: ProveedorEtapaActualComponent,
            },
            {
              id: 2,
              tab: "Etapas del proceso",
              icon: "mdi-format-list-bulleted",
              content: EtapasProcesoImpugnacionesComponent,
            },
          ];
        }

        if (this.haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO'])) {
          return [
            {
              id: 1,
              tab: "Etapa actual",
              icon: "mdi-clock-outline",
              content: UcpEtapaActualComponent,
            },
            {
              id: 2,
              tab: "Etapas del proceso",
              icon: "mdi-format-list-bulleted",
              content: EtapasProcesoImpugnacionesComponent,
            },
          ];
        }

        if (this.haveRole('ROLE_TACOP')) {
          return [
            {
              id: 1,
              tab: "Etapa actual",
              icon: "mdi-clock-outline",
              content: TacopEtapaActualComponent,
            },
            {
              id: 2,
              tab: "Etapas del proceso",
              icon: "mdi-format-list-bulleted",
              content: EtapasProcesoImpugnacionesComponent,
            },
          ];
        }
      },
    },
    methods: {},
  };
</script>

<template>
    <section>
        <v-row>
      <v-col cols="12" sm="12" md="6">
        <h2 class="secondary--text mb-2">
          {{ etapa?.CtlEtapasProcesoImpugnacion?.nombre_etapa }}
        </h2>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-row align="center">
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de inicio:
              {{ moment(etapa?.fecha_inicio).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de fin:
              {{ moment(etapa?.fecha_fin).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    </section>
</template>

<script>

export default {
    props:{
      etapa:{
        type:Object,
      }
    }
}
</script>

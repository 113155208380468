<template>
  <section>
    <!-- Tabla para mostrar proveedores financieros -->
    <v-row>
      <v-col>
        <div class="d-flex justify-space-between">
          <v-btn
            color="secondary text-no-style"
            class="mb-8"
            @click="agregarGanador"
          >
            <v-icon color="white">mdi-plus</v-icon> Agregar nuevo ganador
          </v-btn>
          <v-btn
            color="amber lighten-3"
            class="mb-8"
            @click="abrirModalSuspender()"
          >
            <v-icon>mdi-pause-circle</v-icon> Suspender proceso
          </v-btn>
        </div>

        <v-data-table
          hide-default-footer
          :items-per-page="-1"
          :headers="encabezadoTabla"
          :items="datosTabla"
        >
          <template v-slot:item.proveedor="{ item }">
            {{ item.ParticipacionProveedor?.Proveedor?.nombre || item.Proveedor?.nombre }}
          </template>
          <template v-slot:item.acciones="{ item }">
            <v-btn icon @click="verGanador(item, tipoOferta)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon class="ml-4" @click="eliminarEvaluacion(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <ProcesoSuspenderComponent
      :modal="modalProceso"
      :proceso="procesoData"
      @cerrar="cerrarModal"
    />
  </section>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import { mapState } from "vuex";
import ProcesoSuspenderComponent from "../../../components/ProcesoSuspenderComponent.vue";
export default {
  name: "AddGanadorComponent",
  components: {
    DataTableComponent,
    ProcesoSuspenderComponent,
  },
  data() {
    return {
      encabezadoTabla: [
        {
          text: "Proveedor",
          align: "start",
          sortable: false,
          value: "proveedor",
        },

        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "acciones",
          width: "20%",
        },
      ],
      datosTabla: [],
      tipoEtapa: null,
      modalProceso: false,
    };
  },
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData", "participando"]),
    tipoOferta() {
      switch (this.tipoEtapa) {
        case 14:
          return "tecnica";
        case 15:
          return "financiera";
        case 16:
          return "financiera";
        case 6:
          return "financiera";
        default:
          "";
      }
    },
  },
  methods: {
    verGanador(item, tipo) {
      this.$router.push({
        name: "peo-nuevo-ganador",
        params: {
          id_proceso: this.$route.params.idProceso,
          id_oferta: item?.ofertas?.length > 0 ? item?.ofertas[0]?.id : item.id,
        },
        query: {
          from: "adjudicacion",
          tipo: tipo,
        },
      });
    },
    cerrarModal() {
      this.modalProceso = false;
      location.reload();
    },
    async getGanadores() {


      let response = null;

      if (this.tipoOferta == "financiera") {
        response = await this.services.PeoServices.getGanadoresProcesos(
          this.$route.params.idProceso,
          "",
          { ganador: true }
        );
      } else {
        response = await this.services.PeoServices.getGanadoresOfertaTecnica(
          this.$route.params.idProceso,
          { es_ganador: true }
        );
      }

      if (response.status == 200) {
        this.datosTabla = response.data;
      }

    },
    agregarGanador() {
      this.$router.push({
        name: "peo-nuevo-ganador",
        params: {
          id_proceso: this.$route.params.idProceso,
        },
        query: {
          from: "adjudicacion",
          tipo: this.tipoOferta,
        },
      });
    },

    async eliminarEvaluacion(participacion) {

      const idParticipacion = this.tipoEtapa === 14 ? participacion.id : participacion.id_participacion_proveedor;

      const response = await this.services.PeoServices.eliminarEvaluacion(
        this.$route.params.idProceso,
        idParticipacion
      );
      if (response.status == 200) {
        this.getGanadores();
      }

    },
    async getTipoEtapaPrevia() {
      const { status, data } =
        await this.services.RecursoRevision.getTipoEtapaPrevia(
          this.$route.params.idProceso
        );
      if (status == 200) {
        this.tipoEtapa = data?.id_tipo_etapa;
      }
    },
    abrirModalSuspender() {
      this.modalProceso = true;
    },
  },
  async created() {
    await this.getTipoEtapaPrevia();
    this.getGanadores();
  },
};
</script>

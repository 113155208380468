<template>
  <section>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <h2 class="secondary--text mb-2">{{ 
          etapa?.CtlEtapasProcesoImpugnacion?.nombre_etapa }}</h2>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-row align="center">
          <v-col cols="6">
            <p class="secondary--text">Fecha de inicio: {{ moment(etapa?.fecha_inicio).format("DD/MM/YYYY HH:mm") }}</p>
          </v-col>
          <v-col cols="6">
            <p class="secondary--text">Fecha de fin: {{ moment(etapa?.fecha_fin).format("DD/MM/YYYY HH:mm") }}</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <h5 class="mt-2 mb-3">Recursos de revisión presentados</h5>

    <data-table-component
      :headers="headers"
      :items="recursos"
      v-models:select="perPage"
      v-models:pagina="page"
      :total_registros="totalRows"
      @paginar="paginar"
    >
      <template #[`item.created_at`]="{ item }">
        {{ moment(item.created_at).format("DD/MM/YYYY") }}
      </template>
      <template #[`item.acciones`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              icon
              v-bind="attrs"
              @click="abrirModal(item)"
            ><v-icon>mdi-eye</v-icon></v-btn>
          </template>
          <span>Ver recurso</span>
        </v-tooltip>
      </template>
    </data-table-component>

    <v-dialog v-model="modalRecurso" max-width="800px">
      <v-card class="pb-2">
        <embed
          width="100%"
          height="700"
          :src="doc + '#page=1%zoom=100'"
          v-if="doc"
        />
        <v-card-actions>
          <v-btn @click.stop="modalRecurso = false" color="secondary"  outlined>
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DataTableComponent from '../../../../../components/DataTableComponent.vue'
export default {
  components: { DataTableComponent },
  name: "TacopEtapaActualComponent",
  props:{
    etapa:{
      type:Object,
      default: () => {}
    }
  },
  data:() => ({
    headers:[
      {
        text: "Código",
        value: "correlativo",
        align: "start"
      },
      {
        text: "Proveedor",
        value: "Usuario.Proveedor.nombre_comercial",
        align: "start"
      },
      {
        text: "Fecha de presentación",
        value: "created_at",
        align: "start"
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center"
      },
    ],
    recursos:[],
    page:1,
    perPage:10,
    totalRows:0,
    pagination:true,
    id_tipo:2,
    modalRecurso:false,
    doc:null,
  }),
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
  },
  methods:{
    ...mapActions("procesoCompraDoc", ["descargarBufferDocumento"]),
    async getRecursosApelacion() {

      
      const { data, headers, status } = await this.services.RecursoRevision.getRecursosRevisionUcp(
        Number(this.$route.params.idProceso),
        {
          id_tipo:this.id_tipo,
          page:this.page,
          per_page:this.perPage,
          id_etapa_proceso: this.procesoData.id_etapa_pausa,
        }
      )
      if(status === 200) {
        this.recursos = data
        this.page = Number(headers.page)
        this.perPage = Number(headers.per_page)
        this.totalRows = Number(headers.total_rows)
      }
    },
    paginar(filtros) {
      const { pagina, cantidad_por_pagina } = filtros
      this.page = pagina
      this.perPage = cantidad_por_pagina
      this.getRecursosApelacion()
    },
    async abrirModal(item) {
      const response = await this.descargarBufferDocumento({
        ruta_documento: item.doc_impugnacion,
      });
      this.getDoc(response);
      this.modalRecurso = true;
    },
    getDoc(doc) {
      let b64 = Buffer.from(doc).toString("base64");
      this.doc = `data:application/pdf;base64,${b64}`;
    },

  },
  created() {
    this.getRecursosApelacion()
  }
}
</script>
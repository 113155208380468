<template>
  <v-col cols="12" md="2">
    <span> {{ salas.length ?? 0 }} Salas disponibles: </span>
    <br />
    <v-text-field v-model="sala_busqueda" label="Buscar sala" outlined dense clearable />
    <v-list>
      <v-list-item-group v-model="list_salas" :mandatory="subastaSeleccionada != null">
        <br />
        <div>
        </div>
        <div class="FixedHeightContainer Content">
          <v-list-item v-for="(item, index) in listadoSalas" :key="index" @click="$emit('addSalaSeleccionada', item)">
            <template v-slot:default="{}">
              <v-list-item-action>
                <v-icon large>
                  {{
                    item?.id_sala === sala_seleccionada
                      ? "mdi-check-circle-outline"
                      : "mdi-radiobox-blank"
                  }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title v-bind="attrs" v-on="on">
                    </v-list-item-title>
                    {{ item?.nombre_sala }}
                  </template>
                  <span>{{ item?.nombre_sala }}</span>
                </v-tooltip>
              </v-list-item-content>
            </template>
          </v-list-item>
        </div>
        <v-list-item class="primary white--text" v-if="subastaSeleccionada">
          <v-list-item-content @click="$emit('limpiarSubastaActiva')">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title v-bind="attrs" v-on="on">
                  Abandonar sala
                </v-list-item-title>
              </template>
              <span>
                Esto removerá toda la información referente a la subasta que
                haya seleccionado
              </span>
            </v-tooltip>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-col>
</template>
<script>
export default {
  name: "SeleccionSala",
  props: {
    salas: Array,
    sala_seleccionada: Number,
    subastaSeleccionada: Object,
  },
  data: () => ({
    list_salas: null,
    sala_busqueda: "",
  }),
  computed: {
    listadoSalas() {
      return this.sala_busqueda
        ? this.salas.filter((sala) => {
          return (
            sala.nombre_sala
              .toLowerCase()
              .includes(this.sala_busqueda.toLowerCase()) ||
            sala.id_sala === this.sala_seleccionada
          );
        })
        : this.salas;
    },
  },
};
</script>
<style scoped>
.FixedHeightContainer {
  height: 500px;
}

.Content {
  height: 495px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
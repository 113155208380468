<template>
  <section>
    <v-row>
      <v-col cols="12" class="d-flex justify-sm-start flex-column flex-sm-row">
        <div class="d-flex flex-column mr-3">
          <span>Fecha y hora de contratación </span>
          <span> {{ FormatDatePrt(dataCntr.fecha_hora_contratacion, true) }} </span>
        </div>
        <div class="d-flex flex-column mx-3">
          <span>Número de orden de compra</span>
          <span> {{ dataCntr.numero }} </span>
        </div>
        <div class="d-flex flex-column ml-3">
          <span>Número interno de orden de compra</span>
          <span> {{ dataCntr.numero_interno }} </span>
        </div>
      </v-col>
      <v-col cols="12">
        <data-table-component
          table_title="Cambios en plazo contractual"
          class="mb-3"
          :headers="HEADERS_CONST"
          :items="dataProp"
          inhabilitar_paginacion
          :tiene_paginacion="false"
        >
          <template v-slot:[`item.nuevo_plazo`]="{ item }">
            {{ item.nuevo_plazo }} días
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ FormatDatePrt(item.updatedAt, true) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="
                    descargarArchivoProceso({
                      ruta_documento: item.url_documento,
                      disk: 'documents',
                    })
                  "
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Descargar</span>
            </v-tooltip>
          </template>
        </data-table-component>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "TabGeneralComponent",

  components: {
    DataTableComponent,
  },

  props: {
    dataProp: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    // constants
    HEADERS_CONST: [
      {
        text: "Plazo establecido",
        value: "nuevo_plazo",
      },
      // {
      //   text: "Cambio realizado por",
      //   value: "username",
      // },
      {
        text: "Cambio realizado en",
        value: "updatedAt",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
      },
    ],
  }),

  computed: {
    ...mapState({
      dataCntr: (state) => state?.procesoCompraDoc?.info_general_contrato_st,
    }),
  },

  methods: {
    ...mapActions("procesoCompraDoc", ["descargarArchivoProceso"]),
  },
};
</script>
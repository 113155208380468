<template>
  <section v-if="permisos.leer">
    <v-row
      class="mb-8"
      v-if="actaAdjudicacionActual && esNotificacionEconomica"
    >
      <v-col v-if="actaTecnica" cols="12" sm="4">
        <v-card @click="descargarDocumento(actaTecnica)">
          <v-card-text>
            <p class="text-h6 secondary--text">Acta de evaluación técnica</p>
            <v-btn text color="secondary" class="pl-0">
              <v-icon color="secondary" size="40">mdi-file-pdf-box</v-icon>
              Descargar acta de evaluación
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="actaEconomica" cols="12" sm="4">
        <v-card @click="descargarDocumento(actaEconomica)">
          <v-card-text>
            <p class="text-h6 secondary--text">Acta de evaluación económica</p>
            <v-btn text color="secondary" class="pl-0">
              <v-icon color="secondary" size="40">mdi-file-pdf-box</v-icon>
              Descargar acta de evaluación
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card @click="descargarDocumento(actaAdjudicacionActual)">
          <v-card-text>
            <p class="text-h6 secondary--text">Acta de adjudicación</p>
            <v-btn text color="secondary" class="pl-0">
              <v-icon color="secondary" size="40">mdi-file-pdf-box</v-icon>
              Descargar acta de adjudicación
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

<!--    <h2-->
<!--      v-if="estadoEtapa"-->
<!--      class="secondary&#45;&#45;text text-align: center mt-8"-->
<!--      style="font-style: italic"-->
<!--    >-->
<!--      Se le informa que su oferta ha sido revisada y ha calificado para ser-->
<!--      agregado a la lista de proveedores que continuarán el proceso. Manténgase-->
<!--      atento al desarrollo de las próximas etapas del proceso.-->
<!--    </h2>-->
<!--    <h2-->
<!--      v-if="!estadoEtapa"-->
<!--      class="secondary&#45;&#45;text text-align: center mt-8"-->
<!--      style="font-style: italic"-->
<!--    >-->
<!--      Se le informa que su oferta ha sido revisada, lastimosamente no ha-->
<!--      calificado para seguir el proceso.-->
<!--    </h2>-->
    <br />
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    estadoEtapa: null,
    actaAdjudicacionActual: null,
    esNotificacionEconomica: false,
    actaTecnica: null,
    actaEconomica: null,
  }),
  props: {
    etapa: {
      type: Object,
      default: {},
    },
    etapas: {
      type: Array,
      default: () => [],
    },
    permisos: {
      type: Object,
      default: {},
    },
  },
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData", "participando"]),
  },
  methods: {
    async getEstadoNotificacion(id_tipo_etapa, id) {
      let response = null;
      switch (id_tipo_etapa) {
        case 14:
          response = await this.services.PacProcesos.estadoEtapaProceso(id, 1);
          break;

        case 15:
          response = await this.services.PacProcesos.estadoEtapaProceso(id, 2);
          break;

        case 16:
          response = await this.services.PacProcesos.estadoEtapaProceso(id, 3);
          break;

        default:
          response = await this.services.PacProcesos.estadoEtapaProceso(
            id,
            id_tipo_etapa
          );
          break;
      }

      if (response.data[0]?.tecnica !== undefined) {
        this.estadoEtapa = response.data[0].tecnica;
      } else if (response.data[0]?.economica !== undefined) {
        this.estadoEtapa = response.data[0].economica;
      }
    },
    async obtenerActasAdjudicacion() {

      const response = await this.services.PacProcesos.obtenerDocumentosProceso(
        this.$route.params.idProceso,
        10000
      );

      if (response.status === 200) {
        this.actaAdjudicacionActual = response.data[0];
      }

    },
    async descargarDocumento(acta) {

      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: acta.ruta_documento,
        });

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File(
        [blob],
        acta.nombre_documento,
        {
          type: response.headers["content-type"],
        }
      );

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },
    async getEvaluacionEconomica() {
      const { status, data } =
        await this.services.PeoServices.descargarActaPanel(
          this.$route.params.idProceso,
          { id_tipo_documento: 4 }
        );

      if (status == 200) {
        this.actaEconomica = data;
      }
    },
    async getEvaluacionTecnica() {
      const { status, data } =
        await this.services.PeoServices.descargarActaPanel(
          this.$route.params.idProceso,
          { id_tipo_documento: 5001 }
        );

      if (status == 200) {
        this.actaTecnica = data;
      }
    },
  },
  async created() {
    this.etapas.some(async (element) => {
      if ([14, 15, 16].includes(element.id_tipo_etapa)) {
        if (this.haveRole("ROLE_PROVEEDOR")) {
          await this.getEstadoNotificacion(
            element.id_tipo_etapa,
            this.procesoData?.id
          );
        }
      }
    });

    this.etapas.some(async (element) => {
      if ([15, 16].includes(element.id_tipo_etapa)) {
        this.esNotificacionEconomica = true;
        await this.obtenerActasAdjudicacion();
      }
    });

    this.getEvaluacionEconomica();
    this.getEvaluacionTecnica();
  },
};
</script>

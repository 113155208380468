<template>
  <section>
    <v-row justify="center" class="align-center my-5">
      <v-expansion-panels accordion color="blueGrayMinsal" multiple>
        <v-expansion-panel v-for="(consulta, i) in consultas" :key="i">
          <v-expansion-panel-header color="bgMinsal">
            <v-row justify="space-between" class="align-center">
              <div>
                <span class="secondary--text">{{
                  consulta.proveedor_nombre
                }}</span>
                <span class="grey--text ml-2">{{
                  moment(consulta.fecha).format("DD/MM/YYYY HH:mm:ss")
                }}</span>
                <v-chip
                  label
                  :color="consulta.color"
                  small
                  class="white--text ml-3"
                >
                  <span v-if="consulta.id_estado == 1">Presentada</span>
                  <span v-if="consulta.id_estado == 2">Rechazada</span>
                  <span v-if="consulta.id_estado == 3">Publicada</span>
                </v-chip>
              </div>
              <div v-if="consulta.nombre != null && consulta.ruta != null">
                <v-icon class="mr-2" color="secondary">mdi-file-pdf-box</v-icon>
                <a @click="descargarArchivo(consulta)">{{
                  decodeString(consulta.nombre)
                }}</a>
              </div>
              <span class="mt-3 text-wrap">{{ consulta.consulta }}</span>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            color="bgMinsal"
            v-for="respuesta in consulta.respuestas"
            :key="respuesta.id"
          >
            <v-row class="my-3">
              <div>
                <span class="secondary--text">{{
                  respuesta.institucion_nombre
                }}</span>
                <span class="grey--text ml-2">{{
                  moment(respuesta.fecha).format("DD/MM/YYYY HH:mm:ss")
                }}</span>
              </div>
              <span class="mt-2">{{ respuesta.consulta }}</span>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </section>
</template>

<script>
import PaacServices from "@/services/Paac.services";
import PacProcesosServices from "@/services/PacProcesos.services";
import moment from "moment";

export default {
  name: "ListarConsultasComponent",
  data: () => ({
    consultas: [],
  }),

  methods: {
    decodeString(string) {
      return decodeURIComponent(escape(string));
    },
    async getConsultas() {
      const { data } = await PacProcesosServices.getConsultasProcesoAuditoria(
        this.$route.params.idProceso
      );
      this.consultas = data;
    },
    async descargarArchivo(consulta) {
      const response = await PaacServices.descargarDocumentoConstulas(
        consulta.id,
        consulta.ruta
      ).catch(() => {});

      if (response.status == 200) {
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = consulta.ruta;
        link.click();
      }
    },
  },

  created() {
    this.getConsultas();
  },
};
</script>

<style lang="scss">
.expansion-panels-consultas {
  .v-expansion-panel-header {
    // Colocar borde en la parte inferior
    // border-bottom: 1px solid #e0e0e0;
    border: 1px solid red;
  }
  .v-expansion-panel {
    .v-expansion-panel-content {
      background-color: var(--v-bgMinsal-base);
    }

    .v-expansion-panel-header {
      border-bottom: 1px solid #e0e0e0 !important;
      padding-left: 10px !important;
    }
  }
  .v-expansion-panel:before {
    box-shadow: none !important;
  }
  .v-expansion-panel::before {
    box-shadow: none !important;
  }
}
</style>

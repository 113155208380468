<template>
  <div class="pa-4">
    <p class="text-h6">Modificación al contrato</p>
    <data-table-component
      :headers="headers"
      :items="solicitud.asignacion_contrato"
      mobile_breakpoint="725"
      :tiene_paginacion="false"
    >
      <template v-slot:[`item.created_at`]="{ item }">
          {{moment(item.created_at).format("DD/MM/YYYY")}}
      </template>
    </data-table-component>
  </div>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";

export default {
  name: "ModificacionContratoComponent",
  components: {
    DataTableComponent,
  },
  props: {
    solicitud: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    headers: [
      {
        text: "Comentario",
        align: "center",
        value: "comentario",
      },
      {
        text: "Fecha de creación",
        align: "center",
        value: "created_at",
      },
      {
        text: "Modificado por",
        value: "id_usuario",
        align: "center",
        sortable: false,
      },
    ],
  }),
};
</script>
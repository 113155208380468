<template>
  <section class="ma-8">
    <AdmisionRecursoComponent
      v-if="etapaProceso?.id_etapa_proceso_impugnacion === 1"
      :etapa="etapaProceso"
    />

    <ResolucionRecursoComponent
      v-if="etapaProceso?.id_etapa_proceso_impugnacion === 2"
      :etapa="etapaProceso"
    />

    <RecepcionRecursoApelacionComponent
      v-if="etapaProceso?.id_etapa_proceso_impugnacion === 3"
      :etapa="etapaProceso"
    />

    <AdmisionRecursoApelacionTacopComponent
      v-if="etapaProceso?.id_etapa_proceso_impugnacion === 4"
      :etapa="etapaProceso"
    />

    <ResolucionRecursoApelacionComponent
      v-if="etapaProceso?.id_etapa_proceso_impugnacion === 5"
      :etapa="etapaProceso"
    />
  </section>
</template>

<script>
import RecepcionRecursoApelacionComponent from "./RecursoRevisionTACOP/RecepcionRecursoApelacionComponent.vue";
import AdmisionRecursoApelacionTacopComponent from "./RecursoRevisionTACOP/AdmisionRecursoApelacionTacopComponent.vue";
import ResolucionRecursoApelacionComponent from "./RecursoRevisionTACOP/ResolucionRecursoApelacionComponent.vue";
import AdmisionRecursoComponent from "./RecursoRevisionTACOP/AdmisionRecursoComponent.vue";
import ResolucionRecursoComponent from "./RecursoRevisionTACOP/ResolucionRecursoComponent.vue";
import { mapActions } from "vuex";
export default {
  data: () => ({
    etapaProceso: null,
  }),
  components: {
    RecepcionRecursoApelacionComponent,
    AdmisionRecursoApelacionTacopComponent,
    ResolucionRecursoApelacionComponent,
    AdmisionRecursoComponent,
    ResolucionRecursoComponent,
  },
  methods: {
    ...mapActions("procesoCompraDoc", ["getEtapaActualProcesoImpugnacion"]),
    async getEtapaActual() {
      const response = await this.getEtapaActualProcesoImpugnacion({
        id_proceso_compra: this.$route.params.idProceso,
      });
      this.etapaProceso = response;
    },
  },
  created() {
    this.getEtapaActual();
  },
};
</script>

<style></style>

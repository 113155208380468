var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.show_dialog),callback:function ($$v) {_vm.show_dialog=$$v},expression:"show_dialog"}},[_c('v-card',{staticClass:"bgMinsal"},[_c('v-card-actions',[_c('v-btn',{staticClass:"button-extra text-capitalize mt-4 mt-sm-0",attrs:{"color":"secondary","outlined":""},on:{"click":function($event){_vm.show_dialog = false}}},[_vm._v(" Cerrar ")])],1),_c('v-card-title',{staticClass:"secondary--text d-flex justify-center"},[_c('div',[_vm._v("Pronunciamiento del adjudicado")])]),_c('v-card-text',[_c('v-row',[(_vm.invalid && !_vm.contain_doc)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-alert")]),_c('span',{staticClass:"error--text"},[_vm._v(" No presentó pronunciamiento sobre el recurso de revisión ")])],1):_vm._e(),(_vm.contain_doc && !_vm.invalid)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('card-download-component',{attrs:{"title":"Pronunciamiento del adjudicado","archivo":{
                nombre: _vm.pronunciamiento?.nombre_documento,
                ruta: _vm.pronunciamiento?.ruta_documento,
              }}}),_c('v-spacer')],1)]):(!_vm.contain_doc && !_vm.invalid)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-column flex-sm-row"},[_c('v-spacer'),_c('v-file-input',{attrs:{"label":"Pronunciamiento del adjudicado","outlined":"","accept":"application/pdf","prepend-icon":"","prepend-inner-icon":"mdi-paperclip","clearable":"","error-messages":_vm.FileErrors},on:{"blur":function($event){return _vm.$v.file_pronunciamiento.$touch()}},model:{value:(_vm.file_pronunciamiento),callback:function ($$v) {_vm.file_pronunciamiento=$$v},expression:"file_pronunciamiento"}}),_c('div',[_c('v-btn',{staticClass:"text-no-style mt-3 ml-sm-8",attrs:{"dark":"","color":"secondary","disabled":_vm.$v.$invalid},on:{"click":_vm.AddPronunciamiento}},[_vm._v(" Cargar ")])],1),_c('v-spacer')],1)]):_vm._e(),_c('v-col',{staticClass:"d-flex flex-column text-center",attrs:{"cols":"12","sm":"6"}},[_c('span',[_vm._v(" "+_vm._s(_vm.impugnacion?.Usuario?.Proveedor?.nombre_comercial)+" ")]),_c('div',[_c('v-chip',{staticClass:"ma-2",attrs:{"label":""}},[_vm._v(" "+_vm._s(_vm.impugnacion?.EstadoImpugnacion.nombre)+" ")])],1)]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('card-download-component',{attrs:{"title":"Recurso de revisión","archivo":{
              nombre: _vm.impugnacion?.TipoImpugnacion?.nombre,
              ruta: _vm.impugnacion?.doc_impugnacion,
            }}})],1),(_vm.buffer_documento)?_c('v-col',{attrs:{"cols":"12"}},[_c('embed',{attrs:{"src":_vm.buffer_documento,"type":"application/pdf","width":"100%","height":"600px"}})]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
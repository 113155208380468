<template>
  <section>
    <v-row align="center">
      <v-col cols="12" sm="12" md="6">
        <p class="text-h5 secondary--text font-weight-bold">
          {{ etapa_actual?.nombre_etapa }}
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-row align="center">
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de Inicio:
              {{ date_etapa.inicio }}
            </p>
          </v-col>
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de Fin:
              {{ date_etapa?.fin }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <admision-rec-rev-component v-if="etapa_actual?.id == 1" />
    <resolucion-rec-rev-component v-if="etapa_actual?.id == 2" />

    <presentacion-rec-ape-component v-if="etapa_actual?.id == 3" />
    <admision-rec-ape-component v-if="etapa_actual?.id == 4" />
    <resolucion-rec-ape-component v-if="etapa_actual?.id == 5" />
  </section>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import AdmisionRecApeComponent from "./componentsProv/AdmisionRecApeComponent.vue";
import AdmisionRecRevComponent from "./componentsProv/AdmisionRecRevComponent.vue";
import PresentacionRecApeComponent from "./componentsProv/PresentacionRecApeComponent.vue";
import ResolucionRecApeComponent from "./componentsProv/ResolucionRecApeComponent.vue";
import ResolucionRecRevComponent from "./componentsProv/ResolucionRecRevComponent.vue";

export default {
  name: "ProveedorEtapaActualComponent",

  components: {
    AdmisionRecApeComponent,
    AdmisionRecRevComponent,
    PresentacionRecApeComponent,
    ResolucionRecApeComponent,
    ResolucionRecRevComponent,
  },

  data: () => ({
    etapa_actual: {},
    fecha_resolucion: null,
    date_etapa: {
      inicio: null,
      fin: null,
    },
  }),

  methods: {
    ...mapActions("procesoCompraDoc", ["getEtapaActualProcesoImpugnacion"]),
    ...mapMutations("procesoCompraDoc", ["setFechaResolucion"]),

    formatDate(data) {
      this.date_etapa.inicio = this.moment(data?.fecha_inicio).format(
        "DD/MM/YYYY HH:mm"
      );
      this.date_etapa.fin = this.moment(data?.fecha_fin).format(
        "DD/MM/YYYY HH:mm"
      );
    },

    async FetchEtapaActual() {
      const data = await this.getEtapaActualProcesoImpugnacion({
        id_proceso_compra: this.$route.params.idProceso,
      });
      this.etapa_actual = data?.CtlEtapasProcesoImpugnacion;
      this.formatDate(data);
      if (data?.id_etapa_proceso_impugnacion == 1) {
        // admision de recurso de revision
        this.DetailAdmisionRec(2);
      } else if (data?.id_etapa_proceso_impugnacion == 4) {
        // admision de recurso de apelacion
        this.DetailAdmisionRec(5);
      }
    },

    async DetailAdmisionRec(id_etapa) {
      const { status, data } = await this.services.PacProcesos.getDetailEtapa(
        this.$route.params.idProceso,
        id_etapa
      );

      if (status === 200) {
        this.setFechaResolucion(data?.fecha_fin);
      }
    },
  },

  created() {
    this.FetchEtapaActual();
  },
};
</script>
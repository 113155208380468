<template>
  <v-expansion-panels class="my-3">
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{ proveedorProp.proveedor }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <data-table-component
          class="mb-3"
          :headers="HEADERS_CONST"
          :items="list_docs_proveedor"
          inhabilitar_paginacion
          :tiene_paginacion="false"
        >
          <template v-slot:[`item.date`]="{ item }">
            {{ FormatDatePrt(item.date, true) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="
                    descargarArchivoProceso({
                      ruta_documento: item.url,
                    })
                  "
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Descargar</span>
            </v-tooltip>
          </template>
        </data-table-component>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import { mapActions } from "vuex";

export default {
  name: "CardProveedorIMPComponent",

  components: {
    DataTableComponent,
  },

  props: {
    proveedorProp: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    // constants
    HEADERS_CONST: [
      { text: "Tipo de documento", value: "tipo_doc" },
      { text: "Cargado por", value: "user" },
      { text: "Fecha de subida", value: "date" },
      { text: "Acciones", value: "actions", sortable: false },
    ],

    list_docs_proveedor: [],
  }),

  methods: {
    ...mapActions("procesoCompraDoc", ["descargarArchivoProceso"]),
    initFtn() {
      this.list_docs_proveedor = this.proveedorProp.recursos.flatMap(
        ({ TipoImpugnacion, Usuario, created_at, doc_impugnacion }) => ({
          tipo_doc: TipoImpugnacion.nombre,
          user: Usuario.nombre_usuario,
          date: created_at,
          url: doc_impugnacion,
        })
      );

      this.proveedorProp.recursos.forEach((objeto) => {
        objeto.actas.forEach((acta) => {
          this.list_docs_proveedor.push({
            tipo_doc: acta.EstadoImpugnacion.nombre,
            user: acta.Usuario.nombre_usuario,
            date: acta.created_at,
            url: acta.doc_impugnacion,
          });
        });

        objeto.pronunciamientos.forEach((pronunciamiento) => {
          this.list_docs_proveedor.push({
            tipo_doc: pronunciamiento.TipoDocumentoProceso.nombre,
            user: pronunciamiento.Usuario.nombre_usuario,
            date: pronunciamiento.created_at,
            url: pronunciamiento.ruta_documento,
          });
        });
      });
    },
  },

  created() {
    this.initFtn();
  },
};
</script>
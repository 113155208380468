<template>
  <v-row no-gutters>
    <v-col
      v-if="mostrarAlerta"
      cols="12"
      md="12"
      class="d-flex align-center justify-center"
    >
      <v-alert type="info">
        <!-- El lote seleccionado se ha construido con
        {{ solicitudes_lote?.length }} insumos, por lo que se procederá a
        reorganizar en esa misma cantidad de lotes y subastas -->
        Los insumos de cada lote serán separados en virtud de la modalidad de la subasta seleccionada
      </v-alert>
    </v-col>
    <v-col
      v-if="mostrarAlerta"
      cols="12"
      md="12"
      class="d-flex align-center justify-center"
    >
      <v-switch
        v-model="subasta_unitaria.rehacer_lotes"
        color="secondary"
        inset
        label="¿Desea proceder?"
      ></v-switch>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "UnitariaAlerta",
  computed: {
    ...mapState("configuracionSalas", [
      "solicitudes_lote",
      "form_enviar",
      "subasta_unitaria",
    ]),
    mostrarAlerta() {
      return this.form_enviar?.ctlSubasta?.tipo_operacion === "resta_unitaria";
    },
  },
};
</script>

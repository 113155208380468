<template>
  <v-card>
    <v-card-title>
      Ganadores de adjudicación
    </v-card-title>
    <v-card-subtitle>
      Esta sección permite adjudicar los ganadores de las subastas.
    </v-card-subtitle>
    <v-card-text>
      <v-alert type="info">
        <span class=" text-h6 mx-2"> Ganadores: </span>
      </v-alert>
      <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="-1"
          hide-default-footer
          no-data-text="No hay proveedores ganadores"
      >
        <template #[`item.monto`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.monto)
          }}
        </template>
        <template #[`item.notificado`]="{ item }">
        {{ item.notificado ? 'Notificaciones enviadas' : 'Pendiente de notificar'}}
        </template>
        <template #[`item.adjudicado`]="{ item }">
         {{ item.adjudicado ? 'Adjudicación realizada' : 'Pendiente de adjudicar' }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "PublicacionResultadoSubasta",
  data: () => ({
    headers:[
      {
        text: 'Proveedor',
        value: 'nombre',
      },
      {
        text: 'Nombre comercial',
        value: 'nombre_comercial',
      },
      {
        text: 'Lote',
        value: 'lote',
      },
      {
        text: 'Monto',
        value: 'monto',
      },
      {
        text: 'Estado adjudicación',
        value: 'adjudicado',
      },
      {
        text: 'Estado notificación por correo',
        value: 'notificado',
      }
    ],
  }),
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>


<style lang="scss" scoped>

:deep(
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > thead
      > tr:last-child
      > th.sortable
  ) {
  background: transparent !important;
}

:deep(.theme--light.v-data-table > .v-data-table__wrapper > table) {
  border: 1px solid #175efb !important;
}

:deep(.v-data-table-header tr th span) {
  color: #175efb !important;
}

:deep(
    .theme--light.v-data-table
      .v-data-table-header
      th
      .v-data-table-header__icon
  ) {
  color: #175efb !important;
}

:deep(
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr:last-child
      > td
  ) {
  background: transparent !important;
  border-top: 1px solid #175efb !important;
}

</style>
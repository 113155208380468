<template>
  <section class="ma-8">
    <data-table-component
      :headers="headersFiltered"
      :items="etapas"
      :tiene_paginacion="false"
    >
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip right left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              small
              @click="configurarEtapa(item), (showModalConfig = true)"
            >
              <v-icon color="secondary">mdi-cog</v-icon>
            </v-btn>
          </template>
          <span> Editar etapa</span>
        </v-tooltip>
      </template>
    </data-table-component>
    <v-dialog v-model="showModalConfig" max-width="900px" persistent>
      <v-card height="500" class="pt-2 px-5" style="z-index: 10000 !important">
        <v-card-title>
          <p class="text-h6 secondary--text">{{ etapa?.nombre_etapa }}</p>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <vc-date-picker
                v-model="fecha_inicio"
                mode="dateTime"
                :popover="config_calendar"
                :min-date="fecha_actual"
                :max-date="etapas.fecha_inicio ? fecha_maxima : ''"
                :valid-hours="horas_disponibles_inicio"
                @dayclick="horasDisponibles(1)"
                :minute-increment="5"
              >
                <template v-slot="{ inputEvents }">
                  <v-text-field
                    class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
                    label="Fecha y hora de inicio *"
                    prepend-inner-icon="mdi-calendar"
                    :value="getFechaInicio"
                    readonly
                    v-on="inputEvents"
                    outlined
                    :error-messages="fechaInicioErrorMessage"
                    @input="$v.fecha_inicio.$touch()"
                    @blur="$v.fecha_inicio.$touch()"
                    @click="horasDisponibles(1)"
                  />
                </template>
              </vc-date-picker>
            </v-col>
            <v-col cols="12" md="6">
              <vc-date-picker
                v-model="fecha_fin"
                mode="dateTime"
                locale="es"
                :popover="config_calendar"
                :min-date="getMinDate"
                :max-date="etapas.fecha_inicio ? fecha_maxima : ''"
                :valid-hours="horas_disponibles_fin"
                @dayclick="horasDisponibles(2)"
                :minute-increment="5"
              >
                <template v-slot="{ inputEvents }">
                  <v-text-field
                    class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
                    label="Fecha y hora de fin *"
                    prepend-inner-icon="mdi-calendar"
                    :value="getFechaFin"
                    v-on="inputEvents"
                    outlined
                    readonly
                    :error-messages="fechaFinErrorMessage"
                    @input="$v.fecha_fin.$touch()"
                    @blur="$v.fecha_fin.$touch()"
                    @click="horasDisponibles(2)"
                  />
                </template>
              </vc-date-picker>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-8">
          <v-row class="display-flex justify-end">
            <v-col cols="2">
              <v-btn
                class="mb-5"
                color="secondary"
                text
                @click="(showModalConfig = false), clean()"
                >Cerrar</v-btn
              >
            </v-col>
            <v-col cols="2">
              <v-btn
                class="mb-5"
                color="secondary"
                @click="guardarConfiguracion"
                >Guardar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import moment from "moment";
import { required, requiredIf, maxLength } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import DataTableComponent from "../../../../components/DataTableComponent.vue";

function dateValidation() {
  return (
    moment(this.fecha_inicio).format("YYYY-MM-DD HH:mm") <
      moment(this.fecha_fin).format("YYYY-MM-DD HH:mm") ||
    this.disableFechas == true
  );
}
export default {
  components: { DataTableComponent },
  data: () => ({
    headers: [
      {
        text: "Etapa",
        value: "nombre_etapa",
      },
      {
        text: "Fecha de inicio",
        value: "fecha_inicio",
      },
      {
        text: "Fecha fin",
        value: "fecha_fin",
      },
      {
        text: "Cantidad de días",
        value: "cantidad_dias",
        align: "center",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
    etapas: [],
    etapa: null,
    showModalConfig: false,
    fecha_inicio: null,
    fecha_fin: null,
    config_calendar: {
      visibility: "click",
    },
    fecha_actual: "",
    fecha_minima: "",
    fecha_maxima: "",
    id_phase: "",
    phase_name: "",
    horas_disponibles_inicio: [],
    horas_disponibles_fin: [],
    showModal: false,
    showModalEtapa3: false,
    id_etapa: null,
  }),
  validations: {
    fecha_inicio: {
      required: requiredIf(function () {
        return this.showModalConfig;
      }),
      dateValidation,
    },
    fecha_fin: {
      required: requiredIf(function () {
        return this.showModalConfig;
      }),
      dateValidation,
    },
  },
  watch: {
    fecha_inicio() {
      this.getFechaInicio;
    },
    fecha_fin() {
      this.getFechaFin;
    },
  },
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
    headersFiltered() {
      if (
        this.haveRoles([
          "ROLE_UACI_TECNICO",
          "ROLE_UACI",
          "ROLE_DIRECCION_COMPRAS",
        ])
      ) {
        return this.headers;
      } else {
        return this.headers.filter((target) => target.value !== "acciones");
      }
    },
    getFechaInicio() {
      return this.fecha_inicio
        ? moment(this.fecha_inicio, "YYYY-MM-DD HH:mm").format(
            "DD/MM/YYYY hh:mm A"
          )
        : "";
    },
    getMinDate() {
      return this.fecha_inicio
        ? moment(this.fecha_inicio, "YYYY-MM-DD HH:mm").format(
            "YYYY-MM-DD HH:mm"
          )
        : "";
    },
    getFechaFin() {
      return this.fecha_fin
        ? moment(this.fecha_fin, "YYYY-MM-DD HH:mm").format(
            "DD/MM/YYYY hh:mm A"
          )
        : "";
    },
    fechaInicioErrorMessage() {
      const errors = [];
      if (!this.$v.fecha_inicio.$dirty) return errors;
      !this.$v.fecha_inicio.required &&
        errors.push("Debe seleccionar fecha de inicio");
      !this.$v.fecha_inicio.dateValidation &&
        errors.push("La fecha de inicio debe ser menor a la fecha de fin");
      return errors;
    },
    fechaFinErrorMessage() {
      const errors = [];
      if (!this.$v.fecha_fin.$dirty) return errors;
      !this.$v.fecha_fin.required &&
        errors.push("Debe seleccionar fecha de finalización");
      !this.$v.fecha_fin.dateValidation &&
        errors.push("La fecha de fin debe ser mayor a la fecha de inicio");
      return errors;
    },
  },
  methods: {
    ...mapActions("procesoCompraDoc", ["getEtapasProcesoImpugnacion"]),
    async getEtapasImpugnaciones() {
      const response = await this.getEtapasProcesoImpugnacion({
        id_proceso_compra: this.$route.params.idProceso,
        id_etapa_proceso: this.procesoData.id_etapa_pausa,
      });
      this.etapas = response.map((item) => {
        let cantidadDias = 0;
        if (item.fecha_inicio && item.fecha_fin) {
          const fechaInicio = moment(item.fecha_inicio);
          const fechaFin = moment(item.fecha_fin);

          cantidadDias = fechaFin.diff(fechaInicio, "days");
        }
        return {
          ...item,
          nombre_etapa: item.CtlEtapasProcesoImpugnacion?.nombre_etapa,
          fecha_inicio: this.moment(item.fecha_inicio).format(
            "DD/MM/YYYY HH:mm A"
          ),
          fecha_fin: this.moment(item.fecha_fin).format("DD/MM/YYYY HH:mm A"),
          cantidad_dias: cantidadDias,
        };
      });
    },
    getTotalDias() {},
    clean() {
      this.etapa = null;
      this.fecha_inicio = null;
      this.fecha_fin = null;
      this.$v.fecha_inicio.$reset();
      this.$v.fecha_fin.$reset();
    },
    horasDisponibles(n) {
      switch (n) {
        case 1:
          this.horas_disponibles_inicio = [];
          if (
            this.fecha_minima !==
            moment(this.fecha_inicio).format("YYYY-MM-DD HH:mm")
          ) {
            for (let i = 0; i <= 23; i++) {
              this.horas_disponibles_inicio.push(Number(i));
            }
          } else {
            const hora = this.fecha_minima.split(" ")[1].split(":")[0];
            for (let i = Number(hora); i <= 23; i++) {
              this.horas_disponibles_inicio.push(Number(i));
            }
            this.horas_disponibles_inicio.push(Number(0));
          }
          break;
        case 2:
          this.horas_disponibles_fin = [];
          if (
            moment(this.fecha_inicio).format("YYYY-MM-DD") !==
            moment(this.fecha_fin).format("YYYY-MM-DD")
          ) {
            for (let i = 0; i <= 23; i++) {
              this.horas_disponibles_fin.push(Number(i));
            }
          } else {
            const hora = moment(this.fecha_inicio)
              .format("YYYY-MM-DD HH:mm")
              .split(" ")[1]
              .split(":")[0];
            const min = moment(this.fecha_inicio)
              .format("YYYY-MM-DD HH:mm")
              .split(" ")[1]
              .split(":")[1];
            if (min > 30) {
              for (let i = Number(hora); i <= 23; i++) {
                this.horas_disponibles_fin.push(Number(i));
              }
            } else {
              for (let i = Number(hora); i <= 23; i++) {
                this.horas_disponibles_fin.push(Number(i));
              }
            }
            this.horas_disponibles_fin.push(Number(0));
          }
          break;
        default:
          break;
      }
    },
    obtenerFechaMinima(item) {
      const i = this.etapas.indexOf(item);
      if (i !== 0) {
        if (this.etapas[i - 1].fecha_fin !== null) {
          const date = new Date(this.etapas[i - 1].fecha_inicio);
          date.setDate(date.getDate());
          this.fecha_minima = moment(
            this.etapas[i - 1].fecha_inicio,
            "DD-MM-YYYY HH:mm"
          ).format("YYYY-MM-DD HH:mm");
          const hora = this.fecha_minima.split(" ")[1].split(":")[0];
          this.horas_disponibles_inicio = [];
          if (
            this.fecha_minima !==
            moment(this.fecha_inicio).format("YYYY-MM-DD HH:mm")
          ) {
            for (let i = 0; i <= 23; i++) {
              this.horas_disponibles_inicio.push(Number(i));
            }
          } else {
            for (let i = Number(hora); i <= 23; i++) {
              this.horas_disponibles_inicio.push(Number(i));
            }
            this.horas_disponibles_inicio.push(Number(0));
          }
        }
      }
    },
    obtenerFechaMaxima(item) {
      const i = this.etapas.indexOf(item);
      if (this.etapas[i].fecha_inicio !== null) {
        const date = new Date(this.etapas[i].fecha_inicio);

        date.setDate(date.getDate());
        this.fecha_maxima = moment(date, "DD-MM-YYYY HH:mm ").format(
          "YYYY-MM-DD HH:mm "
        );
        this.horas_disponibles_fin = [];
        if (
          moment(this.fecha_inicio).format("YYYY-MM-DD HH:mm") !==
          moment(this.fecha_fin).format("YYYY-MM-DD HH:mm")
        ) {
          for (let i = 0; i <= 23; i++) {
            this.horas_disponibles_fin.push(Number(i));
          }
        } else {
          const hora = moment(this.fecha_inicio)
            .format("YYYY-MM-DD HH:mm")
            .split(" ")[1]
            .split(":")[0];
          const min = moment(this.fecha_inicio)
            .format("YYYY-MM-DD HH:mm")
            .split(" ")[1]
            .split(":")[1];
          if (min > 30) {
            for (let i = Number(hora) + 1; i <= 23; i++) {
              this.horas_disponibles_fin.push(Number(i));
            }
          } else {
            for (let i = Number(hora); i <= 23; i++) {
              this.horas_disponibles_fin.push(Number(i));
            }
          }
          this.horas_disponibles_fin.push(Number(0));
        }
      }
    },
    configurarEtapa(item) {
      this.id_phase = this.etapas.indexOf(item);
      this.phase_name = item.nombre_etapa;
      this.id_etapa = item.id;
      this.etapa = item;

      this.obtenerFechaMinima(item);
      if (item.fecha_inicio !== null && item.fecha_fin !== null) {
        this.fecha_inicio = null;
        this.fecha_fin = null;

        this.fecha_inicio = this.moment(
          item.fecha_inicio,
          "DD-MM-YYYY HH:mm A"
        ).format("YYYY-MM-DD hh:mm A");

        this.fecha_fin = this.moment(
          item.fecha_fin,
          "DD-MM-YYYY HH:mm A"
        ).format("YYYY-MM-DD hh:mm A");
        this.obtenerFechaMaxima(item);
        if (this.id_phase !== this.etapas.length) {
        }
      }

      if (item.fecha_inicio === "" || item.fecha_fin === "") {
        this.fecha_inicio = null;
        this.fecha_fin = "";
        if (this.id_phase !== 0) {
          let currentIndex = this.etapas.indexOf(item);
          let fechaEtapaAnterior = this.etapas[currentIndex - 1].fecha_fin;
          let fechaInicio = moment(fechaEtapaAnterior, "DD-MM-YYYY HH:mm A");
          this.fecha_inicio = moment(fechaInicio).add(1, "minutes");
        }
      }
    },
    async guardarConfiguracion() {
      if (this.fecha_inicio === null || this.fecha_fin === null) {
        this.temporalAlert({
          show: true,
          type: "warning",
          message: "Rellene ambas fechas",
        });

        return;
      }

      if (!this.$v.$invalid) {

        const response =
          await this.services.PacProcesos.updateEtapasImpugnaciones(
            Number(this.$route.params.idProceso),
            Number(this.id_etapa),
            {
              fecha_inicio: moment(this.fecha_inicio).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              fecha_fin: moment(this.fecha_fin).format("YYYY-MM-DD HH:mm:ss"),
            }
          ).catch(() => {

          });

        if (response.status === 200) {
          setTimeout(() => {
            this.temporalAlert({
              show: true,
              type: "success",
              message: "Etapa de proceso actualizada",
            });

          }, 1500);

          this.showModalConfig = false;
          let e = this.etapas.find((target) => target.id == this.id_etapa);
          let index = this.etapas.indexOf(e);

          this.etapas[index].fecha_inicio = moment(this.fecha_inicio).format(
            "DD/MM/YYYY HH:mm A"
          );
          this.etapas[index].fecha_fin = moment(this.fecha_fin).format(
            "DD/MM/YYYY HH:mm A"
          );

          this.etapas[index].cantidad_dias = moment(this.fecha_fin).diff(
            moment(this.fecha_inicio),
            "days"
          );

          this.clean();
        }
      }
    },
  },
  created() {
    this.getEtapasImpugnaciones();
  },
};
</script>

<style>
</style>
<template>
    <div class="d-flex justify-end align-center my-4" style="gap: 16px;">
        <span class="d-block" style="width: 320px !important;">
            <v-select
                v-model="estadoSeleccionado"
                :loading="estados.isLoading"
                :items="estados.data"
                label="Estado"
                item-text="nombre"
                item-value="id"
                outlined
                hide-details
                dense
            />
        </span>
        <v-btn @click.stop="confirmarCambioEstado" :disabled="!estadoSeleccionado" color="primary">
            Cambiar estado
        </v-btn>
        <ConfirmationModalComponent 
            :is-open="confirmacionAbierta"
            description="¿Desea cambiar el estado del proceso?"
            :is-loading="cambioEstado.isLoading"
            @confirm="cambiarEstado"
            @cancel="confirmacionAbierta = false"
        />
    </div>
</template>
<script>
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
import { ConfirmationModalComponent } from '@/components/utils';
import { Fragment } from 'vue-fragment';

export default {
    name: 'CambioEstadoProceso',
    components: { Fragment, ConfirmationModalComponent },
    emits: ['on-cambio-estado'],
    props: {
        idProceso: { type: Number },
    },
    data: () => ({
        items: [],
        estadoSeleccionado: null,
        estados: createLoadable([]),
        cambioEstado: createLoadable(null),
        confirmacionAbierta: false,
    }),
    methods: {
        confirmarCambioEstado() {
            this.confirmacionAbierta = true;
        },
        async cargarListaDeEstados() {
            toggleLoadable(this.estados);
            const { data } = await this.services.Paac.cargarEstadosProceso();
            setLoadableResponse(this.estados, data, { showAlertOnSuccess: false  });
        },
        async cambiarEstado() {
            toggleLoadable(this.cambioEstado);
            const { data } = await this.services.Paac.cambiarEstadoProceso(this.idProceso, { id_estado: this.estadoSeleccionado });
            setLoadableResponse(this.cambioEstado, data, { skipOnSuccess: true, showAlertOnSuccess: true });
            this.confirmacionAbierta = false;

            if (!isResponseSuccesful(data)) return;
            this.$emit('on-cambio-estado');
        },
    },
    created() {
        this.cargarListaDeEstados();
    },
};
</script>
<template>
  <section>
    <v-divider class="mt-4 mb-6"></v-divider>
    <h5 class="text-h5 secondary--text mb-6">Certificación de fondos</h5>
    <v-row v-if="permisos.escribir">
      <v-col cols="12" sm="4">
        <v-file-input
          v-model="archivo"
          outlined
          prepend-icon=""
          placeholder="Seleccione un archivo"
          label="Archivo *"
          @input="$v.archivo.$touch()"
          @blur="$v.archivo.$touch()"
          :error-messages="archivoError"
          prepend-inner-icon="mdi-paperclip"
          accept="application/pdf"
        />
      </v-col>
      <v-col cols="12" sm="3" md="2">
        <v-btn
          block
          large
          class="mt-2"
          color="secondary"
          @click="guardarArchivo"
          ><v-icon color="white">mdi-content-save</v-icon> Guardar</v-btn
        >
      </v-col>
    </v-row>

    <v-data-table
      :items="items"
      :headers="headers"
      hide-default-footer
      v-if="permisos.leer"
    >
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              icon
              v-on="on"
              v-bind="attrs"
              color="secondary"
              @click="descargarArchivo(item)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Descargar archivo</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import { helpers, required } from "vuelidate/lib/validators";
import LegacyValidations from "@/utils/legacy-validations";

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: 'AsignacionCdfComponent',  
  props: {
    etapa: {
      type: Object,
      default: {},
    },
    permisos: {
      type: Object,
      default: {},
    },
    extraParams: {
      type: Object,
    },
  },
  data: () => ({
    headers: [
      {
        text: "Documento",
        value: "nombre_documento",
      },
      {
        text: "Fecha de carga",
        value: "fecha_carga",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
      },
    ],
    items: [],
    archivo: null,
  }),
  validations: {
    archivo: {
      required,
      fileTypeValid,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },
  computed: {
    archivoError() {
      const errors = [];
      if (!this.$v.archivo.$dirty) return errors;
      !this.$v.archivo.required && errors.push("El archivo es requerido");
      !this.$v.archivo.fileTypeValid &&
        errors.push("El archivo debe ser de tipo PDF");
      !this.$v.archivo.fileNameValid &&
        errors.push(
          "El nombre del archivo no debe contener caracteres especiales"
        );
      !this.$v.archivo.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);          
      return errors;
    },
  },
  methods: {
    async guardarArchivo() {
      this.$v.$touch();
      if (!this.$v.$invalid) {

        let formData = new FormData();
        formData.append("documento", this.archivo);
        formData.append("id_proceso", this.$route.params.idProceso);
        formData.append("id_tipo_documento", 1000);
        formData.append("folder", "cdf");

        const response =
          await this.services.PacProcesos.guardarDocumentosProceso(
            formData
          ).catch(() => {

          });

        if (response.status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Documento cargado correctamente",
          });
          this.getDocumentos();
          this.archivo = null;
          this.nombre = null;
          this.$v.$reset();
        }


      }
    },
    async getDocumentos() {
      if (!this.permisos.leer) return;



      let response = null;
      response = await this.services.PacProcesos.obtenerDocumentosProceso(
        this.$route.params.idProceso,
        1000,
        {
          ...this.addExtraParams(),
        }
      ).catch(() => {

      });

      if (response.status === 200) {
        this.items = response.data;
      }


    },
    addExtraParams() {
      return this.extraParams ?? {};
    },
    async descargarArchivo(item) {

      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: item.ruta_documento,
        })
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });

        return;
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], item.nombre_documento, {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },
  },
  created() {
    this.getDocumentos();
  },
}
</script>
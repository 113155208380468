<template>
  <!-- Técnica:   10,12 -->
  <!-- Económica: 4,5 -->
  <!-- Combinada: 11,13,20 -->
  <div class="d-flex">
    <v-btn v-if="[4, 5].includes(tipoEtapa) || [11, 13, 20].includes(tipoEtapa)"
      @click="(idTipoOferta = 2), (showModalAgregar = true)" dark class="mr-4" color="secondary">
      Agregar oferta económica
    </v-btn>
    <v-btn v-if="[10, 12].includes(tipoEtapa) || [11, 13, 20].includes(tipoEtapa)"
      @click="(idTipoOferta = 1), (showModalAgregar = true)" dark color="secondary">
      Agregar oferta técnica
    </v-btn>
    <app-dialog :max-width="1200" v-model="showModalAgregar" title="Agregar oferta de proveedor" @close="closeModal">
      <template #content>
        <div>
          <p>
            Digite el nombre comercial de la empresa a la que se desea invitar
          </p>
        </div>
        <!-- Autocomplete para buscar proveedores -->
        <v-row>
          <v-col cols="12">
            <v-autocomplete clearable :items="lista_proveedores_OTRO" outlined item-text="nombre_completo" return-object
              :search-input.sync="nombre_comercial_model" label="Nombre comercial" @keyup="getListadoProveedores"
              v-model="proveedor_model" />
          </v-col>
        </v-row>

        <v-row v-if="proveedor_model != null">
          <v-col cols="12">
            <InformacionProvedor :providerData="proveedor_model" />
          </v-col>
        </v-row>
      </template>

      <template #actions>
        <div class="mt-3">
          <v-btn color="secondary" @click="closeModal" class="mr-4" outlined>
            Cerrar
          </v-btn>
          <v-btn v-if="proveedor_model != null" color="secondary" dark @click="agregarProveedor"
            :loading="loadingProveedor">
            Agregar
          </v-btn>
        </div>
      </template>
    </app-dialog>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import InformacionProvedor from "@/components/InfomacionProveedorComponent.vue";
export default {
  name: "OfertaUCPComponent",
  components: {
    InformacionProvedor,
  },
  props: {
    tipoEtapa: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    showModalAgregar: false,
    loadingProveedor: false,
    idTipoOferta: null,
  }),
  computed: {
    ...mapState("procesoCompra", [
      "lista_proveedores",
      "nombre_comercial",
      "proveedor_invitar",
    ]),
    proveedor_model: {
      get() {
        return this.proveedor_invitar;
      },
      set(value) {
        this.setProveedor(value);
      },
    },
    nombre_comercial_model: {
      get() {
        return this.nombre_comercial;
      },
      set(value) {
        this.setNombreComercial(value);
      },
    },
    lista_proveedores_OTRO: function () {
      return this.lista_proveedores.map((proveedor) => ({
        ...proveedor,
        nombre_completo: `${proveedor.nombre_comercial} - ${proveedor.nombre}`,
      }));
    },
  },
  methods: {
    ...mapActions("procesoCompra", [
      "getListadoProveedores",
      "setNombreComercial",
      "setProveedor",
      "invitarProveedor",
    ]),
    async agregarProveedor() {
      try {
        this.loadingProveedor = true;
        const params = {
          correo: this.proveedor_invitar.email,
        };
        const response = await this.services.Paac.postInvitarProveedor(
          this.$route.params.idProceso,
          params
        );

        if (response.status == 200) {
          this.proveedor_model = null;

          this.showModalInvitar = false;
          //redirect to provider's offer page

          this.$router.push({
            name: "pac-procesos-ofertas",
            params: {
              idProceso: this.$route.params.idProceso,
              idTipoOferta: this.idTipoOferta,
              idParticipacionProveedor:
                response.data?.participacion_proveedor?.id,
            },
          });
        }
      } finally {
        this.loadingProveedor = false;
      }
    },
    closeModal() {
      this.showModalAgregar = false;
      this.resetModal();
    },
    resetModal() {
      this.nombre_comercial_model = '';
      this.proveedor_model = null;
      this.lista_proveedores_OTRO = [];
    },
  },
};
</script>

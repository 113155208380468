<template>
  <section>
    <v-row align="center" v-if="showHeader">
      <v-col cols="12" sm="12" md="6">
        <p class="text-h5 secondary--text font-weight-bold">
          {{ etapa?.CtlEtapasProcesoImpugnacion?.nombre_etapa }}
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-row align="center">
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de Inicio:
              {{ moment(etapa?.fecha_inicio).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de Fin:
              {{ moment(etapa?.fecha_fin).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p class="text-h5 secondary--text">
          Proveedores adjudicados después de resolución
        </p>
        <AddGanadorComponent />
      </v-col>
    </v-row>
    <br />
    <v-row>
      <v-col cols="12">
        <p class="text-h5 secondary--text">
          Reprogramación de etapas restantes
        </p>
        <PipProcesoComponent :action="getSiguienteEtapa" :etapas="etapas" />
      </v-col>
    </v-row>
    <br />
    <v-row class="mx-1 my-6">
      <v-btn @click="continuarProceso" color="secondary" dark>
        <v-icon class="mr-2">mdi-play-circle</v-icon>
        Continuar proceso
      </v-btn>
    </v-row>
  </section>
</template>
<script>
import DataTable from "@/components/DataTableComponent.vue";
import AddGanadorComponent from "./AddGanadorComponent.vue";
import PipProcesoComponent from "../../../components/PipProcesoComponent.vue";
export default {
  name: "ResolucionImpugnacionesComponent",
  components: {
    DataTable,
    AddGanadorComponent,
    PipProcesoComponent,
  },
  props: {
    etapa: {
      type: Object,
      default: () => {},
    },
    showHeader: {
      type: Boolean,
      default: true,
    }
  },
  data: () => ({
    etapas: [],
  }),
  methods: {
    async getSiguienteEtapa() {
      const { status, data } =
        await this.services.RecursoRevision.getEtapaSiguiente(
          this.$route.params.idProceso
        );

      if (status == 200) {
        this.etapas = data;
      }
    },
    async continuarProceso() {
      const { status } = await this.services.RecursoRevision.continuarProceso(
        this.$route.params.idProceso,
        1
      );

      if (status == 204) {
        location.reload();
      }
    },
  },
  created() {
    this.getSiguienteEtapa();
  },
};
</script>

<template>
  <v-col cols="12" md="8" class="ml-6" v-if="subastasProximas?.length > 0">
    <v-row>
      <v-col cols="12" md="12">
        <h4>Siguiente articulo:</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="pr-8"
        cols="12"
        md="4"
        v-for="(subasta, index) in subastasProximas"
        :key="index"
      >
        <span class="text-h6 secondary--text mt-4">
          {{ subasta?.nombre_lote }}
        </span>
        <span class="mt-12">
          <app-countdown
            titulo=""
            color="info--text"
            :date-now="moment(serverTime, 'x')"
            :nextDay="moment(subasta.fecha_hora_inicio)"
            @status="$emit('cierreSala')"
          />
        </span>
        <span class="mt-2">
          {{ moment(subasta.fecha_hora_inicio).format("DD/MM/YYYY HH:mm a") }}
        </span>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import moment from "moment-timezone";
import AppCountdown from "@/components/AppCountdown.vue";
import { mapState } from "vuex";

export default {
  name: "siguientesSubastas",
  props: {
    subastasProximas: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    AppCountdown,
  },
  computed: {
    ...mapState(["serverTime"]),
  },
};
</script>

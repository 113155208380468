<template>
  <section>
    <v-row>
      <v-col cols="12" sm="6" lg="4">
        <v-card class="pa-4">
          <v-row class="align-center">
            <v-col cols="8" md="10">
              <h5 class="text-h6">Ofertas técnicas recibidas</h5>
              <h4 class="text-h3">{{ cantidadOfertas.tecnicas }}</h4>
            </v-col>
            <v-col cols="4" md="2" class="text-center">
              <v-icon size="50" color="primary">mdi-file-document-outline</v-icon>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" lg="4">
        <v-card class="pa-4">
          <v-row class="align-center">
            <v-col cols="8" md="10">
              <h5 class="text-h6">Ofertas económicas recibidas</h5>
              <h4 class="text-h3">{{ cantidadOfertas.economicas }}</h4>
            </v-col>
            <v-col cols="4" md="2" class="text-center">
              <v-icon size="50" color="primary">mdi-currency-usd</v-icon>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-divider class="my-4 mb-8" />

    <h5 class="secondary--text text-h5 mb-4">Acta de apertura</h5>

    <AdminDocumentosComponent
      :etapa="etapa"
      :permisos="permisos"
    />
  </section>
</template>

<script>
import AdminDocumentosComponent from "./AdminDocumentosComponent.vue";

export default {
  name: "AperturaOfertasNotificacionComponent",
  components: {
    AdminDocumentosComponent,
  },
  data: () => ({
    cantidadOfertas: 0,
  }),
  props: {
    etapa: {
      type: Object,
      default: {},
    },
    permisos: {
      type: Object,
      default: {},
    },
    extraParams: {
      type: Object,
    },
    showForm: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    async getCantidadOfertas() {


      const response = await this.services.PacProcesos.getCantidadOfertasProceso(this.$route.params.idProceso)

      if (response.status === 200) {
        this.cantidadOfertas = response.data
      }


    }
  },
  async created() {
    await this.getCantidadOfertas();
  }
}
</script>
()<template>
  <section>
    <v-row align="center">
      <v-col cols="12" sm="12" md="6">
        <p class="text-h5 secondary--text font-weight-bold">
          {{ etapa?.CtlEtapasProcesoImpugnacion?.nombre_etapa }}
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-row align="center">
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de Inicio:
              {{ moment(etapa?.fecha_inicio).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de Fin:
              {{ moment(etapa?.fecha_fin).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <!--Comisión de Alto Nivel -->
      <v-col cols="12" sm="12" md="6">
        <p class="text-h6 font-weight-bold">Comisión de Alto Nivel</p>
        <v-btn dark color="secondary" class="mb-6" @click="showAddParticipante = true">Agregar participante</v-btn>
        <DataTable :headers="headersAltoN" :items="itemsAltoN" :tiene_paginacion="false">
        </DataTable>
      </v-col>
      <!--Comisión de Alto Nivel -->

      <!--Actos de trámite -->
      <v-col cols="12" sm="12" md="6">
        <p class="text-h6 font-weight-bold">Actos de trámite</p>
        <div class="d-flex">
          <v-col cols="12" sm="12" md="5">
            <v-text-field outlined v-model="nombreActoTramite" label="Nombre archivo" />
          </v-col>
          <v-col cols="12" sm="12" md="5">
            <v-file-input outlined v-model="actoTramiteFile" prepend-icon="" accept="application/pdf"
              prepend-inner-icon="mdi-paperclip" label="Archivo adjunto" truncate-length="15" />
          </v-col>
          <v-col cols="12" sm="12" md="2">
            <v-btn v-if="nombreActoTramite && actoTramiteFile" dark color="secondary"
              @click="saveArchivo(15000, nombreActoTramite, actoTramiteFile)">
              Agregar
            </v-btn>
          </v-col>
        </div>
        <DataTable :headers="headersActoTramite" :items="itemsActoTramite" :tiene_paginacion="false">
          <template v-slot:[`item.fecha_carga`]="{ item }">
            {{ moment(item.fecha_carga).format("DD/MM/YYYY") }}
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn icon @click="
              descargarArchivoProceso({ ruta_documento: item.ruta_documento })
              ">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
        </DataTable>
      </v-col>
      <!--Actos de trámite -->
    </v-row>
    <v-row>
      <!-- Acta de la Comisión de Alto Nivel -->
      <v-col cols="12" sm="12" md="6">
        <p class="text-h6 font-weight-bold">
          Acta de la Comisión de Alto Nivel
        </p>
        <v-row v-if="!actaComisionActual || editActaComision">
          <v-col cols="12" sm="12" md="4">
            <v-text-field outlined v-model="nombreActaComision" label="Nombre archivo *" />
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-file-input outlined v-model="actaComisionFile" prepend-icon="" accept="application/pdf"
              prepend-inner-icon="mdi-paperclip" label="Acta de la Comisión de Alto Nivel" truncate-length="15" />
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-btn v-if="nombreActaComision && actaComisionFile" dark color="secondary"
              @click="saveArchivo(15002, nombreActaComision, actaComisionFile)">
              Agregar
            </v-btn>
            <v-btn outlined color="secondary" @click="editActaComision = false" class="ml-4" v-if="editActaComision">
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mb-8" v-else-if="actaComisionActual">
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <p class="text-h6 secondary--text">
                  Acta de Comisión de Alto Nivel
                </p>
                <v-btn @click="
                  descargarArchivoProceso({
                    ruta_documento: actaComisionActual.ruta_documento,
                  })
                  " text color="secondary" class="pl-0">
                  <v-icon color="secondary" size="40">mdi-file-pdf-box</v-icon>
                  Descargar
                </v-btn>
                <v-btn icon color="secondary" @click="editActaComision = true" class="ml-4">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Acta de la Comisión de Alto Nivel -->
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <p class="text-h6 font-weight-bold">Recursos de revisión admitidos</p>
        <DataTable :headers="headersRecursosAdmitidos" :tiene_paginacion="false" :items="itemsRecursosAdmitidos">
          <template v-slot:[`item.proveedor`]="{ item }">
            {{ item?.Usuario?.Proveedor?.nombre_comercial }}
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment("DD/MM/YYYY") }}
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-icon color="secondary" @click="showDocument(item)">
              mdi-eye
            </v-icon>
          </template>
        </DataTable>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <p class="text-h6 font-weight-bold">Pronunciamiento del adjudicado</p>
        <DataTable :headers="headersPronunciamiento" :items="itemsPronunciamiento" :tiene_paginacion="false">
          <template v-slot:[`item.proveedor`]="{ item }">
            {{ item?.Usuario?.Proveedor?.nombre_comercial }}
          </template>
          <template v-slot:[`item.fecha_carga`]="{ item }">
            {{ moment(item.fecha_carga).format("DD/MM/YYYY") }}
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn icon @click="
              descargarArchivoProceso({ ruta_documento: item.ruta_documento })
              ">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
        </DataTable>
      </v-col>
    </v-row>
    <br />
    <br />

    <v-row>
      <v-col cols="12" class="mt-6" v-if="!resolucionUcp || editResolucionUcp">
        <p class="text-h5 secondary--text">
          Proveedores adjudicados después de resolución
        </p>

        <p class="font-weight-bold text-h6">Resolución final</p>
        <div class="d-flex">
          <v-col cols="12" sm="12" md="5">
            <v-text-field outlined v-model="nombreResolucionFinal" label="Nombre archivo" />
          </v-col>
          <v-col cols="12" sm="12" md="5">
            <v-file-input outlined v-model="resolucionFinalFile" prepend-icon="" prepend-inner-icon="mdi-paperclip"
              accept="application/pdf" label="Archivo adjunto" truncate-length="15" />
          </v-col>
          <v-col cols="12" sm="12" md="2">
            <v-btn v-if="nombreResolucionFinal && resolucionFinalFile" dark block color="secondary" @click="
              saveArchivo(15003, nombreResolucionFinal, resolucionFinalFile)
              ">
              Agregar
            </v-btn>
            <v-btn block outlined class="mt-2" @click="editResolucionUcp = false">
              <v-icon>mdi-close</v-icon> Cancelar
            </v-btn>
          </v-col>
        </div>
      </v-col>
      <v-col cols="12" sm="6" v-else>
        <v-card>
          <v-card-text>
            <p class="text-h6 secondary--text">
              Resolución final
            </p>
            <v-btn @click="
              descargarArchivoProceso({
                ruta_documento: resolucionUcp.ruta_documento,
              })
              " text color="secondary" class="pl-0">
              <v-icon color="secondary" size="40">mdi-file-pdf-box</v-icon>
              Descargar
            </v-btn>
            <v-btn icon color="secondary" @click="editResolucionUcp = true" class="ml-4">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <AddGanadorComponent />
      </v-col>
    </v-row>
    <!-- Modal Participante-->
    <v-dialog v-model="showAddParticipante" max-width="600" persistent>
      <v-card class="pa-7">
        <div class="d-flex">
          <v-icon @click="clearParticipante(), (showAddParticipante = false)">mdi-close</v-icon>
        </div>
        <v-card-title>
          <p class="secondary--text text-h5">Agregar participante</p>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-select :items="tiposDocumentos" label="Tipo de documento*" outlined v-model="tipo_documento"
                item-text="nombre" item-value="id" return-object></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="9">
              <v-text-field v-model="numero_documento" outlined v-mask="tipo_documento.formato"
                label="Número de documento *" />
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-btn :disabled="!tipo_documento || !numero_documento" dark color="secondary"
                @click="consultarUsuario">Buscar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="verificarDoc">
          <v-row class="flex-column ml-5 mt-5">
            <p class="text-caption mb-0">Nombre:</p>
            <p class="text-subtitle-2">{{ usuario?.empleado }}</p>
          </v-row>
          <v-row class="flex-column ml-5">
            <p class="text-caption mb-0">Número de documento:</p>
            <p class="text-subtitle-2">{{ usuario.documento }}</p>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" outlined @click="clearParticipante(), (showAddParticipante = false)">
            Cancelar
          </v-btn>
          <v-btn :disabled="!verificarDoc" color="secondary" dark @click="addParticipante()">
            Seleccionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal Recursos de revision doc-->
    <v-dialog v-model="showDialog" max-width="1200px">
      <v-card class="pb-2 pa-4">
        <p class="text-h5 secondary--text text-center">
          Pronunciamiento del adjudicado
        </p>
        <v-row align="center" class="mx-4">
          <v-col cols="4">
            <p class="text-h6 secondary--text font-weight-bold">
              {{ proveedorSeleccionado?.Usuario?.Proveedor?.nombre_comercial }}
            </p>
            <v-chip :color="proveedorSeleccionado?.EstadoImpugnacion?.id == 2 ? 'green' : 'red'" label
              class="white--text">
              {{ proveedorSeleccionado?.EstadoImpugnacion?.nombre }}
            </v-chip>
          </v-col>
          <v-col cols="6">
            <v-card class="mx-4 my-6">
              <v-card-text>
                <div class="d-flex flex-wrap">
                  <p class="text-h6 secondary--text">Recurso de revisión</p>
                  <v-btn @click="
                    descargarArchivoProceso({
                      ruta_documento: proveedorSeleccionado.doc_impugnacion,
                    })
                    " icon>
                    <v-icon color="secondary" size="40">mdi-download</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <embed width="100%" height="700" :src="doc + '#page=1%zoom=100'" v-if="doc" />
        <v-card-actions>
          <v-btn @click.stop="showDialog = false" color="secondary" outlined>
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import DataTable from "@/components/DataTableComponent.vue";
import AddGanadorComponent from "./AddGanadorComponent.vue";
import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
  name: "ResolucionRecursosComponent",
  components: {
    DataTable,
    AddGanadorComponent,
  },
  props: {
    etapa: {
      type: Object,
      default: () => { },
    },
  },

  data: () => ({
    headersAltoN: [
      {
        text: "No de documento",
        align: "center",
        value: "numero_documento",
        width: "30%",
      },
      {
        text: "Tipo",
        align: "center",
        value: "tipo_documento",
        width: "20%",
      },
      {
        text: "Nombres",
        value: "nombre_completo",
        width: "20%",
        align: "center",
      },
    ],
    headersActoTramite: [
      {
        text: "Documento",
        align: "center",
        value: "nombre_documento",
        width: "30%",
      },
      {
        text: "Fecha de carga",
        align: "center",
        value: "fecha_carga",
        width: "20%",
      },
      {
        text: "Acciones",
        value: "acciones",
        width: "20%",
        sortable: false,
        align: "center",
      },
    ],
    headersPronunciamiento: [
      {
        text: "Proveedor",
        align: "center",
        value: "proveedor",
        width: "30%",
      },
      {
        text: "Fecha de pronunciamiento",
        align: "center",
        value: "fecha_carga",
        width: "20%",
      },
      {
        text: "Acciones",
        value: "acciones",
        width: "20%",
        sortable: false,

        align: "center",
      },
    ],

    headersRecursosAdmitidos: [
      {
        text: "Código",
        align: "center",
        value: "correlativo",
        width: "30%",
      },
      {
        text: "Proveedor",
        align: "center",
        value: "proveedor",
        width: "20%",
      },
      {
        text: "Fecha de presentación",
        value: "created_at",
        width: "20%",
        align: "center",
      },
      {
        text: "Fecha de admisión",
        value: "created_at",
        width: "20%",
        align: "center",
      },
      {
        text: "Acciones",
        value: "acciones",
        width: "20%",
        align: "center",
        sortable: false,
      },
    ],
    itemsAltoN: [],
    itemsActoTramite: [],
    itemsPronunciamiento: [],
    itemsRecursosAdmitidos: [],
    tiposDocumentos: [],
    showAddParticipante: false,
    tipo_documento: {},
    numero_documento: null,
    verificarDoc: false,
    usuario: {},
    actoTramiteFile: null,
    nombreActoTramite: null,
    actaComisionFile: null,
    nombreActaComision: null,
    actaComisionActual: null,
    editActaComision: false,
    doc: null,
    proveedorSeleccionado: {},
    showDialog: false,
    resolucionFinalFile: null,
    nombreResolucionFinal: null,
    pronunciamientoProveedor: null,
    resolucionUcp: null,
    editResolucionUcp: false,
  }),
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
  },
  methods: {
    ...mapActions("procesoCompraDoc", [
      "getDocumentosProcesoPorTipo",
      "descargarBufferDocumento",
      "guardarArchivoProceso",
      "descargarArchivoProceso",
    ]),
    getNombre(item = null) {
      let nombre;
      if (item !== null) {
        nombre = item.primer_nombre + " ";
        if (item.segundo_nombre !== null) {
          nombre += item.segundo_nombre + " ";
        }
        if (item.tercer_nombre !== null) {
          nombre += item.tercer_nombre + " ";
        }
        if (item.primer_apellido !== null) {
          nombre += item.primer_apellido + " ";
        }
        if (item.segundo_apellido !== null) {
          nombre += item.segundo_apellido + " ";
        }
        if (item.apellido_casada !== null) {
          nombre += item.apellido_casada;
        }
      }
      return nombre;
    },
    async getDocumentosAlto() {
      const { status, data } =
        await this.services.RecursoRevision.getComisionAltoNivel(
          this.$route.params.idProceso,
          {
            id_etapa_proceso: this.procesoData.id_etapa_pausa,
          },
        );

      if (status == 200) {
        this.itemsAltoN = data;
      }
    },
    async obtenerTiposDocumentos() {
      const response = await this.services.Documentos.getDocumentTypeList({
        id_categoria_documento: 1,
      });
      this.tiposDocumentos = response.data.filter(
        (target) => target.id == 1 || target.id == 20
      );
    },
    async consultarUsuario() {

      const response = await this.services.Usuarios.getUsuarios({
        numero_documento: this.numero_documento,
        id_tipo_documento: this.tipo_documento.id,
        crear_usuario: true,
      });
      if (response?.status == 200) {
        if (response?.data?.length > 0) {
          if (response.data[0].id_usuario) {
            this.usuario = response.data[0];
            this.verificarDoc = true;
          } else {
            this.temporalAlert({
              show: true,
              message: "No se encontró usuario registrado con el documento ingresado",
              type: "warning",
            });
          }
        } else {
          this.temporalAlert({
            show: true,
            message: "No se encontró usuario registrado con el documento ingresado",
            type: "warning",
          });
        }
      }
    },
    clearParticipante() {
      this.usuario = {};
      this.tipo_documento = {};
      this.numero_documento = null;
    },

    async addParticipante() {
      const { status } =
        await this.services.RecursoRevision.postParticipacionComision(
          this.$route.params.idProceso,
          {
            id_usuario: this.usuario.id_usuario,
            id_etapa_proceso: this.procesoData.id_etapa_pausa,
          }
        );

      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: "Participante agregado con éxito",
          type: "success",
        });
        this.clearParticipante();
        this.showAddParticipante = false;
        this.getDocumentosAlto();
      }
    },
    async saveArchivo(tipo, nombre, doc) {
      let body = {
        documento: doc,
        nombre: nombre,
        id_proceso_compra: this.$route.params.idProceso,
        id_tipo_documento: tipo,
        id_etapa_proceso: this.procesoData.id_etapa_pausa,
      };
      const response = await this.guardarArchivoProceso(body);

      if (response.status == 200) {
        this.temporalAlert({
          show: true,
          message: "Documento agregado con éxito",
          type: "success",
        });
        this.reloadDocTables(tipo);
      }
    },
    reloadDocTables(type) {
      switch (type) {
        case 15000:
          this.getDocumentosActoTramite();
          break;
        case 15002:
          this.getDocumentoActaComision();
          this.editActaComision = false;
          break;
        case 15003:
          this.getResolucionUcp();
          break;
      }
    },
    async getDocumentosActoTramite() {
      const response = await this.getDocumentosProcesoPorTipo({
        id_proceso_compra: this.$route.params.idProceso,
        id_tipo_documento: 15000,
        id_etapa_proceso: this.procesoData.id_etapa_pausa,

      });
      this.itemsActoTramite = response;
    },
    async getDocumentoActaComision() {
      const response = await this.getDocumentosProcesoPorTipo({
        id_proceso_compra: this.$route.params.idProceso,
        id_tipo_documento: 15002,
        id_etapa_proceso: this.procesoData.id_etapa_pausa,
      });
      this.actaComisionActual = response[response.length - 1];
    },
    async getPronunciamientoProveedor() {
      const response = await this.getDocumentosProcesoPorTipo({
        id_proceso_compra: this.$route.params.idProceso,
        id_tipo_documento: 15001,
        id_etapa_proceso: this.procesoData.id_etapa_pausa,
      });
      this.itemsPronunciamiento = response;
    },
    async getResolucionUcp() {
      const response = await this.getDocumentosProcesoPorTipo({
        id_proceso_compra: this.$route.params.idProceso,
        id_tipo_documento: 15003,
        id_etapa_proceso: this.procesoData.id_etapa_pausa,
      });
      this.resolucionUcp = response[response.length - 1];
    },
    async getRecursosRevision() {
      const { status, data } =
        await this.services.RecursoRevision.getRecursosRevisionUcp(
          this.$route.params.idProceso,
          {
            id_tipo: 1,
            id_estado: 2,
            id_etapa_proceso: this.procesoData.id_etapa_pausa,
          }
        )

      if (status == 200) {
        this.itemsRecursosAdmitidos = data;
      }
    },

    async getPronunciamiento(id) {
      const { status, data } =
        await this.services.RecursoRevision.getPronunciamiento(id);

      if (status == 200) {
        this.pronunciamientoProveedor = data;
      }
    },

    async showDocument(item) {
      const response = await this.descargarBufferDocumento({
        ruta_documento: item.doc_impugnacion,
      });
      this.getDoc(response);
      this.getPronunciamiento(item.id);
      this.proveedorSeleccionado = item;
      this.showDialog = true;
    },
    getDoc(doc) {
      let b64 = Buffer.from(doc).toString("base64");
      this.doc = `data:application/pdf;base64,${b64}`;
    },
  },
  created() {
    this.getDocumentosAlto();
    this.obtenerTiposDocumentos();
    this.getDocumentosActoTramite();
    this.getDocumentoActaComision();
    this.getPronunciamientoProveedor();
    this.getRecursosRevision();
    this.getResolucionUcp();
  },
};
</script>
